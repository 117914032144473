import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap, LoadScriptNext, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import googleMapStyle from 'styles/googleMapStyle';

import { ReactComponent as LayersIcon } from 'assets/icons/ico_layers.svg';

import { getCommunitiesForParkingArea } from 'api/communities';
import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';
import { useParams } from 'react-router-dom';
import { getParkingArea } from 'api/locations';

const CommunitiesMap = ({ parkingAreas, setCommunities, setSelectedParkingArea, setIsLoading, disabled }) => {

    const [searchBox, setSearchBox] = useState();
    const [libraries] = useState(['places', 'drawing']);
    const [map, setMap] = useState();
    const [center] = useState({ lat: 56, lng: 12 });
    const { t } = useTranslation();
    const { showToast } = useMessages();
    const { parkingAreaExtId } = useParams();

    const onLoadGoogleMap = (mapRef) => {
        setMap(mapRef);
        mapRef.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        mapRef.setTilt(0);
    }

    const loadCommunitiesForParkingArea = async (parkingAreaExtId) => {

        setIsLoading(true);

        try {
            const token = await getToken();
            const result = await getCommunitiesForParkingArea(token, parkingAreaExtId);

            if (result && !result.error) {
                setCommunities(result);
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsLoading(false);
    }

    /* Add communities polygons */
    useEffect(() => {
        if (parkingAreas && parkingAreas.length > 0 && window.google && map) {
            for (let i = 0; i < parkingAreas.length; i++) {
                const parkingArea = parkingAreas[i];

                if (parkingArea && parkingArea.polygonCoordinates && window.google && window.google.maps && map) {
                    const polygonCoordinates = [];
                    parkingArea.polygonCoordinates.forEach((c) => {
                        polygonCoordinates.push({ lat: c.latitude, lng: c.longitude })
                    });

                    const polygon = new window.google.maps.Polygon({
                        paths: polygonCoordinates,
                        fillColor: '#33D085',
                        fillOpacity: '0.4',
                        strokeWeight: 3,
                        editable: false,
                        zIndex: 1,
                        strokeColor: '#33D085',
                        draggable: false
                    });
                    
                    if (!disabled) {
                        window.google.maps.event.addListener(polygon, 'click', function (event) {
                            setSelectedParkingArea(parkingArea);
                            loadCommunitiesForParkingArea(parkingArea.parkingAreaExtId);
                            return;
                        });
                    }

                    /* Add the polygon to the map */
                    polygon.setMap(map);
                }
            }
        }

    }, [parkingAreas, window.google, map]);

    /* Focus map on the current parking area */
    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const result = await getParkingArea(token, parkingAreaExtId);

                if (result && result.polygonCoordinates && !result.error) {
                    var bounds = new window.google.maps.LatLngBounds();
                    const polygonCoordinates = [];

                    result.polygonCoordinates.forEach((c) => {
                        polygonCoordinates.push({ lat: c.latitude, lng: c.longitude })
                    });

                    for (let i = 0; i < polygonCoordinates.length; i++) {
                        bounds.extend(polygonCoordinates[i]);
                    }

                    /* Allow for some padding as well */
                    map.fitBounds(bounds, 120);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        }
        if (parkingAreaExtId && window.google && map) {
            load();
        }
    }, [parkingAreaExtId, window.google, map]);

    const onLoadSearchBox = (ref) => {
        setSearchBox(ref);
    }

    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();

        if (places.length == 0) {
            return;
        }

        places.forEach((place) => {
            const bounds = new window.google.maps.LatLngBounds();

            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
            map.fitBounds(bounds);

        });

        map.setZoom(16);
    }

    const ZoomInButton = () => {
        return (
            <button onClick={(e) => {
                e.preventDefault();
                const currentZoom = map.getZoom();
                map.setZoom(currentZoom + 1);
            }}
                className="flex absolute top-4 right-2 bg-white h-11 w-11 rounded-full shadow items-center justify-center font-medium text-2xl">
                <span>+</span>
            </button>
        )
    }

    const ZoomOutButton = () => {
        return (
            <button onClick={(e) => {
                e.preventDefault();
                const currentZoom = map.getZoom();
                map.setZoom(currentZoom - 1);
            }}
                className="flex absolute top-[64px] right-2 bg-white h-11 w-11 rounded-full shadow items-center justify-center font-medium text-2xl">
                <span>-</span>
            </button>
        )
    }

    const ChangeMapTypeButton = () => {
        return (
            <button onClick={(e) => {
                e.preventDefault();
                if (map.getMapTypeId() != window.google.maps.MapTypeId.SATELLITE) {
                    map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
                } else {
                    map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
                }

                map.setTilt(0);
            }}
                className="flex absolute top-4 right-[60px] bg-white h-11 w-11 rounded-full shadow items-center justify-center font-medium text-2xl"
            ><LayersIcon /></button>
        )
    }

    return (
        <LoadScriptNext libraries={libraries} googleMapsApiKey="AIzaSyD6UTBXOfbCvJWhKlK7gKGCBYzQ9ECNu2c">
            <GoogleMap
                onLoad={onLoadGoogleMap}
                id='map'
                mapContainerStyle={{
                    width: '100%',
                    maxWidth: '100%',
                    height: '324px'
                }}
                center={center}
                zoom={4}
                options={{
                    styles: googleMapStyle,
                    disableDefaultUI: true
                }}>
                <StandaloneSearchBox
                    onLoad={onLoadSearchBox}
                    onPlacesChanged={onPlacesChanged}>
                    <input
                        type="text"
                        placeholder={t('createParkingArea.search')}
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `240px`,
                            height: `44px`,
                            padding: `0 12px`,
                            borderRadius: `12px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `16px`,
                            fontWeight: "400",
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute",
                            left: "12px",
                            top: "12px"
                        }}
                    />
                </StandaloneSearchBox>
                <ChangeMapTypeButton />
                <ZoomInButton />
                <ZoomOutButton />
            </GoogleMap>
        </LoadScriptNext>
    );
};

export default CommunitiesMap;