const BASE_URL = `${process.env.REACT_APP_API_BOOKINGS_BASE_URL}/Bookings`;

export const getBookingsForParkingArea = async (token, parkingAreaExtId) => {
    const response = await fetch(BASE_URL + `/parking-area/${parkingAreaExtId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    });

    if (response.status === 200) {
        const responseBody = await response.text();
    
        if (!responseBody) {
          return {};
        } else {
          return JSON.parse(responseBody);
        }
      }

    throw response;
}