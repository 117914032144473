import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as NextIcon } from 'assets/icons/ico_next_green.svg';
import { useNavigate, useParams } from "react-router-dom";

const PayoutItem = ({ payout }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organizationExtId } = useParams();
    const payoutDetailsUrl = `/${organizationExtId}/payouts/${payout.payoutExtId.toLowerCase()}`;

    const baseCssClass = `pl-6 whitespace-nowrap ${payout.status === 'expired' ? 'text-gray-400' : 'text-black'}`;

    const getPayoutStatusTranslation = (payoutStatus) => {
        switch (payoutStatus.toLowerCase()) {
            case 'pending':
                return t('payoutStatus.pending');
            case 'processed':
                return t('payoutStatus.processed');
            case 'processing':
                return t('payoutStatus.processing');
            case 'ready to process':
                return t('payoutStatus.readytoprocess');
            case 'error':
                return t('payoutStatus.failed');
            default:
                return payoutStatus;
        }
    }

    const getPayoutPeriod = (payout) => {
        if (payout.periodStart && payout.periodEnd) {
            return `${payout.periodStart.substring(0, 10)} - ${payout.periodEnd.substring(0, 10)}`;
        } else if (payout.periodStart) {
            return `${payout.periodStart.substring(0, 10)} - ${t('payoutStatus.pending')}`;
        }

        return '-';
    }


    return (
        <tr className={`h-16 border-b-[1px] border-airpark-gray-300`}>
            <td className={baseCssClass}>
                <button className="flex items-center" onClick={(e) => { e.preventDefault(); navigate(payoutDetailsUrl); }}>
                    {payout.payoutExtId}
                </button>
            </td>
            <td className={baseCssClass}>{payout.friendlyTotalAmount}</td>
            <td className={baseCssClass}>{getPayoutStatusTranslation(payout.payoutStatus)}</td>
            <td className={baseCssClass}>{payout.processedAtUtc?.substring(0, 10)}</td>
            <td className={baseCssClass}>{getPayoutPeriod(payout)}</td>
            <td className={baseCssClass}>{payout.description}</td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] min-w-[72px] relative">
                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); navigate(payoutDetailsUrl) }}>
                    <NextIcon />
                </button>
            </td>
        </tr>
    );
}

export default PayoutItem;