import React from "react";
import { useTranslation } from "react-i18next";

const PayoutDetailsItem = ({ payoutLine }) => {
   const baseCssClass = 'pl-6 whitespace-nowrap';
   const { t } = useTranslation();

   const translateTransactionType = (transactionType) => {
      switch (transactionType) {
         case 'Bookings':
            return t('payoutTransactionType.booking');
         case 'Permits':
            return t('payoutTransactionType.permit');
         case 'Sublet booking':
            return t('payoutTransactionType.subletting');
         case 'Sublet permit':
            return t('payoutTransactionType.subletting');
         default:
            return transactionType;
      }
   };

   return (
      <tr className={`h-16 border-b-[1px] border-airpark-gray-300`}>
         <td className={baseCssClass}>
            {translateTransactionType(payoutLine.transactionType)}
         </td>
         <td className={baseCssClass}>
            {payoutLine.payoutAmount}
         </td>
         <td className={baseCssClass}>
            {payoutLine.vatOnPayoutAmount}
         </td>
         <td className={baseCssClass}>
            {payoutLine.transactionFee}
         </td>

         {/* AirPark fee */}
         <td className={baseCssClass}>
            {payoutLine.airParkPercentage}
         </td>
         <td className={baseCssClass}>
            {payoutLine.airParkFee}
         </td>
         <td className={baseCssClass}>
            {payoutLine.vatOnAirParkFee}
         </td>

         {/* Owner fee */}
         <td className={baseCssClass}>
            {payoutLine.ownerPercentage}
         </td>
         <td className={baseCssClass}>
            {payoutLine.ownerAmount}
         </td>
         <td className={baseCssClass}>
            {payoutLine.ownerVatPercentage}
         </td>
         <td className={baseCssClass}>
            {payoutLine.vatOnOwnerAmount}
         </td>

         {/* Subletting */}
         <td className={baseCssClass}>
            {payoutLine.subletterAmount}
         </td>
         <td className={baseCssClass}>
            {payoutLine.subletterPercentage}
         </td>

         {/* Payment provider */}
         <td className={baseCssClass}>
            {payoutLine.paymentProvider}
         </td>

      </tr>
   );
}

export default PayoutDetailsItem;