import React from "react";

const RadioButton = ({ isChecked, onClick }) => {
    return (
        <div className="flex relative cursor-pointer text-lg select-none h-[18px] w-[18px]">
            <input className="flex absolute opacity-0 cursor-pointer" type="radio" checked={isChecked} onChange={(e) => { /* Ignore on change */ }} />
            <div className="flex items-center justify-center absolute top-0 left-0 h-[18px] w-[18px] bg-airpark-gray-300 rounded-full hover:bg-airpark-gray-400" onClick={(e) => { onClick(); }}>
                {isChecked &&
                    <span className="flex rounded-full bg-airpark-green w-3 h-3"></span>
                }
            </div>
        </div>
    );
}

export default RadioButton;