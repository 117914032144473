import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ButtonText from 'components/common/buttonText';
import Logo from "components/common/logo";

import { ReactComponent as SuccessIcon } from 'assets/icons/ico_success.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/ico_warning_yellow.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ico_error.svg';

import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';

import Lottie from 'lottie-react';

import { useMessages } from 'context/messages';
import { useTranslation } from 'react-i18next';
import Loading from 'components/common/loading';

const BetweenPagesMessage = () => {
    const location = useLocation();
    const { betweenPagesMessage, hideBetweenPagesMessage } = useMessages();
    const { t } = useTranslation();

    useEffect(() => {
        hideBetweenPagesMessage();
    }, [location]);

    if (!betweenPagesMessage.display) {
        return (null);
    }

    return (
        <div className='flex fixed inset-0 bg-white'>
            <div className="flex absolute w-full justify-center">
                <Logo className="p-6" />
            </div>
            <div className="flex p-6 flex-col absolute h-full w-full items-center justify-center">
                <div className="flex flex-col justify-center items-center max-w-xs w-full">
                    {betweenPagesMessage.template == 'loading' &&
                        <Loading title={betweenPagesMessage.title ? `${betweenPagesMessage.title}...` : t('defaultLayout.loading')} />
                    }
                    {betweenPagesMessage.template == 'success' &&
                        <SuccessIcon className='w-10 h-10' />
                    }
                    {betweenPagesMessage.template == 'info' &&
                        <InfoIcon className='w-10 h-10' />
                    }
                    {betweenPagesMessage.template == 'warning' &&
                        <WarningIcon className='w-10 h-10' />
                    }
                    {betweenPagesMessage.template == 'error' &&
                        <ErrorIcon className='w-10 h-10' />
                    }
                    {betweenPagesMessage.template != 'loading' &&
                        <>
                            <div className="flex flex-col mt-4 justify-center">
                                <h2 className="text-2xl mt-4 text-center">{betweenPagesMessage.title}</h2>
                                <p className="mt-2 text-center">{betweenPagesMessage.description}</p>
                            </div>
                            <div className="flex w-full justify-end mt-4">
                                <ButtonText onClick={() => { betweenPagesMessage.onClick(); }} className='w-full'>
                                    OK
                                </ButtonText>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default BetweenPagesMessage;