import React from 'react';
import Lottie from 'lottie-react';
import animSpinnerWhite from 'assets/animations/anim_spinner_white_20x20.json';

const Button = ({ onClick, children, loading, disabled }) => {
    return (
        <button onClick={() => { onClick() }} disabled={disabled} className="h-11 w-11 rounded-[22px] bg-airpark-green hover:bg-airpark-green-400 flex justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed">
           {!loading && children}

           {loading &&
                <Lottie className="h-5 w-5" animationData={animSpinnerWhite} loop={true} />
           }
        </button>
    );
}

export default Button;