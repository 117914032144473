import React, { useState } from 'react';
import TableItemContextMenu from 'components/dashboard/tableItemContextMenu/itemContextMenu';

import { useTranslation } from 'react-i18next';
import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';

import { useMessages } from 'context/messages';
import { getToken } from 'context/auth';

import { deleteCommunityConnection } from 'api/communities';

const ConnectedCommunitiesItem = ({ communityConnection, communityConnections, setCommunityConnections }) => {
    const { showToast, showModal, hideModal } = useMessages();

    const [showMenu, setShowMenu] = useState();
    const [isDeleting, setIsDeleting] = useState();
    const { t } = useTranslation();

    const handleDeleteCommunityConnection = async () => {
        setShowMenu(false);
        showModal(
            t('connectedCommunitiesTable.deleteCommunityConnectionTitle'),
            t('connectedCommunitiesTable.deleteCommunityConnectionDescription'),
            t('generic.yes'),
            async () => {
                setIsDeleting(true);
                hideModal();

                try {
                    const token = await getToken();
                    const deleteCommunityConnectionResult = await deleteCommunityConnection(token, 
                        { 
                            communityExtId: communityConnection.communityExtId, 
                            recipientCommunityExtId: communityConnection.recipientCommunityExtId 
                        });

                    if (deleteCommunityConnectionResult && !deleteCommunityConnectionResult.error) {
                        /* Successful, remove item from list */
                        const index = communityConnections.findIndex(({ communityExtId, recipientCommunityExtId }) => { return communityExtId === communityConnection.communityExtId && recipientCommunityExtId === communityConnection.recipientCommunityExtId });
                        communityConnections.splice(index, 1);
                        setCommunityConnections([...communityConnections]);

                        showToast(t('connectedCommunitiesTable.removedCommunityConnectionTitle'), t('connectedCommunitiesTable.removedCommunityConnectionDescription'), 'success');
                    } else {
                        console.error(deleteCommunityConnectionResult);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                setIsDeleting(false);
            },
            t('generic.cancel'),
            () => { hideModal() },
            'warning');
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">
                {communityConnection.isRecipient ? communityConnection.communityName :  communityConnection.recipientCommunityName}
            </td>
            <td className="pl-6 whitespace-nowrap">{communityConnection.isRecipient ? communityConnection.recipientCommunityName :  communityConnection.communityName}</td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <TableItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onDeleteClick={() => {
                            handleDeleteCommunityConnection();
                         }}
                        deleteTitle={t('generic.remove')}>
                    </TableItemContextMenu>
                }
            </td>
        </tr>
    );
};

export default ConnectedCommunitiesItem;