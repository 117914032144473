import React from 'react';

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';
import { useTranslation } from 'react-i18next';
import SentRequestsItem from './sentRequestsItem';
import ReceivedRequestsItem from './receivedRequestsItem';

const ReceivedRequestsTable = ({receivedRequests, setReceivedRequests}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">{t('receivedRequestsTable.myCommunity')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('receivedRequestsTable.receivedFromCommunity')}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {receivedRequests && receivedRequests.length > 0 && receivedRequests.map((receivedRequest, i) => {
                            return (<ReceivedRequestsItem
                                        receivedRequest={receivedRequest}
                                        receivedRequests={receivedRequests}
                                        setReceivedRequests={setReceivedRequests}
                                        key={i} />)
                        })}
                        {receivedRequests && receivedRequests.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={4}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        <span>{t('receivedRequestsTable.noPendingRequests')}</span>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ReceivedRequestsTable;