import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';

import Paging from "components/common/paging";
import PayoutDetailsItemNewTaxCalculation from './payoutDetailsItemNewTaxCalculation';

const PayoutDetailsTableNewTaxCalculation = ({ payout, payoutLines }) => {

    const [payoutLinePages, setPayoutLinePages] = useState();
    const [currentPayoutLinePage, setCurrentPayoutLinePage] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (payoutLines) {
            setPayoutLinePages(Math.ceil(payoutLines.length / 20));
            setCurrentPayoutLinePage(0);
        }
    }, [payoutLines]);

    if (!payoutLines) {
        return (null);
    }

    // if (payout.isNewTaxCalculation) {
    //     // Create header row
    //     csv += 'Transaction Type,Payment Amount,Transaction Fee,Air Park Fee,TotalVAT,Owner Amount,Subletter Amount,Payout Amount,VAT on Payout Amount,Payment Ext ID\n';

    //     // Create data rows
    //     payout?.payoutLines.forEach(line => {
    //         csv += `${line.transactionType},${line.paymentAmount},${line.transactionFee},${line.airParkFee},${line.vatOnAirParkFee},${line.ownerAmount},${line.subletterAmount},${line.payoutAmount},${line.vatOnPayoutAmount},${line.paymentExtId}\n`;
    //     });

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.type')}
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.paymentAmount')}
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.transactionFee')}
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.airParkFee')}
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.totalVat')}
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.ownerAmount')}
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.subletterAmount')}
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.payoutAmount')}
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.vatOnPayoutAmount')}
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.paymentExtId')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {payoutLines && payoutLines.length > 0 && payoutLines.slice(currentPayoutLinePage * 20, ((currentPayoutLinePage * 20) + 20)).map((payoutLine, i) => {
                            return (<PayoutDetailsItemNewTaxCalculation
                                key={i}
                                payoutLine={payoutLine} />)
                        })}
                        {payoutLines?.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={15}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        <span>{t('payoutDetailsTable.noPayoutLines')}</span>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

            </div>
            {payoutLinePages != 0 &&
                <Paging totalNumberOfPages={payoutLinePages} currentPage={currentPayoutLinePage} setCurrentPage={setCurrentPayoutLinePage} />
            }
        </>
    );
};

export default PayoutDetailsTableNewTaxCalculation;