const BASE_URL = `${process.env.REACT_APP_API_USERS_BASE_URL}/Organizations`

export const getOrganizationsForUser = async (token) => {
  const response = await fetch(BASE_URL + `/organizations-for-user`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getOrganizationForUser = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/organization/${organizationExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const createOrganization = async (token, organization) => {
  const response = await fetch(BASE_URL + '/create-organization', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(organization)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const updateOrganization = async (token, organization) => {
  const response = await fetch(BASE_URL + '/update-organization', {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(organization)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getOrganizationInvites = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/get-invites/${organizationExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getOrganizationSublessorInvites = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/get-sublessor-invites/${organizationExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getOrganizationInvite = async (organizationInviteExtId) => {
  const response = await fetch(BASE_URL + `/get-invite/${organizationInviteExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const createOrganizationInvite = async (token, organizationInvite) => {
  const response = await fetch(BASE_URL + '/create-invite', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(organizationInvite)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const createOrganizationSublessorInvite = async (token, organizationSublessorInvite) => {
  const response = await fetch(BASE_URL + '/create-sublessor-invite', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(organizationSublessorInvite)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const removeUserFromOrganization = async (token, removeDto) => {
  const response = await fetch(BASE_URL + '/remove-user-from-organization', {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(removeDto)
  })

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const deleteOrganizationInvite = async (token, organizationInviteExtId) => {
  const response = await fetch(BASE_URL + `/delete-invite/${organizationInviteExtId}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const acceptOrganizationInvite = async (token, organizationInviteExtId) => {
  const response = await fetch(BASE_URL + `/accept-invite/${organizationInviteExtId}`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getAvailableVatPercentagesForOrganization = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/get-available-vat-percentages-for-organization/${organizationExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getRevenueSharingForOrganization = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/get-revenue-sharing/${organizationExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const updateRevenueSharingForOrganization = async (token, revenueSharing) => {
  const response = await fetch(BASE_URL + '/update-revenue-sharing', {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(revenueSharing)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getPayoutSettingsForOrganization = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/get-payout-settings/${organizationExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const updatePayoutSettingsForOrganization = async (token, payoutSettings) => {
  const response = await fetch(BASE_URL + '/update-payout-settings', {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(payoutSettings)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const createSignatureForOrganization = async (token, organizationSignature) => {
  const response = await fetch(BASE_URL + '/create-signature', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(organizationSignature)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}