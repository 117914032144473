import React from "react";
import { useTranslation } from "react-i18next";

const PayoutDetailsItemNewTaxCalculation = ({ payoutLine }) => {
   const baseCssClass = 'pl-6 whitespace-nowrap';
   const { t } = useTranslation();

   const translateTransactionType = (transactionType) => {
      switch (transactionType) {
         case 'Bookings':
            return t('payoutTransactionType.booking');
         case 'Permits':
            return t('payoutTransactionType.permit');
         case 'Sublet booking':
            return t('payoutTransactionType.subletting');
         case 'Sublet permit':
            return t('payoutTransactionType.subletting');
         default:
            return transactionType;
      }
   };

   // if (payout.isNewTaxCalculation) {
    //     // Create header row
    //     csv += 'Transaction Type,Payment Amount,Transaction Fee,Air Park Fee,TotalVAT,Owner Amount,Subletter Amount,Payout Amount,VAT on Payout Amount,Payment Ext ID\n';

    //     // Create data rows
    //     payout?.payoutLines.forEach(line => {
    //         csv += `${line.transactionType},${line.paymentAmount},${line.transactionFee},${line.airParkFee},${line.vatOnAirParkFee},${line.ownerAmount},${line.subletterAmount},${line.payoutAmount},${line.vatOnPayoutAmount},${line.paymentExtId}\n`;
    //     });

   return (
      <tr className={`h-16 border-b-[1px] border-airpark-gray-300`}>
         <td className={baseCssClass}>
            {translateTransactionType(payoutLine.transactionType)}
         </td>
         <td className={baseCssClass}>
            {payoutLine.payoutAmount}
         </td>
         <td className={baseCssClass}>
            {payoutLine.transactionFee}
         </td>
         <td className={baseCssClass}>
            {payoutLine.airParkFee}
         </td>
         <td className={baseCssClass}>
            {payoutLine.totalVat}
         </td>
        <td className={baseCssClass}>
           {payoutLine.ownerAmount}
        </td>
        <td className={baseCssClass}>
            {payoutLine.subletterAmount}
         </td>
         <td className={baseCssClass}>
            {payoutLine.payoutAmount}
         </td>
         <td className={baseCssClass}>
            {payoutLine.vatOnPayoutAmount}
         </td>
         <td className={baseCssClass}>
            {payoutLine.paymentExtId}
         </td>
      </tr>
   );
}

export default PayoutDetailsItemNewTaxCalculation;