import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getToken } from "context/auth";

import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import { ReactComponent as QueueIcon } from "assets/icons/ico_queue.svg";

import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';

import { useMessages } from "context/messages";
import TableItemContextMenu from "components/dashboard/tableItemContextMenu/itemContextMenu";
import { useNavigate, useParams } from "react-router-dom";
import { removeFromQueue } from "api/permitQueue";
import moment from "moment";
import { PermitQueueType } from "../../../helpers/consts";

const PermitQueueItem = ({ queueItem, queuePosition, onEditClick, onRemoveQueueItem }) => {

    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState();
    const { showToast, showModal, hideModal } = useMessages();
    const [isDeleting, setIsDeleting] = useState();
    const navigate = useNavigate();
    const { organizationExtId, parkingAreaExtId } = useParams();

    const baseCssClass = 'pl-6 whitespace-nowrap';

    const handleRemovePermitQueueItem = () => {

        setShowMenu(false);

        showModal(
            t('permitQueueTable.deleteQueueItemTitle'),
            t('permitQueueTable.deleteQueueItemDescription'),
            t('generic.yes'),
            async () => {
                setIsDeleting(true);
                hideModal();

                try {
                    const token = await getToken();
                    const result = await removeFromQueue(token, queueItem.permitQueueExtId);

                    if (result?.error) {
                        console.error(result);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    } else {
                        /* Successful, remove item from list */
                        onRemoveQueueItem(queueItem);

                        showToast(t('permitQueueTable.deletedTitle'), t('permitQueueTable.deletedDescription'), 'success');
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                setIsDeleting(false);
            },
            t('generic.cancel'),
            () => { hideModal() },
            'warning');
    }

    const handleIssuePermitForUser = () => {
        setShowMenu(false);
        navigate(`/${organizationExtId}/parking-areas/${parkingAreaExtId}/create-permit-for-queue/${queueItem.permitQueueExtId}`);
    }

    const renderCreatedDateTime = () => {
        let momentDate = moment.utc(queueItem.createdDateTime);

        if (!momentDate.isValid()) {
            const possibleFormats = [
                'DD. MM. YYYY. HH:mm:ss',
                'YYYY-MM-DD HH:mm:ss',
                'MM/DD/YYYY HH:mm:ss',
            ];
            for (const format of possibleFormats) {
                const date = moment.utc(queueItem.createdDateTime, format);
                if (date.isValid()) {
                    momentDate = date;
                    break;
                }
            }
        }
        const localCreatedDateTime = momentDate.local().format('YYYY-MM-DD HH:mm:ss');
        return localCreatedDateTime;
    }



    const renderPermitQueueTypeText = (permitQueueType) => {
        switch (permitQueueType) {
            case PermitQueueType.AreaParking:
                return t('createPermitPage.allParkingSpots');
            case PermitQueueType.AnyNumberedSpot:
                return t('createPermitPage.specificParkingSpots');
            default:
                return '-'; // Should not happen!
        }
    }

    return (
        <tr className={`h-16 border-b-[1px] border-airpark-gray-300`}>
            <td className={baseCssClass}>{queuePosition}</td>
            <td className={baseCssClass}>{renderPermitQueueTypeText(queueItem.permitQueueType)}</td>
            <td className={baseCssClass}>{queueItem.firstName}</td>
            <td className={baseCssClass}>{queueItem.lastName}</td>
            <td className={baseCssClass}>{queueItem.phone}</td>
            <td className={baseCssClass}>{queueItem.email}</td>
            <td className={baseCssClass}>{renderCreatedDateTime()}</td>
            <td className={baseCssClass}>{queueItem.description}</td>

            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => {
                        e.preventDefault();
                        setShowMenu(!showMenu);
                    }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <TableItemContextMenu
                        onCloseClick={() => {
                            setShowMenu(false);
                        }}
                        onEditClick={() => {
                            onEditClick(queueItem);
                            setShowMenu(false);
                        }}
                        onDeleteClick={() => {
                            handleRemovePermitQueueItem()
                            setShowMenu(false);
                        }}
                        deleteTitle={t('generic.remove')}>
                        <button className="flex items-center" onClick={(e) => {
                            e.preventDefault();
                            handleIssuePermitForUser();
                        }}>
                            <QueueIcon className="h-5 w-5" /><span
                            className="ml-1">{t('permitQueueTable.issuePermit')}</span>
                        </button>
                    </TableItemContextMenu>
                }
            </td>
        </tr>
    );
}

export default PermitQueueItem;