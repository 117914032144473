import { useEffect, useState } from 'react';
import { getToken } from "context/auth";
import { useDashboard } from "context/dashboard";
import { useMessages } from "context/messages";
import { useTranslation } from "react-i18next";

const useOrganizationUsers = ({ getPortalAdmins, getSublessors}) => {
    const [displayInviteUserModal, setDisplayInviteUserModal] = useState();
    const [isLoading, setIsLoading] = useState();
    const [filteredPortalAdmins, setFilteredPortalAdmins] = useState([]);
    const [filteredSublessors, setFilteredSublessors] = useState([]);
    const { organization } = useDashboard();
    const [portalAdmins, setPortalAdmins] = useState([]);
    const [sublessors, setSublessors] = useState([]);
    const { showToast } = useMessages();
    const [searchText, setSearchText] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        const load = async () => {

            setIsLoading(true);

            try {
                const token = await getToken();
                const [portalAdminResult, sublessorResult] = await Promise.all([
                    getPortalAdmins(token, organization.organizationExtId),
                    getSublessors(token, organization.organizationExtId)
                ])

                if (portalAdminResult && !portalAdminResult.error) {
                    setPortalAdmins(portalAdminResult);
                } else {
                    console.error(portalAdminResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                if (sublessorResult && !sublessorResult.error) {
                    setSublessors(sublessorResult)
                } else {
                    console.error(sublessorResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, []);

    useEffect(() => {
        if (searchText && portalAdmins) {
            setFilteredPortalAdmins([...portalAdmins.filter((user) => {
                if (user.firstName.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }
                if (user.lastName?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }

                if (user.email?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }

                if (user.phone?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }
            })]);
        } else if (portalAdmins) {
            setFilteredPortalAdmins([...portalAdmins]);
        }
    }, [searchText, portalAdmins]);

    useEffect(() => {
        if (searchText && sublessors) {
            setFilteredSublessors([...sublessors.filter((user) => {
                if (user.firstName.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }
                if (user.lastName?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }

                if (user.email?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }

                if (user.phone?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }
            })]);
        } else if (sublessors) {
            setFilteredSublessors([...sublessors]);
        }
    }, [searchText, sublessors]);

    return {
        searchText,
        setSearchText,
        displayInviteUserModal,
        setDisplayInviteUserModal,
        isLoading,
        filteredPortalAdmins,
        filteredSublessors,
        setFilteredPortalAdmins,
        setFilteredSublessors,
    }
}

export default useOrganizationUsers;