import Button from 'components/common/button';
import useAuth, { getToken } from 'context/auth';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackgroundIcon } from 'assets/icons/ico_bg_lock.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/ico_arrow_right.svg';

import Logo from 'components/common/logo';
import ButtonText from 'components/common/buttonText';
import { getUser } from 'api/users';
import Alert from 'components/common/alert';
import { resendEmailVerificationCode } from 'api/users';

const VerifyEmailPage = () => {

    const [email, setEmail] = useState();
    const [loading, setIsLoading] = useState();
    const { t } = useTranslation();
    const { showToast } = useMessages();
    const navigate = useNavigate();
    const { logout } = useAuth();
    const [notReceivedVerificationCode, setNotReceivedVerificationCode] = useState(false);
    const [isResendingVerificationCode, setIsResendingVerificationCode] = useState(false);

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const decodedToken = jwtDecode(token);
                const email = decodedToken['https://airpark.app/email'];
                setEmail(email);
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        }

        load();
    }, []);

    const checkIfTokenHasVerifiedEmail = (token) => {
        const decodedToken = jwtDecode(token);
        return decodedToken['https://airpark.app/email_verified'];
    }

    const resendVerificationCode = async () => {
        setIsResendingVerificationCode(true);

        try {
            const token = await getToken();
            await resendEmailVerificationCode(token);

            showToast(t('verifyEmailPage.emailResentTitle'), t('verifyEmailPage.emailResentDescription', { email: email }), 'success');
            setNotReceivedVerificationCode(false);
            setIsResendingVerificationCode(false);
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }
        
        setIsResendingVerificationCode(false);
    }

    const handleCheckIfEmailIsVerified = async () => {
        setIsLoading(true);

        try {
            const token = await getToken(true);

            if (checkIfTokenHasVerifiedEmail(token)) {
                const user = await getUser(token);
                if (user.error === 'USER_MISSING') {
                    navigate('/verified/register');
                } else {
                    navigate('/authentication-callback');
                }
            } else {
                showToast(t('verifyEmailPage.emailCouldNotBeVerifiedTitle'), t('verifyEmailPage.emailCouldNotBeVerifiedDescription'), 'error')
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error');
        }

        setIsLoading(false);
    }

    if (!email) {
        return (null);
    } else {
        return (
            <div className="flex h-screen justify-center p-6">
                <Logo className="absolute top-12" />
                <div className="flex flex-col m-auto max-w-md z-20">
                    <h1 className="text-2xl font-medium">{t('verifyEmailPage.verifyEmailTitle')}</h1>
                    <span>{t('verifyEmailPage.verifyEmailDescription', { email: email })}</span>
                    <div className="flex justify-between mt-6">
                        <ButtonText isSecondary={true} onClick={() => { logout(); }}>{t('generic.cancel')}</ButtonText>
                        <Button onClick={(e) => { handleCheckIfEmailIsVerified(); }} loading={loading}>
                            <ArrowRight />
                        </Button>
                    </div>
                    <div className="flex flex-col mt-6 gap-y-3">
                        <a href="#" onClick={(e) => { e.preventDefault(); setNotReceivedVerificationCode(true); }} className="text-airpark-green">{t('verifyEmailPage.noVerificationEmailReceivedTitle')}</a>
                        {notReceivedVerificationCode &&
                            <>
                                <Alert title={t('verifyEmailPage.noVerificationEmailReceivedTitle')} description={t('verifyEmailPage.noVerificationEmailReceivedDescription')} />
                                <ButtonText
                                    isSecondary={true}
                                    onClick={() => { resendVerificationCode(); }}
                                    isLoading={isResendingVerificationCode}>
                                        {t('verifyEmailPage.resendVerificationEmail')}
                                </ButtonText>
                            </>
                        }
                    </div>
                </div>
                <BackgroundIcon className="absolute bottom-6 left-0 max-h-[100%] h-[150px] w-[275px] z-10" />
            </div>
        );
    }

};

export default VerifyEmailPage;