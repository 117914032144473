import React, { useState, useEffect } from 'react';
import moment from "moment";
import { useTranslation } from "react-i18next";

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const WeeklyTimeslotDisplay = ({ timeRanges }) => {
  const { t } = useTranslation();
  const [timeslotStyles, setTimeslotStyles] = useState({});

  const getDaysOfWeekWithinRange = (range) => {
    const startIndex = daysOfWeek.indexOf(range.fromDayOfWeek);
    const endIndex = daysOfWeek.indexOf(range.toDayOfWeek);
    var days = [];

    const shouldReturnWholeWeek = () => {
      return range.moreThanOneWeek || (isSameDay() && isMoreThanOneDay());
    }

    const isMoreThanOneDay = () => {
      return moment(range.toDate).diff(moment(range.fromDate), 'hours') > 24;
    }

    const isSameDay = () => {
      return startIndex === endIndex;
    }

    const shouldReturnDay = (i) => {
      return isWithinRange(i) || (isCrossingWeek() && isWithinCrossingRange(i));
    }

    const isWithinRange = (i) => {
      return i <= endIndex && i >= startIndex;
    }

    const isCrossingWeek = () => {
      return startIndex > endIndex;
    }

    const isWithinCrossingRange = (i) => {
      return i <= endIndex || i >= startIndex;
    }

    if (shouldReturnWholeWeek()) {
      days = daysOfWeek;
    } else {
      daysOfWeek.forEach((day, i) => {
        if (shouldReturnDay(i)) {
          days.push(day);
        }
      })
    }

    return days;
  }

  useEffect(() => {
    const styles = {};
    const percentages = [];

    const isWithinSingleDay = (range, day) => {
      return range.fromDate === range.toDate && range.fromDayOfWeek === day && range.toDayOfWeek === day;
    }

    const isLoopedToSameDay = (range, day) => {
      return range.fromDate !== range.toDate && range.fromDayOfWeek === day && range.toDayOfWeek === day;
    }

    timeRanges.forEach(range => {
      const days = getDaysOfWeekWithinRange(range);
      days.forEach(day => {
        if (range.moreThanOneWeek) {
          percentages.push({ day: day, start: 0, end: 100 });
        } else if (isWithinSingleDay(range, day)) {
          const startPercent = (parseInt(range.fromTime.split(':')[0], 10) / 24) * 100;
          const endPercent = (parseInt(range.toTime.split(':')[0], 10) / 24) * 100;
          percentages.push({ day: day, start: startPercent, end: endPercent });
        } else if (isLoopedToSameDay(range, day)) {
          const startPercent = (parseInt(range.fromTime.split(':')[0], 10) / 24) * 100;
          const endPercent = (parseInt(range.toTime.split(':')[0], 10) / 24) * 100;
          percentages.push({ day: day, start: 0, end: endPercent });
          percentages.push({ day: day, start: startPercent, end: 100 });
        } else if (range.fromDayOfWeek === day) {
          const startPercent = (parseInt(range.fromTime.split(':')[0], 10) / 24) * 100;
          percentages.push({ day: day, start: startPercent, end: 100 });
        } else if (range.toDayOfWeek === day) {
          const endPercent = (parseInt(range.toTime.split(':')[0], 10) / 24) * 100;
          percentages.push({ day: day, start: 0, end: endPercent });
        } else {
          percentages.push({ day: day, start: 0, end: 100 });
        }
      })
    })

    daysOfWeek.forEach(day => {
      const percentagesForDay = percentages.filter(p => p.day === day).sort((a, b) => a.start - b.start);
      if (percentagesForDay.length > 0) {
        var style = 'linear-gradient(to right';
        percentagesForDay.forEach(p => {
          style = style + `, #e0e0e0 ${p.start}%, #4caf50 ${p.start}%, #4caf50 ${p.end}%, #e0e0e0 ${p.end}%`
        })
        style = style + ')';
        styles[day] = style;
      }
    })

    setTimeslotStyles(styles);
  }, [timeRanges]);

  return (
    <div className="flex justify-between px-4 py-2 bg-gray-100 overflow-x-auto">
      {daysOfWeek.map(day => (
        <div key={day} className="flex flex-col items-center px-2">
          <h3 className="mb-2 text-sm font-semibold">{t(`daySingularNames.${day}`)}</h3>
          <div className="w-32 h-10" style={{ background: timeslotStyles[day] || '#e0e0e0' }}>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WeeklyTimeslotDisplay;
