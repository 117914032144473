import React, { useEffect, useRef, useState } from 'react';

const CollapsibleContainer = ({ children, isExpanded }) => {
    const [opacity, setOpacity] = useState();
    const sectionRef = useRef(null);
    const [isInitialized, setIsInitialized] = useState();

    const removeStyleAttribute = () => {
        sectionRef.current.removeAttribute('style');
        sectionRef.current.removeEventListener('transitionend', removeStyleAttribute);
    }

    useEffect(() => {

        if (isInitialized) {
            if (isExpanded) {

                setOpacity('opacity-100');
                setTimeout(() => {
                    sectionRef.current.style.height = sectionRef.current.scrollHeight+'px';
                }, 10);
    
                if (sectionRef.current.hasAttribute('style')) {
                    sectionRef.current.addEventListener('transitionend', removeStyleAttribute);
                }
            }
    
            if (!isExpanded) {
                setOpacity('opacity-0');
    
                setTimeout(() => {
                    sectionRef.current.style.height = '0px';
                }, 10);
    
                sectionRef.current.style.height = sectionRef.current.scrollHeight+'px';
            }
        } else {
            if (!isExpanded) {
                setOpacity('opacity-0');
                sectionRef.current.style.height = '0px';
            }
        }

        setIsInitialized(true);
    }, [isExpanded]);

    return (
        <div ref={sectionRef} className={`section ${isExpanded ? 'overflow-visible' : 'overflow-hidden'} transition-all duration-500 ease-in-out ${opacity}`}>
            {children}
        </div>
    );
};

export default CollapsibleContainer;