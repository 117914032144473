import React, { useEffect, useState } from 'react';
import { ReactComponent as AirParkSmallIcon } from 'assets/icons/ico_airpark_p_green.svg';
import { ReactComponent as IconMenuClose } from 'assets/icons/ico_menu_close.svg';
import { ReactComponent as IconMenuOpen } from 'assets/icons/ico_menu_open.svg';
import { ReactComponent as NotificationsIcon } from 'assets/icons/ico_notifications.svg';
import { ReactComponent as IconDown } from 'assets/icons/ico_arrow_down_green.svg';
import { organizationMenuItems, leftMenuParkingAreaItems } from 'data/menuItems';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDashboard } from 'context/dashboard';
import ButtonText from 'components/common/buttonText';
import useAuth from 'context/auth';
import DropdownSelector from 'components/common/dropdownSelector/dropdownSelector';


const MobileMenu = ({ isOrganizationLevel }) => {

    const { organization, organizations, showNotificationsMenu, setShowNotificationsMenu, notifications } = useDashboard();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuItems, setMenuItems] = useState(isOrganizationLevel ? organizationMenuItems : leftMenuParkingAreaItems);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { logout } = useAuth();

    const toggleNotificationMenu = (e) => {
        e.preventDefault();
        setShowNotificationsMenu(!showNotificationsMenu);
    }

    const handleOpenSubMenu = (item) => {
        if (item.subItems) {
            const newMenuItems = menuItems.map((menuItem) => {
                if (menuItem.to === item.to) {
                    menuItem.isOpen = !menuItem.isOpen;
                }
                return menuItem;
            });
            setMenuItems(newMenuItems);
        }
    }

    const handleLogoClick = (e) => {
        e.preventDefault();
        if (organization) {
            navigate(`/${organization.organizationExtId.toLowerCase()}`)
        }
    }

    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [isMenuOpen])

    return (
        <div className="pb-16 lg:hidden">
            <nav className="flex fixed inset-0 h-16 w-full items-center justify-between px-6 z-50 bg-white shadow">
                <div className="flex items-center gap-x-2 cursor-pointer" onClick={(e) => { handleLogoClick(e); }}>
                    <AirParkSmallIcon className="h-11" />
                    <span className="font-medium text-lg">AirPark</span>
                </div>
                <div className="flex gap-x-3">
                    {/* <button onClick={(e) => { toggleNotificationMenu(e); }}>
                        <div className="ml-4 relative">
                            <NotificationsIcon className="h-5 w-5" />
                            {notifications?.filter(n => n.read == false).length > 0 &&
                                <span className="h-5 w-5 bg-airpark-red rounded-full text-white text-xs flex items-center justify-center absolute -top-2.5 -left-2.5 shadow">
                                    {notifications?.filter(n => n.read == false).length}
                                </span>
                            }
                        </div>
                    </button> */}
                    <button onClick={(e) => { e.preventDefault(); setIsMenuOpen(true); }}>
                        <IconMenuOpen className="h-6 w-6" />
                    </button>
                </div>
                {isMenuOpen &&
                    <div className="fixed inset-0 z-50 bg-black opacity-70" onClick={(e) => { e.preventDefault(); setIsMenuOpen(false); }} />
                }
                <ul className={`flex flex-col gap-y-3 p-6 fixed bg-white max-w-[375px] w-full top-0 z-50 transition-all ease-in-out duration-300 h-screen ${isMenuOpen ? 'right-0' : '-right-[375px] '}`}>
                    <button className="flex h-11 w-11 mb-6 items-center justify-center -ml-3" onClick={(e) => { e.preventDefault(); setIsMenuOpen(false); }}>
                        <IconMenuClose className="h-6 w-6" />
                    </button>
                    {menuItems?.map((item, index) => {
                        return (
                            <li
                                className="flex flex-col text-body font-medium text-airpark-oil-400 cursor-pointer relative"
                                key={index}
                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); navigate(item.to); setIsMenuOpen(false); }}>
                                <div className="flex items-center">
                                    {item.title ?? t(item.translationKey)}
                                    {item.subItems?.length > 0 &&
                                        <button className="h-6 w-11 pl-2" onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleOpenSubMenu(item); }}>
                                            <IconDown className={`flex h-2 flex-shrink-0 ${item.isOpen ? 'rotate-180' : ''} transition-all duration-300 ease-in-out`} />
                                        </button>
                                    }
                                </div>
                                {item.isOpen && item.subItems?.length > 0 && (
                                    <div className="flex flex-col mt-3 mb-6 pl-6 gap-y-3">
                                        {item.subItems?.map((subItem, index) => {
                                            return (
                                                <a href="#" key={index} onClick={(e) => { e.preventDefault(); e.stopPropagation(); navigate(subItem.to); setIsMenuOpen(false); }}>
                                                    {subItem.title ?? t(subItem.translationKey)}
                                                </a>
                                            )
                                        })}
                                    </div>
                                )}
                            </li>)
                    })}
                    <li>
                        <ButtonText onClick={() => { logout(); }} isSecondary={false} className="my-4">
                            <span className="text-center w-full">{t('topBar.logout')}</span>
                        </ButtonText>
                    </li>
                    {organization && organizations &&
                        <>
                            <DropdownSelector
                                options={organizations.map((organization) => { return { title: organization.name, value: organization.organizationExtId } })}
                                defaultValue={organization?.organizationExtId}
                                onChange={(value) => { navigate(`/${value.toLowerCase()}`); }} />
                        </>
                    }
                </ul>
            </nav>
        </div>
    );
};

export default MobileMenu;