import React, { useState, useEffect } from "react";
import { useMessages } from "context/messages";
import { getToken } from "context/auth";
import { useTranslation } from "react-i18next";

import VideoInstructions from "components/common/videoInstructions";
import Widget from "components/dashboard/widget";
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import ControlFeesTable from "components/pageSpecific/controlFees/controlFeesTable";
import { getControlFeesForParkingArea } from "api/controlFees";
import { useParams } from "react-router-dom";
import Alert from "components/common/alert";

const ControlFeesPage = () => {
    const { t } = useTranslation();
    const [controlFees, setControlFees] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const { showToast } = useMessages();
    const { parkingAreaExtId } = useParams();

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const result = await getControlFeesForParkingArea(token, parkingAreaExtId);

                if (result && !result.error) {
                    setControlFees(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, []);

    return (
        <>
            <Alert
                title={t('controlFeesPage.signsRequiredTitle')}
                description={t('controlFeesPage.signsRequiredDescription')}
                template='info'
                clickTitle={t('controlFeesPage.contactAirPark')}
                onClick={() => { window.open('mailto:hello@airpark.app'); }} />
            <Widget className="mt-6">
                <div className="flex flex-col">
                    <h1 className="text-xl font-medium mb-3">{t('controlFeesPage.title')}</h1>
                    <span>{t('controlFeesPage.description')}</span>
                </div>
            </Widget>
            <div className="mt-6 mb-6">
                <VideoInstructions
                    title={t('generic.videoInstructions')}
                    description={t('controlFeesPage.videoInstructionsDescription')}
                    videoUrl='https://www.youtube.com/watch?v=pTiWJH2RbP8' />
            </div>
            {isLoading &&
                <WidgetLoadingSkeleton />
            }
            {!isLoading &&
                <Widget>
                    <ControlFeesTable controlFees={controlFees} />
                </Widget>
            }
        </>
    )
}

export default ControlFeesPage;