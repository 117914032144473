const BASE_URL = `${process.env.REACT_APP_API_USERS_BASE_URL}/Notifications`;

export const getNotificationsForUser = async (token) => {
    const response = await fetch(BASE_URL + '/notifications-for-user', {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const markAllNotificationsAsRead = async (token) => {
    const response = await fetch(BASE_URL + '/mark-all-notifications-as-read', {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        const responseBody = await response.text();

        if (!responseBody) {
            return {};
        } else {
            return JSON.parse(responseBody);
        }
    }

    throw response;
}
