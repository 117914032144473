import React from "react";

const StepIndicator = ({ currentStep, totalNumberOfSteps }) => {

    let indicators = [];

    for (let i = 1; i <= totalNumberOfSteps; i++) {
        indicators.push(<>
            <Indicator key={i}  step={i} currentStep={currentStep} />
            {(i != totalNumberOfSteps) &&
                <div key={`${i}-separator`} className="h-[1px] w-4 bg-airpark-gray-500 m-1 hidden md:block" />
            }
        </>);
    }

    return (
        <div className="flex flex-row items-center">
            {indicators}
        </div>
    );
}

const Indicator = ({ step, currentStep }) => {
    if (step <= currentStep) {
        return (
            <div key={`${step}-indicator`} className="flex h-11 w-11 rounded-full bg-airpark-green justify-center items-center mr-2 md:mr-0">
                <span className="text-white">{step}</span>
            </div>
        );
    } else {
        return (
            <div key={`${step}-indicator`} className="flex h-11 w-11 rounded-full bg-white border-[1px] border-airpark-gray-400 justify-center items-center mr-2 last:mr-0 md:mr-0">
                <span className="text-airpark-gray-500">{step}</span>
            </div>
        );
    }
}

export default StepIndicator;