import React from 'react';
import { useTranslation } from "react-i18next";
import UserItem from './organizationUserItem';

const OrganizationUsersTable = ({ 
    filteredAdmins,
    filteredSublessors,
    setFilteredAdmins,
    setFilteredSublessors
}) => {
    const { t } = useTranslation();

    if (!filteredAdmins || !filteredSublessors) {
        return (null);
    }

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">{t('usersTable.firstName')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('usersTable.lastName')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('usersTable.email')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('usersTable.accessLevel')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSublessors && filteredSublessors.length > 0 && filteredSublessors.map((user, i) => {
                            return (<UserItem 
                                        key={user.userExtId}
                                        user={user} 
                                        users={filteredSublessors}
                                        setUsers={setFilteredSublessors}
                                        type={'sublessor'}
                                        i={user.userExtId} />)
                        })}
                        {filteredAdmins && filteredAdmins.length > 0 && filteredAdmins.map((user, i) => {
                            return (<UserItem 
                                        key={user.userExtId}
                                        user={user} 
                                        users={filteredAdmins}
                                        setUsers={setFilteredAdmins}
                                        type={'portalAdmin'}
                                        i={user.userExtId} />)
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default OrganizationUsersTable;