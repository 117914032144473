import React from 'react';
import { useTranslation } from "react-i18next";

import InviteItem from './organizationInviteItem';

const OrganizationInviteTable = ({ sublessorInvites, setSublessorInvites, portalAdminInvites, setPortalAdminInvites }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">{t('invitesTable.issuedBy')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('invitesTable.contact')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('invitesTable.sentAt')}</th>
                            <th className="pl-6 whitespace-nowrap"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {sublessorInvites && sublessorInvites.length > 0 && sublessorInvites.map((invite, i) => {
                            return (<InviteItem 
                                        key={i} 
                                        invite={invite} 
                                        invites={sublessorInvites} 
                                        setInvites={setSublessorInvites} />)
                        })}
                        {portalAdminInvites && portalAdminInvites.length > 0 && portalAdminInvites.map((invite, i) => {
                            return (<InviteItem 
                                        key={i} 
                                        invite={invite} 
                                        invites={portalAdminInvites} 
                                        setInvites={setPortalAdminInvites} />)
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default OrganizationInviteTable;