import Widget from 'components/dashboard/widget';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMessages } from 'context/messages';

import { ReactComponent as PermitMarketPlaceIcon } from 'assets/icons/ico_permit_marketplace.svg';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import ButtonText from 'components/common/buttonText';
import { useNavigate, useParams } from 'react-router-dom';
import { getMarketplacePermitsForParkingArea } from 'api/permits';
import { getToken } from 'context/auth';
import PermitsTable from 'components/pageSpecific/permits/permitsTable';

const PermitMarketplacePage = () => {

    const { t } = useTranslation();
    const { showToast } = useMessages();
    const [isLoading, setIsLoading] = useState(true);
    const { organizationExtId, parkingAreaExtId } = useParams();
    const [permits, setPermits] = useState();
    const navigate = useNavigate();

    useEffect(() => {

        const load = async () => {
            try {
                const token = await getToken();
                const result = await getMarketplacePermitsForParkingArea(token, parkingAreaExtId);

                if (result && !result.error) {
                    setPermits(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('loginPage.emailIsNotValid'), 'error');
                }
            } catch (error) {

            }

            setIsLoading(false);
        }

        load();
    }, []);

    return (
        <>
            <Widget>
                <div className="flex md:flex-row flex-col-reverse gap-12 justify-between items-center">
                    <div className="flex flex-col">
                        <h1 className="text-xl font-medium mb-3">{t('permitMarketplacePage.title')}</h1>
                        <span>{t('permitMarketplacePage.description')}</span>
                    </div>
                    <span className="flex w-20 h-20">
                        <PermitMarketPlaceIcon />
                    </span>
                </div>
            </Widget>
            {isLoading &&
                <div className="mt-6">
                    <WidgetLoadingSkeleton />
                </div>
            }
            {!isLoading &&
                <Widget className="mt-6">
                    <div className="flex items-center justify-between mb-6">
                        <h2 className="text-xl font-medium mb-3">{t('permitsPage.addPermitTitle')}</h2>
                        <ButtonText onClick={() => { navigate(`/${organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId}/create-marketplace-permit`) }}>
                            {t('permitMarketplacePage.create')}
                        </ButtonText>
                    </div>
                    <PermitsTable
                        permits={permits}
                        setPermits={setPermits}
                        isMarketplace={true} />
                </Widget>
            }
        </>
    );
};

export default PermitMarketplacePage;