import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { getParkingSpots } from "api/locations";
import { addChargingStationToParkingSpot, getChargingProviders, getChargingStation, updateChargingStation } from "api/charging";

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ico_arrow_left.svg';

import ButtonText from "components/common/buttonText";
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import DropdownSelector from "components/common/dropdownSelector/dropdownSelector";
import InputText from "components/common/inputText/inputText";
import SelectParkingSpotsModal from "components/dashboard/selectParkingSpotsModal";

import { getToken } from "context/auth";
import { useDashboard } from "context/dashboard";
import { useMessages } from "context/messages";

import Alert from "components/common/alert";

const ChargingStationPage = () => {

    const navigate = useNavigate();
    const { organization } = useDashboard();
    const { showToast, showBetweenPagesMessage } = useMessages();
    const { t } = useTranslation();
    const { parkingAreaExtId, chargingStationExtId } = useParams();
    const [isLoading, setIsLoading] = useState();
    const [displaySelectParkingSpotModal, setDisplaySelectParkingSpotModal] = useState();
    const [parkingSpots, setParkingSpots] = useState();
    const [chargingStation, setChargingStation] = useState({
        parkingSpot: [],
        chargingProviderExtId: ''
    });
    const [isSaving, setIsSaving] = useState();
    const [chargingProviders, setChargingProviders] = useState([]);

    useEffect(() => {
        const load = async () => {

            setIsLoading(true);

            try {
                const token = await getToken();

                /* Always get parking spots for the parking area */
                const parkingSpotsResult = await getParkingSpots(token, parkingAreaExtId);
                const chargingProvidersResult = await getChargingProviders(token, parkingAreaExtId);
                let chargingStationResult = null;

                if (chargingStationExtId) {
                    chargingStationResult = await getChargingStation(token, chargingStationExtId);
                }

                if (!parkingSpotsResult?.error) {
                    setParkingSpots(parkingSpotsResult.filter(p => p.isNumbered && p.hasChargingPost));
                } else {
                    console.error(parkingSpotsResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                if (!chargingProvidersResult?.error) {
                    setChargingProviders(chargingProvidersResult);

                    if (chargingStationResult && parkingSpotsResult) {
                        setChargingStation({
                            ...chargingStationResult,
                            parkingSpot: parkingSpotsResult.filter(p => p.parkingSpotExtId === chargingStationResult.parkingSpotExtId)
                        });
                    } else {
                        setChargingStation({ ...chargingStation, chargingProviderExtId: chargingProvidersResult[0].chargingProviderExtId });
                    }
                } else {
                    console.error(chargingProvidersResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, [parkingAreaExtId, chargingStationExtId]);

    const handleBackButton = () => {
        navigate(-1);
    }

    const handleSave = async () => {
        if (isSaving) {
            return;
        }

        const payload = {
            parkingSpotExtId: chargingStation.parkingSpot?.length > 0 ? chargingStation.parkingSpot[0].parkingSpotExtId : null,
            chargingProviderExtId: chargingStation.chargingProviderExtId,
            chargingStationExternalId: chargingStation.chargingStationExternalId,
            chargingStationExtId: chargingStation.chargingStationExtId,
            parkingAreaExtId: parkingAreaExtId
        };

        if (!chargingStation.chargingStationExternalId || !chargingStation.chargingProviderExtId || !chargingStation.parkingSpot.length) {
            showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error');
            return;
        }

        setIsSaving(true);

        try {
            const token = await getToken();

            if (!chargingStationExtId) {
                const result = await addChargingStationToParkingSpot(token, payload);

                if (!result.error) {
                    showBetweenPagesMessage(
                        t('chargingStationPage.addSuccessTitle'),
                        t('chargingStationPage.addSuccessDescription'),
                        () => { navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId.toLowerCase()}/charging`); },
                        'success');
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } else {
                const result = await updateChargingStation(token, payload);

                if (!result.error) {
                    showBetweenPagesMessage(
                        t('chargingStationPage.updatedSuccessTitle'),
                        t('chargingStationPage.updatedSuccessDescription'),
                        () => { navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId.toLowerCase()}/charging`); },
                        'success');
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSaving(false);
    }

    return (
        <>
            <button className="flex p-6 absolute top-12 left-12 md:visible invisible" onClick={(e) => { e.preventDefault(); handleBackButton(); }}>
                <ArrowLeftIcon />
            </button>
            <div className="flex flex-col m-auto max-w-[448px] w-full">
                {!isLoading && chargingStation && organization && parkingSpots && chargingProviders &&
                    <>
                        <h1 className="mt-14 text-center text-2xl font-medium mb-12">{chargingStationExtId ? t('chargingStationPage.editTitle') : t('chargingStationPage.addTitle')}</h1>
                        <div className="flex flex-col gap-y-3">
                            <DropdownSelector
                                title={t('chargingStationPage.selectProvider')}
                                options={chargingProviders.map(p => { return { title: p.name, value: p.chargingProviderExtId } })}
                                defaultValue={chargingProviders[0].chargingProviderExtId}
                                onChange={(value) => { setChargingStation({ ...chargingStation, chargingProviderExtId: value }) }} />
                            <InputText
                                title={t('waybler.chargingStationId')}
                                placeHolder={t('waybler.chargingStationIdPlaceholder')}
                                value={chargingStation.chargingStationExternalId}
                                onChange={(value) => { setChargingStation({ ...chargingStation, chargingStationExternalId: value }) }}
                            />
                        </div>
                        <hr className="w-full mt-6 mb-6 border-airpark-gray-400" />
                        {chargingStation.parkingSpot.length === 0 &&
                            <Alert template='warning'>
                                {t('chargingStationPage.selectParkingSpotWarning')}
                            </Alert>
                        }
                        {chargingStation.parkingSpot.length > 0 &&
                            <Alert template='info'>
                                {t('chargingStationPage.selectedParkingSpot', { spot: chargingStation.parkingSpot[0].spotIdentifier })}
                            </Alert>
                        }
                        <ButtonText
                            isSecondary={true}
                            className="w-full mb-3 mt-3"
                            onClick={() => { setDisplaySelectParkingSpotModal(true); }}>
                            {t('chargingStationPage.selectParkingSpot')}
                        </ButtonText>
                        <ButtonText
                            className="mt-3"
                            isLoading={isSaving}
                            disabled={isSaving}
                            onClick={() => { handleSave(); }}>
                            {chargingStationExtId ? t('generic.update') : t('generic.save')}
                        </ButtonText>
                        <SelectParkingSpotsModal
                            display={displaySelectParkingSpotModal}
                            parkingSpots={parkingSpots}
                            isSingleSelectionOnly={true}
                            selectedParkingSpots={chargingStation.parkingSpot}
                            setSelectedParkingSpots={(value) => { setChargingStation({ ...chargingStation, parkingSpot: value }) }}
                            setDisplay={setDisplaySelectParkingSpotModal} />
                    </>
                }
                {isLoading &&
                    <WidgetLoadingSkeleton />
                }
            </div>
        </>
    );
};

export default ChargingStationPage;