import React from "react";

const InputTime = ({title, value, onChange, className}) => {

    const baseClassName = 'border-airpark-gray-300 hover:border-airpark-gray-400 border-2 h-11 rounded-xl pl-2 pr-2 text-airpark-green font-medium text-center focus:outline-none';

    if (!className) {
        className = baseClassName;
    } else {
        className = `${baseClassName} ${className}`;
    }

    const handleTimeChange = (value) => {
        onChange(value);
    }

    return (
        <>
            <span className="font-medium pb-1">{title}</span>
            <div className="flex flex-row w-full">
                <input value={value} onChange={(e) => { handleTimeChange(e.target.value); }} type="time" className={className} />
            </div>
        </>
    );
}

export default InputTime;