import React from "react";

const ButtonTogle = ({ title, onClick, defaultIcon, toggledIcon, isToggled, className }) => {

    let classNameButton = isToggled ? 
    'flex flex-col cursor-pointer items-center justify-center bg-airpark-green hover:bg-airpark-green-400 h-20 w-28 rounded-lg' :
    'flex flex-col cursor-pointer items-center justify-center h-20 w-28 rounded-lg border-airpark-gray-400 hover:border-airpark-gray-500 border-[1px]';

    if (className) {
        classNameButton = `${classNameButton} ${className}`;
    }

    const classNameText = isToggled ? 
    'font-normal text-sm text-white select-none' : 
    'font-normal text-sm select-none';

    return (
        <button onClick={(e) => { e.preventDefault(); onClick(); }} className={classNameButton}>
            {isToggled &&
                toggledIcon
            }
            {!isToggled &&
                defaultIcon
            }
            <span className={classNameText}>{title}</span>
        </button>
    );
}

export default ButtonTogle;