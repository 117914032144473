import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';

import { getUser } from 'api/users';
import { acceptOrganizationInvite, getOrganizationInvite } from 'api/organizations';

import { useMessages } from 'context/messages';
import useAuth, { getToken } from 'context/auth';

import Logo from "components/common/logo";
import Alert from 'components/common/alert';
import PageLoad from 'components/common/pageLoad';
import ButtonText from 'components/common/buttonText';

const OrganizationInvitePage = () => {

    const { organizationInviteExtId } = useParams();
    const { isAuthenticated, isFinishedCheckingIfUserIsAuthenticated, logout } = useAuth();
    const { showToast } = useMessages();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isAccepting, setIsAccepting] = useState();
    const [organizationInvite, setOrganizationInvite] = useState();
    const [inviteNotFound, setInviteNotFound] = useState();
    const navigate = useNavigate();

    const [user, setUser] = useState();

    useEffect(() => {
        const load = async () => {
            try {

                if (isAuthenticated) {
                    const token = await getToken();
                    const result = await getUser(token);

                    if (result && !result.error) {
                        setUser(result);
                    } else {
                        console.error(result);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    }
                }

                const result = await getOrganizationInvite(organizationInviteExtId);

                if (result && !result.error) {
                    setOrganizationInvite(result);
                } else if (result && result.error == 'INVITE_NOT_FOUND') {
                    setInviteNotFound(true);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        if (isFinishedCheckingIfUserIsAuthenticated) {
            load();
        }
    }, [isFinishedCheckingIfUserIsAuthenticated]);

    const handleAcceptOrganizationInvite = async () => {

        if (isAccepting) {
            return;
        } else {
            setIsAccepting(true);
        }

        try {
            const token = await getToken(true);
            const emailInfo = getEmailFromToken(token);

            if (emailInfo.email != organizationInvite.email) {
                showToast(t('organizationInvitationPage.emailDoesNotMatchTitle'), t('organizationInvitationPage.emailDoesNotMatchDescription'), 'error');
                setIsAccepting(false);
                return;
            }

            if (emailInfo.verified) {
                const result = await acceptOrganizationInvite(token, organizationInviteExtId);
                
                if (result && !result.error) {
                    navigate(`/${result.organizationExtId}`);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } else {
                showToast(t('organizationInvitationPage.emailNotVerifiedTitle'), t('organizationInvitationPage.emailNotVerifiedDescription'), 'error');
            }
        } catch (error) {
            console.error();
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsAccepting(false);
    }

    const getEmailFromToken = (token) => {
        const decodedToken = jwtDecode(token);
        return { verified: decodedToken['https://airpark.app/email_verified'], email: decodedToken['https://airpark.app/email'] };
    }

    return (
        <>
            {!isLoading &&
                <div className="flex flex-col p-6">
                    <div className="flex justify-center">
                        <Logo className="p-6" />
                    </div>
                    <div className="flex flex-col m-auto max-w-[448px] w-full">
                        <div className="flex flex-col">
                            {organizationInvite &&
                                <>
                                    <h1 className="mt-14 text-center text-2xl font-medium mb-8">{t('organizationInvitationPage.youveBeenInvitedTitle')}</h1>
                                    <span>{t('organizationInvitationPage.youveBeenInvitedDescription', { organizationName: organizationInvite.organizationName })}</span>
                                    <span className="pt-1">{t('organizationInvitationPage.useEmail')} <span className="font-medium">{organizationInvite.email}</span>.</span>
                                </>
                            }
                            {!user && organizationInvite &&
                                <>
                                    <ButtonText className="mt-6" onClick={() => {
                                        setIsLoading(true);
                                        localStorage.setItem('redirect_path', window.location.pathname);
                                        window.location.href = `https://auth.airpark.app/authorize?audience=https://logic.airpark.app&scope=offline_access email&response_type=code&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${window.location.origin}/authentication-callback&login_hint=${organizationInvite.email}&screen_hint=signup`;
                                    }}>
                                        {t('generic.signup')}
                                    </ButtonText>
                                    <ButtonText className="mt-6" isSecondary={true} onClick={() => {
                                        setIsLoading(true);
                                        localStorage.setItem('redirect_path', window.location.pathname);
                                        window.location.href = `https://auth.airpark.app/authorize?audience=https://logic.airpark.app&scope=offline_access email&response_type=code&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${window.location.origin}/authentication-callback&login_hint=${organizationInvite.email}`;
                                    }}>
                                        {t('generic.signin')}
                                    </ButtonText>
                                </>
                            }
                            {user && organizationInvite &&
                                <>
                                    <ButtonText className="mt-6" onClick={() => { handleAcceptOrganizationInvite(); }} isLoading={isAccepting}>
                                        {t('organizationInvitationPage.acceptInvite')}
                                    </ButtonText>
                                    <ButtonText className="mt-6" isSecondary={true} onClick={() => { logout(); }}>
                                        {t('generic.signout')}
                                    </ButtonText>
                                </>
                            }
                            {inviteNotFound &&
                                <div className="mt-14">
                                    <Alert title={t('organizationInvitationPage.invitationNotFoundTitle')} description={t('organizationInvitationPage.invitationNotFoundDescription')} template='error' />
                                </div>
                            }
                        </div>

                    </div>
                </div>
            }
            {isLoading &&
                <PageLoad />
            }
        </>
    );
};

export default OrganizationInvitePage;