import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ico_arrow_left.svg';

const BackButton = () => {
    const navigate = useNavigate();

    const handleBackButton = () => {
        navigate(-1);
    }

    return (
        <button className="flex p-6 absolute top-12 left-12 md:visible invisible" onClick={(e) => { e.preventDefault(); handleBackButton(); }}><ArrowLeftIcon /></button>
    );
};

export default BackButton;