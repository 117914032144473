import Widget from "components/dashboard/widget";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";

import { ReactComponent as CommunityLargeIcon } from 'assets/icons/ico_community_large.svg';

import { useNavigate, useParams } from "react-router-dom";
import ButtonText from "components/common/buttonText";
import CommunitiesTable from "components/pageSpecific/communities/communitiesTable";
import { useMessages } from "context/messages";
import { getToken } from "context/auth";
import { getCommunitiesForParkingArea } from "api/communities";
import VideoInstructions from "components/common/videoInstructions";

const CommunitiesPage = () => {

    const { t } = useTranslation();
    const { showToast } = useMessages();
    const [isLoading, setIsLoading] = useState();
    const [communities, setCommunities] = useState();
    const { organizationExtId, parkingAreaExtId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const load = async () => {

            setIsLoading(true);

            try {
                var token = await getToken();
                var communitiesForParkingAreaResult = await getCommunitiesForParkingArea(token, parkingAreaExtId);
               
                if (communitiesForParkingAreaResult && !communitiesForParkingAreaResult.error) {
                    setCommunities(communitiesForParkingAreaResult);
                } else {
                    console.error(communitiesForParkingAreaResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, []);


    return (
        <>
            <Widget>
                <div className="flex md:flex-row flex-col-reverse gap-12 justify-between items-center">
                    <div className="flex flex-col">
                        <h1 className="text-xl font-medium mb-3">{t('communityPage.title')}</h1>
                        <span>{t('communityPage.description')}</span>
                    </div>
                    <span className="flex w-20 h-20">
                        <CommunityLargeIcon />
                    </span>
                </div>
            </Widget>
            <div className="mt-6">
                <VideoInstructions
                    title={t('generic.videoInstructions')}
                    description={t('communityPage.videoInstructionsDescription')}
                    videoUrl='https://www.youtube.com/watch?v=IcO2HVAJHpg' />
            </div>
            {isLoading &&
                <div className="mt-6">
                    <WidgetLoadingSkeleton />
                </div>
            }
            {!isLoading &&
                <>


                    <Widget className="mt-6">
                        <div className="flex items-center justify-between mb-6">
                            <h1 className="text-xl font-medium">{t('communityPage.communitiesTitle')}</h1>
                            <div className="flex items-center gap-3">
                                <ButtonText onClick={() => { navigate(`/${organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId}/create-community`) }}>
                                    {t('generic.create')}
                                </ButtonText>
                            </div>
                        </div>
                        <CommunitiesTable
                            communities={communities}
                            setCommunities={setCommunities} />
                    </Widget>

                </>
            }
        </>
    )
}

export default CommunitiesPage;