import objectToFormData from "helpers/objectToFormData";

const BASE_URL = `${process.env.REACT_APP_API_USERS_BASE_URL}/Communities`;

export const getCommunitiesForParkingArea = async (token, parkingAreaExtId) => {
    const response = await fetch(BASE_URL + `/get-communities-for-parking-area/${parkingAreaExtId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const getConnectedCommunitiesForParkingArea = async (token, parkingAreaExtId) => {
    const response = await fetch(BASE_URL + `/get-connected-communities-for-parking-area/${parkingAreaExtId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const getParkingAreasThatHasCommunities = async (token) => {
    const response = await fetch(BASE_URL + '/get-parking-areas-that-has-communities/', {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const createCommunity = async (token, community) => {

    const formData = objectToFormData(community);

    const response = await fetch(BASE_URL + '/create-community', {
        method: 'POST',
        headers: {
            'authorization': `Bearer ${token}`
        },
        body: formData
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const getCommunity = async (token, communityExtId) => {
    const response = await fetch(BASE_URL + `/get-community/${communityExtId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response
}

export const updateCommunity = async (token, community) => {
    const formData = objectToFormData(community);

    const response = await fetch(BASE_URL + '/update-community', {
        method: 'PUT',
        headers: {
            'authorization': `Bearer ${token}`
        },
        body: formData
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response
}

export const deleteCommunityImage = async (token, communityImage) => {
    const response = await fetch(BASE_URL + '/delete-image-from-community', {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(communityImage)
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response
}

export const createCommunityInvite = async (token, communityInvite) => {
    const response = await fetch(BASE_URL + '/create-invite', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(communityInvite)
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const deleteCommunityInvite = async (token, communityInviteExtId) => {
    const response = await fetch(BASE_URL + `/delete-invite/${communityInviteExtId}`, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        const responseBody = await response.text();

        if (!responseBody) {
            return {};
        } else {
            return JSON.parse(responseBody);
        }
    }

    throw response;
}

export const getCommunityInvites = async (token, communityExtId) => {
    const response = await fetch(BASE_URL + `/get-invites/${communityExtId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response
}

export const getCommunityInviteLinks = async (token, communityExtId) => {
    const response = await fetch(BASE_URL + `/get-invite-links/${communityExtId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response
}

export const createCommunityConnectRequest = async (token, communityConnectRequest) => {
    const response = await fetch(BASE_URL + '/create-community-connect-request', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(communityConnectRequest)
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const getCommunnityConnectRequests = async (token, parkingAreaExtId) => {
    const response = await fetch(BASE_URL + `/get-community-connect-requests/${parkingAreaExtId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response
}

export const getRecipientCommunnityConnectRequests = async (token, parkingAreaExtId) => {
    const response = await fetch(BASE_URL + `/get-recipient-community-connect-requests/${parkingAreaExtId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response
}

export const getCommunityConnectRequest = async (token, communityConnectRequestExtId) => {
    const response = await fetch(BASE_URL + `/get-community-connect-request/${communityConnectRequestExtId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}

export const deleteCommunityConnectRequest = async (token, communityConnectRequestExtId) => {
    const response = await fetch(BASE_URL + `/delete-community-connect-request/${communityConnectRequestExtId}`, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        const responseBody = await response.text();

        if (!responseBody) {
            return {};
        } else {
            return JSON.parse(responseBody);
        }
    }

    throw response;
}

export const acceptCommunityConnectRequest = async (token, communityConnectRequestExtId) => {
    const response = await fetch(BASE_URL + `/accept-community-connect-request/${communityConnectRequestExtId}`, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        const responseBody = await response.text();

        if (!responseBody) {
            return {};
        } else {
            return JSON.parse(responseBody);
        }
    }

    throw response;
}

export const deleteCommunityConnection = async (token, deleteCommunityConnection) => {
    const response = await fetch(BASE_URL + `/delete-community-connection`, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(deleteCommunityConnection)
    })

    if (response.status === 200) {
        const responseBody = await response.text();

        if (!responseBody) {
            return {};
        } else {
            return JSON.parse(responseBody);
        }
    }

    throw response;
}

export const removeUserFromCommunity = async (token, removeUserRequest) => {
    const response = await fetch(BASE_URL + '/remove-user-from-community', {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(removeUserRequest)
    })

    if (response.status === 200) {
        const responseBody = await response.text();

        if (!responseBody) {
            return {};
        } else {
            return JSON.parse(responseBody);
        }
    }

    throw response;
}
