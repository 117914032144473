import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import Widget from "components/dashboard/widget";
import OrganizationUsersTableWithControls from "components/pageSpecific/organizationUsers/users/organizationUsersTableWithControls";
import OrganizationInviteTableWithControls from "components/pageSpecific/organizationUsers/invites/organizationInviteTableWithControls";
import React from "react";
import { useTranslation } from "react-i18next";
import useOrganizationUsers from "../../components/pageSpecific/organizationUsers/hooks/useOrganizationUsers";
import { getUsersForOrganization, getSublessorsForOrganization } from "api/users";
import { getOrganizationInvites, getOrganizationSublessorInvites } from "api/organizations";
import useOrganizationInvites from "../../components/pageSpecific/organizationUsers/hooks/useOrganizationInvites";
import InviteUserModal from "components/pageSpecific/organizationUsers/invites/inviteUserModal/inviteUserModal";

const OrganizationUsersPage = () => {
    const { t } = useTranslation();
    const users = useOrganizationUsers({ getPortalAdmins: getUsersForOrganization, getSublessors: getSublessorsForOrganization });
    const invites = useOrganizationInvites({ getPortalAdminInvites: getOrganizationInvites, getSublessorInvites: getOrganizationSublessorInvites});
    
    return (
        <div className="flex flex-col gap-y-6">
            <Widget>
                <div className="flex gap-12 justify-between items-center">
                    <div className="flex flex-col">
                        <h1 className="text-xl font-medium mb-1">{t('usersPage.title')}</h1>
                        <span>{t('usersPage.description')}</span>
                    </div>
                </div>
            </Widget>
            {users.isLoading ?
                <div className="mt-6">
                    <WidgetLoadingSkeleton />
                </div>
                :
                <>
                    <OrganizationUsersTableWithControls
                        searchText={users.searchText}
                        setSearchText={users.setSearchText}
                        setDisplayInviteUserModal={users.setDisplayInviteUserModal}
                        filteredAdmins={users.filteredPortalAdmins}
                        filteredSublessors={users.filteredSublessors}
                        title={t('usersPage.users')}
                        setFilteredAdmins={users.setFilteredPortalAdmins}
                        setFilteredSublessors={users.setFilteredSublessors} />
                </>
            }
            {invites.isLoading ?
                <div className="mt-6">
                    <WidgetLoadingSkeleton />
                </div>
                :
                <>
                    <OrganizationInviteTableWithControls
                        searchText={invites.searchText}
                        setSearchText={invites.setSearchText}
                        setDisplayInviteUserModal={invites.setDisplayInviteUserModal}
                        filteredPortalAdminInvites={invites.filteredPortalAdminInvites}
                        filteredSublessorInvites={invites.filteredSublessorInvites}
                        title={t('usersPage.invites')}
                        setFilteredAdminInvites={invites.setFilteredPortalAdminInvites}
                        setFilteredSublessorInvites={invites.setFilteredSublessorInvites} />
                </>
            }
            <InviteUserModal 
                display={users.displayInviteUserModal}
                setDisplay={users.setDisplayInviteUserModal}
                portalAdminInvites={invites.filteredPortalAdminInvites}
                setPortalAdminInvites={invites.setFilteredPortalAdminInvites} 
                sublessorInvites={invites.filteredSublessorInvites}
                setSublessorInvites={invites.setFilteredSublessorInvites} />
        </div>
    )
}

export default OrganizationUsersPage;