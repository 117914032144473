import { createSignatureForOrganization, getOrganizationForUser, getPayoutSettingsForOrganization, updatePayoutSettingsForOrganization } from 'api/organizations';
import { getStripeAccount, getStripeConnectAccountUrl } from 'api/payments';
import Alert from 'components/common/alert';
import ButtonText from 'components/common/buttonText';
import InputNumber from 'components/common/inputNumber';
import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import { getToken } from 'context/auth';
import { useDashboard } from 'context/dashboard';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const PayoutSettingsPage = () => {
    const { t } = useTranslation();
    const { organization } = useDashboard();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingStripeUrl, setIsLoadingStripeUrl] = useState();
    const { showToast } = useMessages();
    const { organizationExtId } = useParams();
    const [stripeAccount, setStripeAccount] = useState();
    const [payoutSettings, setPayoutSettings] = useState();
    const [isValidMinimumPayoutValue, setIsValidMinimumPayoutValue] = useState(true);
    const [isSavingPayoutSettings, setIsSavingPayoutSettings] = useState(false);

    useEffect(() => {

        setIsLoading(true);

        const load = async () => {
            try {
                const token = await getToken();

                const stripeResult = await getStripeAccount(token, organizationExtId);
                const payoutSettingsResult = await getPayoutSettingsForOrganization(token, organizationExtId);

                if (stripeResult && !stripeResult.error && payoutSettingsResult && !payoutSettingsResult.error) {
                    setStripeAccount(stripeResult);
                    setPayoutSettings(payoutSettingsResult);
                } else {
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, []);

    const handleConfigureStripe = async () => {

        if (isLoadingStripeUrl) {
            return;
        }

        setIsLoadingStripeUrl(true);

        try {
            const token = await getToken();
            const result = await getStripeConnectAccountUrl(token, {
                "organizationExtId": organization.organizationExtId,
                "refreshUrl": window.location.href,
                "returnUrl": window.location.href
            });

            if (result && !result.error) {
                window.location.href = result.url;
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                setIsLoadingStripeUrl(false);
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            setIsLoadingStripeUrl(false);
        }

        /* We keep the loading since we navigate away from the page on a successful response */
    }

    useEffect(() => {
        if (payoutSettings && payoutSettings.minimumPayoutAmount) {
            setIsValidMinimumPayoutValue(payoutSettings.minimumPayoutAmount >= 300);
        }
    }, [payoutSettings]);

    const handleChangeMinimumPayoutValue = (value) => {
        setPayoutSettings({
            ...payoutSettings,
            minimumPayoutAmount: value
        });
    }

    const handleSavePayoutSettings = async () => {

        if (isSavingPayoutSettings) {
            return;
        }

        setIsSavingPayoutSettings(true);

        try {
            const token = await getToken();
            const result = await updatePayoutSettingsForOrganization(token, { ...payoutSettings, organizationExtId: organizationExtId });

            if (result && !result.error) {
                showToast(t('generic.updated'), t('generic.updatedDescription'), 'success');
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSavingPayoutSettings(false);
    }

    if (!isLoading && stripeAccount && payoutSettings) {
        return (
            <div className="flex flex-col gap-y-6">
                <Widget>
                    <h2 className="text-xl font-medium pb-1">{t('payoutSettingsPage.stripeTitle')}</h2>
                    <span className="mb-3">{t('payoutSettingsPage.stripeDescription')}</span>
                    {stripeAccount && (!stripeAccount.payoutsEnabled || stripeAccount.requirements?.currentDeadline) &&
                        <Alert
                            title={t('payoutSettingsPage.stripeNotConfiguredTitle')}
                            template="warning"
                            description={t('payoutSettingsPage.stripeNotConfiguredDescription')} />
                    }
                    {stripeAccount && stripeAccount.payoutsEnabled && !stripeAccount.requirements?.currentDeadline &&
                        <Alert
                            title={t('payoutSettingsPage.stripeConfiguredTitle')}
                            template="success"
                            description={t('payoutSettingsPage.stripeConfiguredDescription')} />
                    }
                    <div className="flex justify-center">
                        <ButtonText className="mt-6 max-w-xs w-full" onClick={() => { handleConfigureStripe(); }} isSecondary={false} isLoading={isLoadingStripeUrl}>
                            {t('payoutSettingsPage.configureStripe')}
                        </ButtonText>
                    </div>
                </Widget>
                <Widget>
                    <h2 className="text-xl font-medium pb-1">{t('payoutSettingsPage.payoutDayOfMonthAndMinimumValueTitle')}</h2>
                    <span className="mb-3">{t('payoutSettingsPage.payoutDayOfMonthAndMinimumValueDescription')}</span>

                    <div className="flex md:flex-row flex-col gap-3 mt-3">
                        <div className="md:w-1/2">
                            <InputNumber
                                title={t('payoutSettingsPage.payoutDayOfMonth')}
                                disabled={true}
                                value={payoutSettings.payoutDayOfMonth} />
                        </div>
                        <div className="md:w-1/2">
                            <InputNumber
                                title={t('payoutSettingsPage.minimumPayoutValue')}
                                value={payoutSettings.minimumPayoutAmount}
                                onChange={(value) => { handleChangeMinimumPayoutValue(value); }}
                                tooltip={t('payoutSettingsPage.minimumPayoutValueTooltip')}
                                unit={organization.currencySymbol} />
                        </div>
                    </div>
                    <div className="flex justify-center mt-6">
                        <ButtonText
                            disabled={!isValidMinimumPayoutValue}
                            className="max-w-xs w-full"
                            onClick={() => { handleSavePayoutSettings(); }}
                            isSecondary={true}
                            isLoading={isSavingPayoutSettings}>
                            {t('generic.save')}
                        </ButtonText>
                    </div>
                </Widget>
                {!isValidMinimumPayoutValue &&
                    <div className="mt-6">
                        <Alert
                            title={t('payoutSettingsPage.minimumPayoutValueErrorTitle')}
                            template="error"
                            description={t('payoutSettingsPage.minimumPayoutValueErrorDescription')} />
                    </div>
                }
            </div>
        );
    } else {
        return <WidgetLoadingSkeleton />
    }
};

export default PayoutSettingsPage;