import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { createCommunityInvite, getCommunityInviteLinks, getCommunityInvites } from 'api/communities';

import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';
import { useDashboard } from 'context/dashboard';

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';

import ButtonText from 'components/common/buttonText';
import BackButton from 'components/dashboard/backButton';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import Widget from 'components/dashboard/widget';
import CommunityMembersTable from 'components/pageSpecific/manageCommunity/communityMembersTable';
import InputPhoneNumber from 'components/common/inputPhoneNumber';
import CommunityInvitesTable from 'components/pageSpecific/communities/communityInvitesTable';
import { getUsersForCommunity } from 'api/users';
import CommunityInviteLinksTable from 'components/pageSpecific/manageCommunity/communityInviteLinksTable';

const ManageCommunityMembersPage = () => {

    const [isLoading, setIsLoading] = useState();
    const [inviteMemberPhoneNumber, setInviteMemberPhoneNumber] = useState();
    const [isSendingInvitation, setIsSendingInvitation] = useState();
    const [isCreatingInviteLink, setIsCreatingInviteLink] = useState();
    const [communityMembers, setCommunityMembers] = useState();
    const [communityInvites, setCommunityInvites] = useState();
    const [communityInviteLinks, setCommunityInviteLinks] = useState([]);

    const [resetTrigger, setResetTrigger] = useState(false);

    const { showToast } = useMessages();
    const { communityExtId } = useParams();
    const { t } = useTranslation();
    const { organization } = useDashboard();

    useEffect(() => {

        const load = async () => {
            setIsLoading(true);

            try {
                const token = await getToken();
                const communityMembersResult = await getUsersForCommunity(token, communityExtId);
                const communityInvitesResult = await getCommunityInvites(token, communityExtId);
                const communityInviteLinksResult = await getCommunityInviteLinks(token, communityExtId);

                if (communityMembersResult && !communityMembersResult.error) {
                    setCommunityMembers(communityMembersResult);
                } else {
                    console.error(communityMembersResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                if (communityInvitesResult && !communityInvitesResult.error) {
                    setCommunityInvites(communityInvitesResult);
                } else {
                    console.error(communityInvitesResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                if (communityInviteLinksResult && !communityInviteLinksResult.error) {
                    setCommunityInviteLinks(communityInviteLinksResult);
                } else {
                    console.error(communityInviteLinksResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        if (communityExtId) {
            load();
        }
    }, [communityExtId]);

    const handleInviteCommunityMember = async () => {
        setIsSendingInvitation(true);

        if (isSendingInvitation) {
            return;
        }

        if (!inviteMemberPhoneNumber) {
            showToast(t('manageCommunityMembersPage.phoneNumberMissingTitle'), t('manageCommunityMembersPage.phoneNumberMissingDescription'), 'error');
            setIsSendingInvitation(false);
            return;
        }

        try {
            const token = await getToken();
            const result = await createCommunityInvite(token, { communityExtId: communityExtId, phoneNumber: inviteMemberPhoneNumber.phoneNumber, phoneNumberCountryCodePrefix: inviteMemberPhoneNumber.countryCodePrefix });

            if (result && !result.error) {
                showToast(t('manageCommunityMembersPage.invitationSentTitle'), t('manageCommunityMembersPage.invitationSentDescription'), 'success');
                communityInvites.push(result);
                setCommunityInvites([...communityInvites]);
                setResetTrigger(!resetTrigger);
            }
        } catch (error) {
            console.error();
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSendingInvitation(false);
    }

    const handleCreateCommunityInviteLink = async () => {
        setIsCreatingInviteLink(true);

        if (isCreatingInviteLink) {
            return;
        }

        try {
            const token = await getToken();
            const result = await createCommunityInvite(token, 
                { 
                    communityExtId: communityExtId, 
                    phoneNumber: null, 
                    phoneNumberCountryCodePrefix: null 
                });

            if (result && !result.error) {
                showToast(t('manageCommunityMembersPage.invitationLinkCreatedTitle'), t('manageCommunityMembersPage.invitationLinkCreatedDescription'), 'success');
                communityInviteLinks.push(result);
                setCommunityInviteLinks([...communityInviteLinks]);
            }
        } catch (error) {
            console.error();
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsCreatingInviteLink(false);
    }

    return (
        <>
            <BackButton />
            <div className="flex flex-col m-auto max-w-3xl w-full">
                <h1 className="mt-14 text-center text-2xl font-medium mb-8">{t('manageCommunityMembersPage.title')}</h1>
                {!isLoading &&
                    <>
                        <Widget className="mb-6">
                            <div className="flex flex-col mb-3">
                                <h1 className="text-xl font-medium pb-1">{t('manageCommunityMembersPage.createLinkInvitationTitle')}</h1>
                                <span>{t('manageCommunityMembersPage.createLinkInvitationDescription')}</span>
                            </div>
                            <CommunityInviteLinksTable
                                communityInviteLinks={communityInviteLinks}
                                setCommunityInviteLinks={setCommunityInviteLinks} />
                           
                            <ButtonText 
                                className="mt-6" 
                                onClick={() => { handleCreateCommunityInviteLink(); }}
                                isLoading={isCreatingInviteLink}
                                disabled={isCreatingInviteLink}>
                                    {t('generic.create')}
                            </ButtonText>
                        </Widget>
                        <Widget className="mb-6 gap-y-3">
                            <div className="flex flex-col">
                                <h1 className="text-xl font-medium">{t('manageCommunityMembersPage.sendPhoneInvitationTitle')}</h1>
                                <span>{t('manageCommunityMembersPage.sendPhoneInvitationDescription')}</span>
                            </div>
                            <InputPhoneNumber
                                    title={t('manageCommunityMembersPage.phoneNumber')}
                                    setPhoneNumber={(phoneNumber, countryCodePrefix) => { setInviteMemberPhoneNumber({ countryCodePrefix: countryCodePrefix, phoneNumber: phoneNumber }) }}
                                    defaultCountryCode={organization.countryCode}
                                    resetTrigger={resetTrigger}
                                />
                            <ButtonText 
                                onClick={() => { handleInviteCommunityMember() }} 
                                isLoading={isSendingInvitation} 
                                disabled={isSendingInvitation}>
                                {t('manageCommunityMembersPage.inviteMember')}
                            </ButtonText>
                        </Widget>
                        <Widget className="mb-6">
                            <div className="flex items-center justify-between mb-6">
                                <h1 className="text-xl font-medium">{t('manageCommunityMembersPage.members')}</h1>
                            </div>
                            <CommunityMembersTable
                                communityMembers={communityMembers}
                                setCommunityMembers={setCommunityMembers} />
                        </Widget>
                        <Widget className="mb-6">
                            <div className="flex items-center justify-between mb-6">
                                <h1 className="text-xl font-medium">{t('manageCommunityMembersPage.pendingInvites')}</h1>
                            </div>
                            <CommunityInvitesTable
                                invites={communityInvites}
                                setInvites={(value) => { setCommunityInvites(value); }} />
                        </Widget>
                    </>
                }

                {isLoading &&
                    <WidgetLoadingSkeleton />
                }
            </div>
        </>
    );
};

export default ManageCommunityMembersPage;