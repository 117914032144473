import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PriceRuleSetItem from './priceRuleSetItem';
import { ReactComponent as WarningIcon } from "assets/icons/ico_warning_yellow.svg";
import Paging from 'components/common/paging';

const PriceRuleSetsTable = ({ priceRuleSets, setPriceRuleSets }) => {

    const [priceRuleSetPages, setPriceRuleSetPages] = useState();
    const [currentPriceRuleSetPage, setCurrentPriceRuleSetPage] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (priceRuleSets) {
            setPriceRuleSetPages(Math.ceil(priceRuleSets.length / 10));
            setCurrentPriceRuleSetPage(0);
        }
    }, [priceRuleSets]);

    if (!priceRuleSets) {
        return (null);
    }

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">{t('parkingArea.description')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('parkingArea.period')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('parkingArea.days')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('parkingArea.time')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('parkingArea.parkingSpots')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('parkingArea.availableFor')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('parkingArea.price')}</th>
                            <th className="pl-6 whitespace-nowrap"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {priceRuleSets && priceRuleSets.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={8}>
                                    <div className="flex flex-row items-center justify-center">
                                        <WarningIcon />
                                        <span className="ml-3 text-airpark-yellow">{t('parkingArea.missingPriceRuleSets')}</span>
                                    </div>
                                </td>
                            </tr>
                        }
                        {priceRuleSets && priceRuleSets.length > 0 && priceRuleSets.slice(currentPriceRuleSetPage * 10, ((currentPriceRuleSetPage * 10) + 10)).map((prs, i) => {
                            return (<PriceRuleSetItem
                                key={i}
                                priceRuleSet={prs}
                                priceRuleSets={priceRuleSets}
                                setPriceRuleSets={setPriceRuleSets} />)
                        })
                        }
                    </tbody>
                </table>
            </div>
            {priceRuleSetPages > 0 &&
                <Paging totalNumberOfPages={priceRuleSetPages} currentPage={currentPriceRuleSetPage} setCurrentPage={setCurrentPriceRuleSetPage} />
            }
        </>
    );
};

export default PriceRuleSetsTable;