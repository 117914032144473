const BASE_URL = `${process.env.REACT_APP_API_PRICING_BASE_URL}/PriceRuleSet`

export const getPriceRuleSetsForParkingArea = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL + `/get-price-rule-sets-for-parking-area/${parkingAreaExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getPriceRuleSetByExtId = async (token, priceRuleSetExtId) => {
  const response = await fetch(BASE_URL + `/get-price-rule-set/${priceRuleSetExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const createPriceRuleSet = async (token, priceRuleSet) => {
  const response = await fetch(BASE_URL + '/create-price-rule-set', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(priceRuleSet)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const updatePriceRuleSet = async (token, priceRuleSet) => {
  const response = await fetch(BASE_URL + '/update-price-rule-set', {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(priceRuleSet)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const deletePriceRuleSet = async (token, priceRuleSetExtId) => {
  const response = await fetch(BASE_URL + `/delete-price-rule-set/${priceRuleSetExtId}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}