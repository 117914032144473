import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMessages } from "context/messages";

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ico_arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ico_arrow_right.svg';
import { ReactComponent as GarageIcon } from 'assets/icons/ico_garage_black.svg';
import { ReactComponent as ElectricityIcon } from 'assets/icons/ico_electricity_black.svg';
import { ReactComponent as FenceIcon } from 'assets/icons/ico_fence_black.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/ico_delete.svg';
import { ReactComponent as SunIcon } from 'assets/icons/ico_sun.svg';
import { ReactComponent as ParkingSpotsIcon } from 'assets/icons/ico_map_pin_green_p_drop_shadow.svg';

import Button from "components/common/button";
import ButtonText from "components/common/buttonText";
import AddParkingSpotsModal from "./addParkingSpotsModal";
import { t } from "i18next";
import { useDashboard } from "context/dashboard";
import StepIndicator from "./stepIndicator";
import { useNavigate } from "react-router-dom";

const ParkingSpotsView = ({ setStep, parkingArea, setParkingArea }) => {
    const { t } = useTranslation();
    const [isFormValid, setIsFormValid] = useState(false);
    const { showToast } = useMessages();
    const [showAddParkingSpotModal, setShowAddParkingSpotModal] = useState(false);
    const [totalNumberOfParkingSpots, setTotalNumberOfParkingSpots] = useState();
    const { organization } = useDashboard();
    const navigate = useNavigate();

    const handleRemoveParkingGroup = (i) => {
        let updatedParkingGroups = parkingArea.parkingGroups;
        updatedParkingGroups.splice(i, 1);
        setParkingArea({ ...parkingArea, parkingGroups: updatedParkingGroups });
    }

    useEffect(() => {
        if (parkingArea && parkingArea.parkingGroups) {
            const sumOfParkingSpots = parkingArea.parkingGroups.reduce((accumulator, parkingGroup) => {
                return accumulator + parkingGroup.numberOfParkingSpots;
            }, 0);

            setTotalNumberOfParkingSpots(sumOfParkingSpots);
        }

        if (parkingArea && parkingArea.parkingGroups && parkingArea.parkingGroups.length > 0) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }

    }, [parkingArea]);

    const handleNextStep = () => {
        if (!organization.isAutomaticApproval) {
            navigate(`/${organization.organizationExtId.toLowerCase()}/create-parking-area/proofofownership`);
        } else {
            navigate(`/${organization.organizationExtId.toLowerCase()}/create-parking-area/details`);
        }
    }

    return (
        <>
            <button className="flex p-6 absolute top-12 left-12 md:visible invisible" onClick={(e) => { e.preventDefault(); navigate(`/${organization.organizationExtId.toLowerCase()}/create-parking-area/area`); }}><ArrowLeftIcon /></button>
            <div className="flex flex-col m-auto max-w-[768px] w-full ">
                <h1 className="mt-14 text-center text-2xl font-medium mb-12">{t('createParkingArea.parkingSpots')}</h1>
                <div className="flex flex-row justify-center items-center mb-8">
                    <ParkingSpotsIcon className="h-11 w-11" />
                    <p className="ml-2 text-center">{t('createParkingArea.totalNumberOfParkingSpots', { totalNumberOfParkingSpots: totalNumberOfParkingSpots })}</p>
                </div>
                <div className="flex flex-col justify-center items-center">
                    {parkingArea.parkingGroups && parkingArea.parkingGroups.map((pg, i) => {
                        return (<ParkingGroupItem key={i} parkingGroup={pg} removeItem={() => { handleRemoveParkingGroup(i); }} />)
                    })
                    }
                </div>
                <div className="flex justify-center">
                    <ButtonText onClick={() => { setShowAddParkingSpotModal(true); }}><span className="w-full">{t('createParkingArea.addParkingSpots')}</span></ButtonText>
                </div>
                <div className="flex relative justify-center mt-12">
                    <StepIndicator currentStep={2} totalNumberOfSteps={organization.hasAutomaticParkingAreaApproval ? 3 : 4} />
                    <div className="flex absolute right-0">
                        <Button disabled={!isFormValid} onClick={() => { if (isFormValid) { handleNextStep(); } else { showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error') } }}>
                            {isFormValid &&
                                <span className="flex opacity-100">
                                    <ArrowRightIcon />
                                </span>
                            }
                            {!isFormValid &&
                                <span className="flex opacity-50">
                                    <ArrowRightIcon />
                                </span>
                            }
                        </Button>
                    </div>
                </div>
            </div>

            <AddParkingSpotsModal display={showAddParkingSpotModal} setDisplay={setShowAddParkingSpotModal} parkingArea={parkingArea} setParkingArea={setParkingArea}></AddParkingSpotsModal>
        </>
    );
}

const ParkingGroupItem = ({ parkingGroup, removeItem }) => {

    const getParkingGroupNumberRange = (parkingSpotGroup) => {
        if (parkingSpotGroup.numberOfParkingSpots == 1) {
            return parkingSpotGroup.numberSeriesStart;
        } else {
            return `${parkingGroup.numberSeriesStart} - ${parkingGroup.numberSeriesStart + parkingGroup.numberOfParkingSpots - 1}`;
        }
    }

    return (
        <div className="flex flex-col p-3 max-w-sm w-full justify-start items-center rounded-lg shadow mb-6">
            <div className="flex flex-row justify-between w-full">
                <div className="flex flex-row items-center">
                    {parkingGroup.isIndoor &&
                        <GarageIcon className="h-6" />
                    }
                    {!parkingGroup.isIndoor &&
                        <SunIcon className="h-6" />
                    }
                    {parkingGroup.isChargingPost &&
                        <ElectricityIcon className="h-6" />
                    }
                    {parkingGroup.isFenced &&
                        <FenceIcon className="h-6" />
                    }
                    <span className="ml-2">{parkingGroup.numberOfParkingSpots} {t('createParkingArea.parkingSpots').toLocaleLowerCase()}</span>
                </div>
                <button onClick={(e) => { e.preventDefault(e); removeItem() }}>
                    <DeleteIcon className="mr-2 w-5 h-5" />
                </button>
            </div>

            {!parkingGroup.isNumbered &&
                <span className="flex justify-start w-full mt-3">{t('createParkingArea.notnumbered')}</span>
            }
            {parkingGroup.isNumbered &&
                <span className="flex justify-start w-full mt-3">{getParkingGroupNumberRange(parkingGroup)}</span>
            }
        </div>

    );
}

export default ParkingSpotsView;