import ButtonText from 'components/common/buttonText';
import { getToken } from 'context/auth';
import { createOrganizationSublessorInvite } from 'api/organizations';
import InputPhoneNumber from 'components/common/inputPhoneNumber';
import { useState} from 'react';
import { useDashboard } from 'context/dashboard';
import { useTranslation } from 'react-i18next';
import { useMessages } from 'context/messages';

export const InviteSublessor = ({ invites, setInvites, isSaving, setIsSaving, setDisplay }) => {
    const { t } = useTranslation();
    const { showToast } = useMessages();
    const { organization } = useDashboard();
    const [phoneNumber, setPhoneNumber] = useState();
    

    const handleInviteUser = async () => {

        setIsSaving(true);

        try {
            const token = await getToken();
            const result = await createOrganizationSublessorInvite(token, {
                phoneCountryNumberPrefix: phoneNumber.countryCodePrefix,
                organizationExtId: organization.organizationExtId,
                phoneNumber: phoneNumber.phoneNumber
              });

              if (result && !result.error) {
                invites.push(result);
                setInvites([...invites]);
                setDisplay(false);
                setPhoneNumber('');
                showToast(t('inviteSublessorModal.userInvitedTitle'), t('inviteSublessorModal.userInvitedDescription'), 'success');
                
              } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
              }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSaving(false);
    }

    return <>
        <InputPhoneNumber
            title={t('manageCommunityMembersPage.phoneNumber')}
            setPhoneNumber={(phoneNumber, countryCodePrefix) => { setPhoneNumber({ countryCodePrefix: countryCodePrefix, phoneNumber: phoneNumber }) }}
            defaultCountryCode={"SE"}
            resetTrigger={false}
        />
        <ButtonText 
            onClick={() => { handleInviteUser(); }} 
            className="flex mt-6 justify-center w-full" 
            isLoading={isSaving} 
            isSecondary={false}>
            {t('generic.invite')}
        </ButtonText>
    </>
};