import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Logo from "components/common/logo";
import useAuth from "context/auth";

export const AuthenticatedLayout = () => {

    const { isAuthenticated, isFinishedCheckingIfUserIsAuthenticated, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isFinishedCheckingIfUserIsAuthenticated && !isAuthenticated) {
            logout();
            navigate('/');
        }
    }, [isFinishedCheckingIfUserIsAuthenticated]);

    return (
        <div className="flex h-screen justify-center p-6">
            <Logo className="absolute top-12" />
            <Outlet />
        </div>
    )
};

export default AuthenticatedLayout;