import React from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';
import CommunityInviteLinkItem from './communityInviteLinkItem';

const CommunityInviteLinksTable = ({ communityInviteLinks, setCommunityInviteLinks }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">{t('communityInviteLinksTable.link')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {communityInviteLinks?.length > 0 && communityInviteLinks.map((communityInviteLink, i) => {
                            return (<CommunityInviteLinkItem
                                       key={i}
                                       setCommunityInviteLinks={setCommunityInviteLinks}
                                       communityInviteLinks={communityInviteLinks}
                                       communityInviteLink={communityInviteLink} />)
                        })}
                        {communityInviteLinks?.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={4}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        <span>{t('communityInviteLinksTable.noLinks')}</span>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default CommunityInviteLinksTable;