import React from "react";

import { ReactComponent as StreamIcon } from 'assets/icons/ico_stream.svg';
import Button from "../button";


const VideoInstructions = ({ title, description, children, videoUrl }) => {

    const handleOpenVideoUrl = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div className="flex z-0 min-h-24 items-center p-6 w-full rounded-[30px] shadow bg-white justify-between">
            <div className="flex gap-x-8">
                <StreamIcon className="md:flex hidden w-8" />
                <div className="flex flex-col w-full">
                    <span className="font-medium text-lg pb-1">{title}</span>
                    <span className="font-normal text-base">{description}</span>
                    {children}
                </div>
            </div>
            <Button onClick={() => { handleOpenVideoUrl(videoUrl); }}>
                <StreamIcon className="fill-white w-6" />
            </Button>
        </div>
    );
}

export default VideoInstructions;