import Logo from "components/common/logo";
import React from "react";
import Lottie from 'lottie-react';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import { useTranslation } from "react-i18next";

const PageLoad = ({title}) => {
    const { t } = useTranslation();
    return (
        <div className="flex h-screen items-center justify-center">
            <Logo className="absolute top-12" />
            <div className="flex flex-col items-center">
                <Lottie className="h-10 w-10" animationData={animSpinnerGreen} loop={true} />
                <div className="flex h-10 items-center mt-2">
                    <h1 className="text-2xl font-medium">{title ? title : t('defaultLayout.loading')}</h1>
                </div>
            </div>
        </div>
    );

}

export default PageLoad;