import { getParkingSpotsWithNumberOfPermitsForParkingArea } from 'api/permits';
import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import PermitParkingSpotsTable from 'components/pageSpecific/permitParkingSpots/permitParkingSpotsTable';
import PermitsModal from 'components/pageSpecific/permitParkingSpots/permitsModal';
import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const PermitParkingSpotsPage = () => {

    const { t } = useTranslation();
    const { parkingAreaExtId } = useParams();
    const [parkingSpots, setParkingSpots] = useState();
    const [loading, setLoading] = useState(true);
    const { showToast } = useMessages();
    const [selectedParkingSpot, setSelectedParkingSpot] = useState();
    const [displayPermitsModal, setDisplayPermitsModal] = useState(false);

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const result = await getParkingSpotsWithNumberOfPermitsForParkingArea(token, parkingAreaExtId);

                if (result && !result.error) {
                    setParkingSpots(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setLoading(false);
        }

        if (parkingAreaExtId) {
            load();
        }
    }, [parkingAreaExtId]);

    useEffect(() => {
        if (displayPermitsModal) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [displayPermitsModal]);

    return (
        <>
            <Widget>
                <div className="flex flex-col">
                    <h2 className="text-xl font-medium mb-3">{t('permitParkingSpotsPage.title')}</h2>
                    <span>{t('permitParkingSpotsPage.description')}</span>
                </div>
            </Widget>
            {!loading && parkingSpots &&
                <div className="mt-6">
                    <Widget>
                        <PermitParkingSpotsTable
                            parkingSpots={parkingSpots}
                            setSelectedParkingSpot={setSelectedParkingSpot}
                            setDisplayPermitsModal={setDisplayPermitsModal}
                        />
                    </Widget>
                </div>
            }
            {loading &&
                <div className="mt-6">
                    <WidgetLoadingSkeleton />
                </div>
            }
            {displayPermitsModal &&
                <PermitsModal
                    selectedParkingSpot={selectedParkingSpot}
                    display={displayPermitsModal}
                    setDisplay={setDisplayPermitsModal} />
            }
        </>
    );
};

export default PermitParkingSpotsPage;