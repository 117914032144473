import React from 'react';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';

const Loading = ({ title, size = 10 }) => {
    return (
        <div className="flex flex-col justify-center items-center">
            <Lottie className={`h-${size} w-${size}`} animationData={animSpinnerGreen} loop={true} />
            {title &&
                <div className="flex h-10 items-center mt-2">
                    <h1 className="text-2xl font-medium">{title}</h1>
                </div>
            }
        </div>
    );
};

export default Loading;