import React, { useEffect, useState } from 'react';
import { ReactComponent as AirParkLogo } from 'assets/icons/ico_airpark_logo.svg';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getPayout } from 'api/payouts';
import { getToken } from 'context/auth';
import PageLoad from 'components/common/pageLoad';
import { formatCurrency } from 'helpers/currency';
import { useDashboard } from 'context/dashboard';

const PayoutPrintPage = () => {

    const { t } = useTranslation();
    const { payoutExtId } = useParams();
    const { organization } = useDashboard();
    const [payout, setPayout] = useState(null);

    useEffect(() => {
        const load = async () => {
            const token = await getToken();
            const result = await getPayout(token, payoutExtId);
            console.log(result);
            const payoutLines = result.payoutLines;

            let aggregatedPayoutLines = {};
            for (let i = 0; i < payoutLines.length; i++) {
                let payoutLine = payoutLines[i];
                let transactionType = payoutLine.transactionType;

                if (!aggregatedPayoutLines.hasOwnProperty(transactionType)) {
                    aggregatedPayoutLines[transactionType] = {
                        paymentAmount: 0,
                        transactionFee: 0,
                        airParkFee: 0,
                        vatOnAirParkFee: 0,
                        airParkPercentage: 0,
                        ownerPercentage: 0,
                        ownerAmount: 0,
                        ownerVatPercentage: 0,
                        vatOnOwnerAmount: 0,
                        subletterPercentage: 0,
                        subletterAmount: 0,
                        payoutAmount: 0,
                        vatOnPayoutAmount: 0,
                        organizationVATPercentage: 0,
                        paymentExtIds: []
                    };
                }

                aggregatedPayoutLines[transactionType].paymentAmount += payoutLine.paymentAmount;
                aggregatedPayoutLines[transactionType].transactionFee += payoutLine.transactionFee;
                aggregatedPayoutLines[transactionType].airParkFee += payoutLine.airParkFee;
                aggregatedPayoutLines[transactionType].vatOnAirParkFee += payoutLine.vatOnAirParkFee;
                aggregatedPayoutLines[transactionType].airParkPercentage += payoutLine.airParkPercentage;
                aggregatedPayoutLines[transactionType].ownerPercentage += payoutLine.ownerPercentage;
                aggregatedPayoutLines[transactionType].ownerAmount += payoutLine.ownerAmount;
                aggregatedPayoutLines[transactionType].ownerVatPercentage += payoutLine.ownerVatPercentage;
                aggregatedPayoutLines[transactionType].vatOnOwnerAmount += payoutLine.vatOnOwnerAmount;
                aggregatedPayoutLines[transactionType].subletterPercentage += payoutLine.subletterPercentage;
                aggregatedPayoutLines[transactionType].subletterAmount += payoutLine.subletterAmount;
                aggregatedPayoutLines[transactionType].payoutAmount += payoutLine.payoutAmount;
                aggregatedPayoutLines[transactionType].vatOnPayoutAmount += payoutLine.vatOnPayoutAmount;
                aggregatedPayoutLines[transactionType].organizationVATPercentage += payoutLine.organizationVATPercentage;
            }

            setPayout({
                ...result,
                aggregatedPayoutLines: aggregatedPayoutLines,
                period: getPeriod(result),
                payoutDate: getPayoutDate(result)
            });
        }
        if (payoutExtId) {
            load();
        }
    }, [payoutExtId]);

    useEffect(() => {
        if (payout) {
            window.print();
        }
    }, [payout]);

    const getPeriod = (payout) => {
        if (payout.payoutStatus === 'Processed') {
            return payout.description;
        } else {
            return t('generic.pending');
        }
    }

    const getPayoutDate = (payout) => {
        if (payout.processedAtUtc) {
            return payout.processedAtUtc.substring(0, 10);
        } else {
            return t('generic.pending');
        }
    }

    const getTotalVat = (payout) => {
        if (payout.totalVat == 0) {
            return payout.totalAmount * 0.2;
        } else {
            return payout.totalVat;
        }
    }

    const getAmountExVat = (payout) => {
        if (payout.totalVat == 0) {
            return payout.totalAmount - getTotalVat(payout);
        } else {
            return payout.totalAmountExVat;
        }
    }

    if (!payout) {
        return <PageLoad />
    } else {
        return (
            <div className="flex justify-center items-center">
                <div className="flex flex-col max-w-3xl w-full p-6">
                    <div className="flex flex-row items-center justify-between">
                        <AirParkLogo />
                        <div className="flex flex-col">
                            <h2 className="text-2xl font-medium">{t('payoutPrintPage.title')}</h2>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div className="flex flex-col text-right">
                            <span>{t('payoutPrintPage.payoutReference')}: {payout.payoutExtId}</span>
                            <span>{t('payoutPrintPage.payoutDate')}: {payout.payoutDate}</span>
                            <span>{t('payoutPrintPage.period')}: {payout.period}</span>
                        </div>
                    </div>
                    <div className="flex justify-between mt-12">
                        <div className="flex flex-col">
                            <span className="font-medium">{t('payoutPrintPage.address')}</span>
                            <span>{organization.name}</span>
                            <span>{organization.address}</span>
                            <span>{organization.zipCode} {organization.city}</span>
                        </div>
                        <div className="flex flex-col text-right">
                            <span className="font-medium">{t('payoutPrintPage.reference')}</span>
                            <span>Anton Sjöberg</span>
                        </div>
                    </div>
                    <div className="flex flex-col mt-6 gap-y-1">
                        {payout.aggregatedPayoutLines['Bookings']?.payoutAmount > 0 &&
                            <div className="flex justify-between">
                                <span>{t('payoutPrintPage.bookings')}</span>
                                <span>{formatCurrency(payout.aggregatedPayoutLines['Bookings']?.payoutAmount ?? 0, 2)} {payout.currencySymbol}</span>
                            </div>
                        }
                        {payout.aggregatedPayoutLines['Bookings']?.vatOnPayoutAmount > 0 &&
                            <div className="flex justify-between">
                                <span>{t('payoutPrintPage.bookingsVat')}</span>
                                <span>{formatCurrency(payout.aggregatedPayoutLines['Bookings']?.vatOnPayoutAmount ?? 0, 2)} {payout.currencySymbol}</span>
                            </div>
                        }
                        {payout.aggregatedPayoutLines['Sublet booking']?.payoutAmount > 0 &&
                            <div className="flex justify-between">
                                <span>{t('payoutPrintPage.subletting')}</span>
                                <span>{formatCurrency(payout.aggregatedPayoutLines['Sublet booking']?.payoutAmount ?? 0, 2)} {payout.currencySymbol}</span>
                            </div>
                        }
                        {payout.aggregatedPayoutLines['Sublet booking']?.vatOnPayoutAmount > 0 &&
                            <div className="flex justify-between">
                                <span>{t('payoutPrintPage.sublettingVat')}</span>
                                <span>{formatCurrency(payout.aggregatedPayoutLines['Sublet booking']?.vatOnPayoutAmount ?? 0, 2)} {payout.currencySymbol}</span>
                            </div>
                        }
                        {payout.aggregatedPayoutLines['Permits']?.payoutAmount > 0 &&
                            <div className="flex justify-between">
                                <span>{t('payoutPrintPage.permits')}</span>
                                <span>{formatCurrency(payout.aggregatedPayoutLines['Permits']?.payoutAmount ?? 0, 2)} {payout.currencySymbol}</span>
                            </div>
                        }
                        {payout.aggregatedPayoutLines['Permits']?.vatOnPayoutAmount > 0 &&
                            <div className="flex justify-between">
                                <span>{t('payoutPrintPage.permitsVat')}</span>
                                <span>{formatCurrency(payout.aggregatedPayoutLines['Permits']?.vatOnPayoutAmount ?? 0, 2)} {payout.currencySymbol}</span>
                            </div>
                        }
                        {payout.aggregatedPayoutLines['Control fee']?.payoutAmount > 0 &&
                            <div className="flex justify-between">
                                <span>{t('payoutPrintPage.controlFees')}</span>
                                <span>{formatCurrency(payout.aggregatedPayoutLines['Control fee']?.payoutAmount ?? 0, 2)} {payout.currencySymbol}</span>
                            </div>
                        }
                        {payout.aggregatedPayoutLines['Control fee']?.vatOnPayoutAmount > 0 &&
                            <div className="flex justify-between">
                                <span>{t('payoutPrintPage.controlFeesVat')}</span>
                                <span>{formatCurrency(payout.aggregatedPayoutLines['Control fee']?.vatOnPayoutAmount ?? 0, 2)} {payout.currencySymbol}</span>
                            </div>
                        }
                        {payout.aggregatedPayoutLines['Charging']?.payoutAmount > 0 &&
                            <div className="flex justify-between">
                                <span>{t('payoutPrintPage.charging')}</span>
                                <span>{formatCurrency(payout.aggregatedPayoutLines['Charging']?.payoutAmount ?? 0, 2)} {payout.currencySymbol}</span>
                            </div>
                        }
                        {payout.aggregatedPayoutLines['Charging']?.vatOnPayoutAmount > 0 &&
                            <div className="flex justify-between">
                                <span>{t('payoutPrintPage.chargingVat')}</span>
                                <span>{formatCurrency(payout.aggregatedPayoutLines['Charging']?.vatOnPayoutAmount ?? 0, 2)} {payout.currencySymbol}</span>
                            </div>
                        }
                    </div>

                    <div className="flex flex-col mt-6 gap-y-1">
                        <div className="flex justify-between">
                            <span className="font-medium">{t('payoutPrintPage.totalExVat')}</span>
                            <span className="font-medium">{formatCurrency(payout.totalAmount - getTotalVat(payout), 2)} {payout.currencySymbol}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="font-medium">{t('payoutPrintPage.totalVat')} ({organization.vatPercentage}%)</span>
                            <span className="font-medium">{formatCurrency(getTotalVat(payout), 2)} {payout.currencySymbol}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="font-medium">{t('payoutPrintPage.total')}</span>
                            <span className="font-medium">{formatCurrency(payout.totalAmount, 2)} {payout.currencySymbol}</span>
                        </div>
                    </div>

                    <div className="flex justify-between mt-12">
                        <div className="flex flex-col">
                            <span>AirPark AB</span>
                            <span>Redaregatan 48</span>
                            <span>252 36 Helsingborg</span>
                        </div>
                        <div className="flex flex-col text-right">
                            <span>{t('payoutPrintPage.organizationNumber')}: 559350-3138</span>
                            <span>{t('payoutPrintPage.vatNumber')}:</span>
                            <span>SE559350313801</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default PayoutPrintPage;