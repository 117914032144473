import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/ico_close.svg';
import { ReactComponent as SunIcon } from 'assets/icons/ico_sun.svg';
import { ReactComponent as GarageIcon } from 'assets/icons/ico_garage_black.svg';
import { ReactComponent as ElectricityIconBlack } from 'assets/icons/ico_electricity_black.svg';
import { ReactComponent as ElectricityIconWhite } from 'assets/icons/ico_electricity_white.svg';
import { ReactComponent as FenceIconBlack } from 'assets/icons/ico_fence_black.svg';
import { ReactComponent as FenceIconWhite } from 'assets/icons/ico_fence_white.svg';
import ButtonText from 'components/common/buttonText';
import RadioButton from 'components/common/radioButton/radioButton';
import ButtonToggle from 'components/common/buttonToggle';
import InputNumber from 'components/common/inputNumber';
import ToggleText from 'components/common/toggleText';
import { addParkingSpotsToParkingArea } from 'api/locations';
import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ButtonSlider from "../../common/buttonSlider";

const AddParkingSpotsModal = ({ display, setDisplay, setParkingSpots, setSelectedParkingSpots }) => {

    const parkingGroupInitialState = { isIndoor: false, numberOfParkingSpots: 1, isChargingPost: false, isFenced: false, isNumbered: false, numberSeriesStart: 1 };
    const [parkingGroup, setParkingGroup] = useState(parkingGroupInitialState);
    const [isSaving, setIsSaving] = useState(false);
    const [showFloor, setShowFloor] = useState(false);
    const { parkingAreaExtId } = useParams();
    const { showToast } = useMessages();
    const { t } = useTranslation();

    useEffect(() => {
        if (!display) {
            setParkingGroup(parkingGroupInitialState);
        }
    }, [display]);

    useEffect(() => {
        if (parkingGroup.isNumbered) {
            if (Number.isInteger(parkingGroup.numberSeriesStart) && parkingGroup.numberOfParkingSpots) {
                setParkingGroup({ ...parkingGroup, numberSeriesEnd: parkingGroup.numberSeriesStart + parkingGroup.numberOfParkingSpots - 1 })
            } else {
                setParkingGroup({ ...parkingGroup, numberSeriesEnd: '' })
            }
        } else {
            setParkingGroup({ ...parkingGroup, numberSeriesEnd: '', numberSeriesStart: '' })
        }

    }, [parkingGroup.isNumbered, parkingGroup.numberSeriesStart, parkingGroup.numberOfParkingSpots]);

    const handleNumberOfParkingSpotsChange = (value) => {
        if (value) {
            var regex = /[^0-9]|\./;
            setParkingGroup({ ...parkingGroup, numberOfParkingSpots: parseInt(value.replace(regex, '')) });
        } else {
            setParkingGroup({ ...parkingGroup, numberOfParkingSpots: '' });
        }
    };

    const handleNumberSeriesChange = (value) => {
        if (value) {
            var regex = /[^0-9]|\./;
            setParkingGroup({ ...parkingGroup, numberSeriesStart: parseInt(value.replace(regex, '')) });
        } else {
            setParkingGroup({ ...parkingGroup, numberSeriesStart: 0 });
        }
    };

    const handleSaveParkingGroup = () => {
        if (isSaving) {
            return;
        }

        if (parkingGroup.numberOfParkingSpots == '') {
            parkingGroup.numberOfParkingSpots = 1;
        }

        if (parkingGroup.isNumbered && !Number.isInteger(parkingGroup.numberSeriesStart)) {
            parkingGroup.isNumbered = false;
        }

        if (!Number.isInteger(parkingGroup.numberSeriesStart)) {
            parkingGroup.numberSeriesStart = 0;
        }

        const saveParkingGroup = async () => {
            setIsSaving(true);

            try {
                const token = await getToken();
                const result = await addParkingSpotsToParkingArea(token, {
                    parkingAreaExtId: parkingAreaExtId,
                    parkingGroups: [
                        parkingGroup
                    ]
                });

                if (result.error) {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setParkingSpots(result);
                    setSelectedParkingSpots(result);
                    setDisplay(false);
                    showToast(t('addParkingSpotsModal.addedParkingSpotsTitle'), t('addParkingSpotsModal.addedParkingSpotsDescription'), 'success');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsSaving(false);
        }

        saveParkingGroup();
    }

    return (
        <>
            {/* Overlay */}
            <div className="fixed inset-0 z-50 bg-black bg-opacity-60">
            </div>
            {/* Modal */}
            <div onClick={(e) => { e.preventDefault(); setDisplay(false); }} className="flex fixed inset-0 z-50 justify-center items-center overflow-y-auto overflow-x-hidden">
                <div onClick={(e) => { e.stopPropagation(); }} className="flex max-w-sm w-full p-3 justify-center items-center">
                    <div className="flex flex-col w-full bg-white max-w-sm rounded-lg p-6">
                        <div className="flex justify-end">
                            <button className="h-4" onClick={() => {
                                setDisplay(false);
                            }}>
                                <CloseIcon className="h-5 w-5"/>
                            </button>
                        </div>
                        <div className="flex items-center justify-center">
                            <span className="font-medium">{t('addParkingSpotsModal.title')}</span>
                        </div>
                        <div className="flex flex-row mt-6 mb-3">
                            <div className="flex items-center flex-row w-1/2 mr-2">
                                <RadioButton isChecked={!parkingGroup.isIndoor} onClick={(e) => {
                                    setParkingGroup({ ...parkingGroup, isIndoor: false })
                                }}/>
                                <SunIcon className="ml-2"/>
                                <span className="ml-2">{t('createParkingArea.outdoors')}</span>
                            </div>
                            <div className="flex items-center flex-row w-1/2">
                                <RadioButton isChecked={parkingGroup.isIndoor} onClick={(e) => {
                                    setParkingGroup({ ...parkingGroup, isIndoor: true })
                                }}/>
                                <GarageIcon className="ml-2"/>
                                <span className="ml-2">{t('createParkingArea.indoors')}</span>
                            </div>
                        </div>
                        <span className="flex mt-4 font-medium">{t('createParkingArea.features')}</span>
                        <div className="flex mt-2">
                            <ButtonToggle defaultIcon={<ElectricityIconBlack/>} toggledIcon={<ElectricityIconWhite/>}
                                          isToggled={parkingGroup.isChargingPost}
                                          title={t('createParkingArea.chargingPost')} onClick={() => {
                                setParkingGroup({ ...parkingGroup, isChargingPost: !parkingGroup.isChargingPost })
                            }}/>
                            <ButtonToggle defaultIcon={<FenceIconBlack/>} toggledIcon={<FenceIconWhite/>}
                                          isToggled={parkingGroup.isFenced} title={t('createParkingArea.fenced')}
                                          onClick={() => {
                                              setParkingGroup({ ...parkingGroup, isFenced: !parkingGroup.isFenced })
                                          }} className="ml-2"/>
                        </div>
                        <div className="flex items-center mt-4">
                            <ButtonSlider title={t('createParkingArea.floor')}
                                          isToggled={showFloor}
                                          onClick={() => {
                                              const enabled = !showFloor;
                                              if (!enabled) {
                                                  setParkingGroup({ ...parkingGroup, floor: null })
                                              }
                                              setShowFloor(enabled);
                                          }}/>
                            <div className='ml-3'>
                                <InputNumber value={parkingGroup.floor}
                                             disabled={!showFloor}
                                             className={'mb-[2px]'}
                                             onChange={(value) => {
                                                 setParkingGroup({ ...parkingGroup, floor: value })
                                             }}/>
                            </div>
                        </div>
                        <div className="flex flex-col mt-4">
                            <InputNumber title={t('createParkingArea.amount')} value={parkingGroup.numberOfParkingSpots}
                                         onChange={(value) => {
                                             handleNumberOfParkingSpotsChange(value);
                                         }}/>
                        </div>

                        <div className="flex flex-col mt-4">
                            <ToggleText title={t('createParkingArea.numberedTitle')}
                                        defaultText={t('createParkingArea.notnumbered')}
                                        toggledText={t('createParkingArea.numbered')}
                                        isToggled={parkingGroup.isNumbered} onClick={() => {
                                setParkingGroup({ ...parkingGroup, isNumbered: !parkingGroup.isNumbered })
                            }}/>
                        </div>
                        <div
                            className={`${parkingGroup.isNumbered ? 'flex flex-row h-20' : 'invisible h-0'} transition-[height] duration-[300ms] ease-in-out mt-3`}>
                            <div
                                className={`${parkingGroup.isNumbered ? 'opacity-100 delay-100' : 'opacity-0 delay-0'} flex flex-row w-full transition-all ease-in-out`}>
                                <div className="flex flex-col w-1/2 pr-1">
                                    <span className="font-medium">{t('createParkingArea.from')}</span>
                                    <input type="number" onChange={(e) => {
                                        handleNumberSeriesChange(e.target.value);
                                    }} className="max-w-full rounded-xl p-2 pl-4 bg-airpark-gray-300"></input>
                                </div>
                                <div className="flex flex-col w-1/2 pl-1">
                                    <span className="font-medium">{t('createParkingArea.to')}</span>
                                    <input type="number" value={parkingGroup.numberSeriesEnd} disabled={true}
                                           className="max-w-full rounded-xl p-2 pl-4 bg-airpark-gray-300 opacity-50"></input>
                                </div>
                            </div>
                        </div>
                        <ButtonText onClick={() => {
                            handleSaveParkingGroup();
                        }} className="flex mt-6 justify-center w-full" isLoading={isSaving} isSecondary={false}>
                            {t('createParkingArea.add')}
                        </ButtonText>
                    </div>
                </div>
            </div>
        </>)
}

export default AddParkingSpotsModal;