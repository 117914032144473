import React from "react";
import Tooltip from "../tooltip/tooltip";

const InputEmail = ({ title, value, onChange, placeHolder, disabled, className, tooltip, required }) => {

    let baseClassName = `w-full h-11 bg-airpark-gray-300 rounded-xl p-2 pl-4 text-airpark-input-field focus:outline-none ${!value && required ? 'border-2 border-airpark-strawberry-500' : ''}`;

    if(disabled) {
        baseClassName = `${baseClassName} opacity-50`;
    }

    if (!className) {
        className = baseClassName;
    } else {
        className = `${baseClassName} ${className}`;
    }

    return (
        <div>
            <div className="flex relative items-center">
                <span className="text-base font-medium pb-1">{title}</span>
                {tooltip &&
                    <Tooltip tooltip={tooltip} />
                }
            </div>
            <input value={value} onChange={(e) => { onChange(e.target.value); }} type="email" disabled={disabled} autoComplete="email" placeholder={placeHolder} className={className}></input>
        </div>
    );
}

export default InputEmail;