import React from "react";
import { useParams } from "react-router-dom";

import MobileMenu from "../mobileMenu";
import DesktopMenu from "../desktopMenu";

const TopBar = () => {
    const { parkingAreaExtId } = useParams();
    return (
        <>
            {/* Desktop */}
            <DesktopMenu />

            {/* Mobile */}
            <MobileMenu isOrganizationLevel={!parkingAreaExtId} />
        </>
    );
}

export default TopBar;