import { getRevenueSharingForOrganization, updateRevenueSharingForOrganization } from 'api/organizations';
import Alert from 'components/common/alert';
import ButtonText from 'components/common/buttonText';
import InputNumber from 'components/common/inputNumber';
import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import { getToken } from 'context/auth';
import { useDashboard } from 'context/dashboard';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const RevenueSharingSettingsPage = () => {
    const { t } = useTranslation();
    const { organization } = useDashboard();
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useMessages();
    const { organizationExtId } = useParams();
    const [revenueSharing, setRevenueSharing] = useState();
    const [isSavingRevenueShare, setIsSavingRevenueShare] = useState(false);
    const [isSublettingRevenueShareValid, setIsSublettingRevenueShareValid] = useState(true);


    useEffect(() => {

        setIsLoading(true);

        const load = async () => {
            try {
                const token = await getToken();
                const result = await getRevenueSharingForOrganization(token, organizationExtId);

                if (result && !result.error) {
                    setRevenueSharing(result);
                } else {
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, []);

    const handleChangeRevenueForSublettingInPercentage = (value) => {
        setRevenueSharing({ ...revenueSharing, organizationRevenueForSublettingInPercentage: parseInt(value) })
    }

    const handleSaveRevenueSharing = async () => {
        try {
            setIsSavingRevenueShare(true);
            const token = await getToken();
            const result = await updateRevenueSharingForOrganization(token, {
                organizationExtId: organizationExtId,
                organizationRevenueForSublettingInPercentage: revenueSharing.organizationRevenueForSublettingInPercentage,
                organizationRevenueForControlFeesInPercentage: revenueSharing.organizationRevenueForControlFeesInPercentage
            });

            if (result && !result.error) {
                showToast(t('generic.updated'), t('generic.updatedDescription'), 'success');
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        } finally {
            setIsSavingRevenueShare(false);
        }
    }

    useEffect(() => {
        if (revenueSharing) {
            if ((revenueSharing.organizationRevenueForSublettingInPercentage) > 100) {
                setIsSublettingRevenueShareValid(false);
            } else {
                setIsSublettingRevenueShareValid(true);
            }
        }
    }, [revenueSharing]);

    if (isLoading || !revenueSharing) {
        return <WidgetLoadingSkeleton />
    }

    return (
        <Widget>
            <h2 className="text-2xl font-medium pb-1">{t('revenueSharingSettingsPage.revenueSharingTitle')}</h2>
            <span className="mb-6">{t('revenueSharingSettingsPage.revenueSharingDescription')}</span>

            <h3 className="text-lg font-medium pb-1">{t('revenueSharingSettingsPage.revenueSharingSublettingTitle')}</h3>
            <div className="flex md:flex-row flex-col gap-3 mt-3">
                <div className="md:w-1/2">
                    <InputNumber value={revenueSharing.organizationRevenueForSublettingInPercentage} onChange={(value) => { handleChangeRevenueForSublettingInPercentage(value); }} title={t('revenueSharingSettingsPage.forYou')} unit='%'></InputNumber>
                </div>
                <div className="md:w-1/2">
                    <InputNumber value={100 - revenueSharing.organizationRevenueForSublettingInPercentage} title={t('revenueSharingSettingsPage.subletter')} disabled={true} unit='%'></InputNumber>
                </div>
            </div>
            <div className="flex justify-center mt-6">
                <ButtonText className="max-w-xs w-full" onClick={() => { handleSaveRevenueSharing(); }} isSecondary={false} isLoading={isSavingRevenueShare} disabled={!isSublettingRevenueShareValid }>
                    {t('generic.save')}
                </ButtonText>
            </div>
            {(!isSublettingRevenueShareValid) &&
                <div className="mt-6">
                    <Alert template='error' title={t('revenueSharingSettingsPage.shareIsNotValidTitle')} description={t('revenueSharingSettingsPage.shareIsNotValidDescription')} />
                </div>
            }
        </Widget>
    );
};

export default RevenueSharingSettingsPage;