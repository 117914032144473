import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getToken } from "context/auth";

import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import { ReactComponent as NextIcon } from 'assets/icons/ico_next_green.svg';

import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';

import { useMessages } from "context/messages";
import { deleteParkingArea } from "api/locations";
import TableItemContextMenu from "components/dashboard/tableItemContextMenu/itemContextMenu";
import { useNavigate, useParams } from "react-router-dom";

const ParkingAreaItem = ({ parkingArea, parkingAreas, setParkingAreas, setShowEditParkingAreaModal, setParkingAreaToEdit }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState();
    const { showToast, showModal, hideModal } = useMessages();
    const [isDeleting, setIsDeleting] = useState();
    const navigate = useNavigate();
    const { organizationExtId } = useParams();
    const parkingAreaUrl = `/${organizationExtId}/parking-areas/${parkingArea.parkingAreaExtId.toLowerCase()}`;

    const handleRemoveParkingArea = () => {
        setShowMenu(false);

        showModal(
            t('parkingArea.removeParkingAreaTitle'),
            t('parkingArea.removeParkingAreaDescription'),
            t('generic.yes'),
            async () => {
                setIsDeleting(true);
                hideModal();

                try {
                    const token = await getToken();
                    const deleteParkingAreaResult = await deleteParkingArea(token, [parkingArea.parkingAreaExtId]);

                    if (deleteParkingAreaResult.error) {
                        console.error(deleteParkingAreaResult);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    } else {
                        /* Successful, remove item from list */
                        const index = parkingAreas.findIndex(({ parkingAreaExtId }) => parkingAreaExtId === parkingArea.parkingAreaExtId);
                        parkingAreas.splice(index, 1);
                        setParkingAreas([...parkingAreas]);

                        showToast(t('parkingArea.removedParkingAreaTitle'), t('parkingArea.removedParkingAreaDescription'), 'success');
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                setIsDeleting(false);
            },
            t('generic.cancel'),
            () => { hideModal() },
            'warning');
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6"><a href={parkingAreaUrl}>{parkingArea.name}</a></td>
            <td className="pl-6">{parkingArea.numberOfParkings}</td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <TableItemContextMenu onCloseClick={() => { setShowMenu(false); }}
                        onDeleteClick={() => { handleRemoveParkingArea(); }} />
                }
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); navigate(parkingAreaUrl) }}>
                    <NextIcon />
                </button>
            </td>
        </tr>
    );
}

export default ParkingAreaItem;