import React, { useEffect, useState } from "react";
import { useDashboard } from "context/dashboard";
import { useParkingArea } from "context/parkingArea";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const Breadcrumbs = () => {

    const { organizationExtId } = useParams();
    const { organization } = useDashboard();
    const { parkingArea } = useParkingArea();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const routeLookup = [];

    useEffect(() => {
        if (organization) {
            routeLookup.push({
                id: organizationExtId,
                name: organization.name,
                path: [organizationExtId]
            })
        }

        if (parkingArea) {
            routeLookup.push({
                id: parkingArea.parkingAreaExtId.toLowerCase(),
                name: parkingArea.name,
                path: [parkingArea.parkingAreaExtId.toLowerCase()]
            })
        }

        const pathSegments = location.pathname.split('/');

        const crumbs = [];

        pathSegments.map((path, i) => {
            if (path && i > 0) {
                var result = routeLookup.find(item => item.id == pathSegments[i]);
                let currentPath = pathSegments.slice().splice(1, i);
                let pathName = pathSegments[i];

                if (result) {
                    pathName = result.name;
                } else {
                    pathName = t([`navigation.${pathName}`, pathName])
                }
                crumbs.push(<BreadcrumbItem key={i} navigate={navigate} pathName={pathName} path={currentPath} isActive={i + 1 == pathSegments.length} />);
            }
        });

        setBreadcrumbs(crumbs);
    }, [location, parkingArea]);

    const takeLastTwoItems = (array) => {
        return array.slice(Math.max(array.length - 2, 0))
    }

    return (
        <>
            <div className="lg:hidden flex flex-row h-11 pl-6 justify-start items-center w-full ">
                {breadcrumbs}
            </div>
        </>
    )
}

const BreadcrumbItem = ({ pathName, path, isActive }) => {
    if (isActive) {
        return (
            <span key={path} className="font-base text-base mr-1">{pathName}</span>
        )
    } else {
        return (
            <div key={path} className="flex flex-row">
                <Link to={`/${path.join('/')}`} className="text-airpark-green mr-1 whitespace-nowrap sm:max-w-full max-w-[96px] w-full truncate">{pathName}</Link>
                <span className="ml-1 mr-1">/</span>
            </div>
        );
    }
}

export default Breadcrumbs;