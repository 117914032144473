const BASE_URL = `${process.env.REACT_APP_API_PASSAGE_BASE_URL}/AirPass`;

export const getAirPasses = async (token, parkingAreaExtId) => {
    const response = await fetch(BASE_URL + `/get-airpasses-for-parkingarea/${parkingAreaExtId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    });

    if (response.status === 200) {
        const responseBody = await response.text();
    
        if (!responseBody) {
          return {};
        } else {
          return JSON.parse(responseBody);
        }
      }

    throw response;
}

export const getAirPass = async (token, airPassExtId) => {
  const response = await fetch(BASE_URL + `/get-airpass/${airPassExtId}`, {
      method: 'GET',
      headers: {
          'content-type': 'application/json',
          'authorization': `Bearer ${token}`
      }
  });

  if (response.status === 200) {
      const responseBody = await response.text();
  
      if (!responseBody) {
        return {};
      } else {
        return JSON.parse(responseBody);
      }
    }

  throw response;
}

export const createAirPass = async (token, airPass) => {
    const response = await fetch(BASE_URL + '/create-airpass', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(airPass)
    });

    if (response.status === 200) {
        const responseBody = await response.text();
    
        if (!responseBody) {
          return {};
        } else {
          return JSON.parse(responseBody);
        }
      }

    throw response;
}

export const deleteAirPass = async (token, airPassExtId) => {
    const response = await fetch(BASE_URL + `/delete-airpass/${airPassExtId}`, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    });

    if (response.status === 200) {
        const responseBody = await response.text();
    
        if (!responseBody) {
          return {};
        } else {
          return JSON.parse(responseBody);
        }
      }

    throw response;
}

export const updateAirPass = async (token, airPass) => {
    const response = await fetch(BASE_URL + `/update-airpass`, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(airPass)
    });

    if (response.status === 200) {
        const responseBody = await response.text();
    
        if (!responseBody) {
          return {};
        } else {
          return JSON.parse(responseBody);
        }
      }

    throw response;
}