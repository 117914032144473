import React, { useEffect, useRef, useState } from 'react'
import { DrawingManager, GoogleMap, LoadScriptNext, StandaloneSearchBox } from '@react-google-maps/api';
import { ReactComponent as PositionIcon } from 'assets/icons/ico_position.svg';
import { ReactComponent as LayersIcon } from 'assets/icons/ico_layers.svg';
import googleMapStyle from 'styles/googleMapStyle';
import Lottie from 'lottie-react';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import { t } from 'i18next';
import debounce from "lodash/debounce";

const ParkingAreaMap = ({ parkingArea, setParkingArea, isRoadmapDefault, isSearchDisabled }) => {
    const [google, setGoogle] = useState(window.google);

    const containerStyle = {
        width: '100%',
        maxWidth: '100%',
        height: '400px'
    };

    useEffect(() => {
        if (window.google) {
            setGoogle(window.google);
        }
    }, [window.google])

    const [searchBox, setSearchBox] = useState();
    const [map, setMap] = useState();
    const [polygon, setPolygon] = useState();
    const [libraries] = useState(['drawing', 'places']);
    const [loadingUserPosition, setLoadingUserPosition] = useState();
    const [center, setCenter] = useState({ lat: 56, lng: 12 });
    const [address, setAddress] = useState();
    const [polygonCoordinates, setPolygonCoordinates] = useState();
    const drawingManagerRef = useRef();
    const polygonRef = useRef();
    const listenersRef = useRef([]);

    const onLoadSearchBox = (ref) => {
        setSearchBox(ref);
    }

    /* Reconstruct previous polygon when navigating back */
    useEffect(() => {
        if (!polygon && parkingArea.polygonCoordinates && parkingArea.polygonCoordinates.length > 0 && google && map) {
            let polygonCoordinates = [];

            parkingArea.polygonCoordinates.forEach((c) => {
                polygonCoordinates.push({ lat: c.latitude, lng: c.longitude })
            });

            const previousPolygon = new google.maps.Polygon({
                paths: polygonCoordinates,
                fillColor: '#33D085',
                fillOpacity: '0.4',
                strokeWeight: 3,
                editable: setParkingArea ? true : false,
                zIndex: 1,
                strokeColor: '#33D085',
                draggable: setParkingArea ? true : false
            });

            /* Add the polygon to the map */
            previousPolygon.setMap(map);
            setPolygon(previousPolygon);

            /* Set the polygon reference to the previous polygon */
            polygonRef.current = previousPolygon;

            if (setParkingArea) {

                /* The drawing mode must be null for the polygon to be editable */
                drawingManagerRef.current.setDrawingMode(null);

                /* Adding listeners that detect if the polygon is updated */
                listenersRef.current.push(window.google.maps.event.addListener(previousPolygon.getPath(), 'set_at', debounce(handlePolygonEdit, 1000, { 'leading': true, 'trailing': false })));
                listenersRef.current.push(window.google.maps.event.addListener(previousPolygon.getPath(), 'insert_at', debounce(handlePolygonEdit, 1000, { 'leading': true, 'trailing': false })));
                listenersRef.current.push(window.google.maps.event.addListener(previousPolygon.getPath(), 'remove_at', debounce(handlePolygonEdit, 1000, { 'leading': true, 'trailing': false })));
            }

            var bounds = new window.google.maps.LatLngBounds();

            for (let i = 0; i < polygonCoordinates.length; i++) {
                bounds.extend(polygonCoordinates[i]);
            }

            map.fitBounds(bounds);
        }
    }, [polygon, google, map])

    const onLoadGoogleMap = (ref) => {
        setMap(ref);

        if (!isRoadmapDefault) {
            ref.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        }

        ref.setTilt(0);
    }

    /* Update parking area state when we have both address and polygon coordinates */
    useEffect(() => {
        if (polygonCoordinates && address) {
            setParkingArea({
                ...parkingArea,
                polygonCoordinates: polygonCoordinates,
                ...address
            });
        }
    }, [address, polygonCoordinates]);

    const getReverseGeocodingData = async (lat, lng) => {
        var latlng = new window.google.maps.LatLng(lat, lng);
        var geocoder = new window.google.maps.Geocoder();
        var addressComponents;

        await geocoder.geocode({ 'latLng': latlng }, (results, status) => {
            if (status !== window.google.maps.GeocoderStatus.OK) {
                console.error(status);
            } else if (status == window.google.maps.GeocoderStatus.OK) {
                addressComponents = results[0].address_components;
            }
        });

        return addressComponents;
    }

    const onPolygonComplete = async (createdPolygon) => {
        if (polygon) {
            /* Remove previous (current) polygon, we only allow one */
            handleRemovePolygon();
        }

        setPolygon(createdPolygon);
        const coordinates = getPolygonCoordinates(createdPolygon);
        const polygonAddress = await getAddress(createdPolygon);

        if (polygonAddress) {
            setAddress(polygonAddress);
        }

        setPolygonCoordinates(coordinates);

        /* The drawing mode must be null for the polygon to be editable */
        drawingManagerRef.current.setDrawingMode(null);

        /* We need a reference to the polygon if it's updated */
        polygonRef.current = createdPolygon;

        /* Adding listeners that detect if the polygon is updated */
        listenersRef.current.push(window.google.maps.event.addListener(createdPolygon.getPath(), 'set_at', debounce(handlePolygonEdit, 1000, { 'leading': true, 'trailing': false })));
        listenersRef.current.push(window.google.maps.event.addListener(createdPolygon.getPath(), 'insert_at', debounce(handlePolygonEdit, 1000, { 'leading': true, 'trailing': false })));
        listenersRef.current.push(window.google.maps.event.addListener(createdPolygon.getPath(), 'insert_at', debounce(handlePolygonEdit, 1000, { 'leading': true, 'trailing': false })));
    }

    const handlePolygonEdit = async () => {
        const coordinates = getPolygonCoordinates(polygonRef.current);
        const polygonAddress = await getAddress(polygonRef.current);

        if (polygonAddress) {
            setAddress(polygonAddress);
        }

        setPolygonCoordinates(coordinates);
    }

    const getPolygonCoordinates = (polygon) => {
        let coordinates = [];

        polygon.getPath()
            .getArray()
            .forEach((c) => {
                coordinates.push({ latitude: c.lat(), longitude: c.lng() });
            });

        return coordinates;
    }

    const getAddress = async (polygon) => {
        const coordinate = polygon.getPath().getArray()[0];
        const addressComponents = await getReverseGeocodingData(coordinate.lat(), coordinate.lng());

        if (!addressComponents) {
            return;
        }

        let streetNumber = '';
        let streetName = '';
        let city = '';
        let country = '';
        let zipCode = '';

        addressComponents.forEach((addressComponent) => {
            switch (addressComponent.types[0]) {
                case 'street_number':
                    streetNumber = addressComponent.long_name;
                    break;
                case 'postal_town':
                    city = addressComponent.long_name;
                    break;
                case 'route':
                    streetName = addressComponent.long_name;
                    break;
                case 'postal_code':
                    zipCode = addressComponent.long_name;
                    break;
                case 'country':
                    country = addressComponent.short_name;
                    break;
            }
        });

        return ({
            address: `${streetName} ${streetNumber}`,
            city: city,
            country: country,
            zipCode: zipCode
        });
    }

    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();

        if (places.length == 0) {
            return;
        }

        places.forEach((place) => {
            const bounds = new google.maps.LatLngBounds();

            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
            map.fitBounds(bounds);

        });

        map.setZoom(19);
    }

    const panTo = ({ lat, lng }) => {
        setCenter({ lat: lat, lng: lng });
        map.panTo({ lat, lng });
        map.setZoom(19);
    }

    const panToUser = () => {
        setLoadingUserPosition(true);
        navigator.geolocation.getCurrentPosition((position) => { panTo({ lat: position.coords.latitude, lng: position.coords.longitude }); setLoadingUserPosition(false); }, () => { console.warn('Could not get user location'); setLoadingUserPosition(false); })
    }

    const handleRemovePolygon = () => {
        if (polygon) {

            /* Remove the polygon from the map */
            polygon.setMap(null);

            /* Clean up listeners */
            listenersRef.current.forEach(lis => lis.remove());
            polygonRef.current = null;
            setPolygon(null);

            /* Reset the parking area coordinates state object */
            setParkingArea({
                ...parkingArea,
                polygonCoordinates: [],
                address: '',
                zipCode: '',
                city: '',
                country: ''
            })
        }

        /* Reset the drawing mode to polygon so a polygon can be created */
        drawingManagerRef.current.setDrawingMode('polygon');
    }

    const handlerLoadDrawingManager = (drawingManagerInstance) => {
        drawingManagerRef.current = drawingManagerInstance;
    }

    const LocateUserButton = () => {
        return (
            <button onClick={(e) => { e.preventDefault(); panToUser(); }} className="flex absolute w-11 h-11 rounded-full shadow top-4 right-2 p-3 bg-white hover:scale-105 transition-all duration-200 ease-in-out">
                {!loadingUserPosition &&
                    <PositionIcon />
                }
                {loadingUserPosition &&
                    <Lottie className="h-5 w-5" animationData={animSpinnerGreen} loop={true} />
                }
            </button>
        )
    }

    const RemovePolygonButton = () => {
        return (
            <button disabled={!polygon} onClick={(e) => { e.preventDefault(); handleRemovePolygon(); }} className="flex absolute rounded-full bottom-2 right-2 p-3 pr-4 pl-4 shadow-secondaryButton bg-white text-airpark-green hover:text-airpark-green-400">
                <span className={polygon ? 'opacity-100' : 'opacity-50'}>{t('parkingAreaMap.removeArea')}</span>
            </button>
        )
    }

    const ZoomInButton = () => {
        return (
            <button onClick={(e) => {
                e.preventDefault();
                const currentZoom = map.getZoom();
                map.setZoom(currentZoom + 1);
            }}
                className="flex absolute top-[72px] right-2 bg-white h-11 w-11 rounded-full shadow items-center justify-center font-medium text-2xl hover:scale-105 transition-all duration-200 ease-in-out">
                <span>+</span>
            </button>
        )
    }

    const ZoomOutButton = () => {
        return (
            <button onClick={(e) => {
                e.preventDefault();
                const currentZoom = map.getZoom();
                map.setZoom(currentZoom - 1);
            }}
                className="flex absolute top-[122px] right-2 bg-white h-11 w-11 rounded-full shadow items-center justify-center font-medium text-2xl hover:scale-105 transition-all duration-200 ease-in-out">
                <span>-</span>
            </button>
        )
    }

    const ChangeMapTypeButton = () => {
        return (
            <button onClick={(e) => {
                e.preventDefault();
                if (map.getMapTypeId() != window.google.maps.MapTypeId.SATELLITE) {
                    map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
                    map.setTilt(0);
                } else {
                    map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
                    map.setTilt(0);
                }
            }}
                className="flex absolute top-4 right-14 bg-white h-11 w-11 rounded-full shadow items-center justify-center font-medium text-2xl hover:scale-105 transition-all duration-200 ease-in-out"
            ><LayersIcon /></button>
        )
    }

    return (
        <LoadScriptNext libraries={libraries} googleMapsApiKey="AIzaSyD6UTBXOfbCvJWhKlK7gKGCBYzQ9ECNu2c">
            <GoogleMap
                onLoad={onLoadGoogleMap}
                id='map'
                mapContainerStyle={containerStyle}
                center={center}
                zoom={4}
                options={{
                    styles: googleMapStyle,
                    disableDefaultUI: true
                }}>
                <DrawingManager
                    onLoad={handlerLoadDrawingManager}
                    drawingMode={setParkingArea ? 'polygon' : 'none'}
                    options={{
                        drawingControl: false,
                        polygonOptions: {
                            fillColor: '#33D085',
                            fillOpacity: '0.4',
                            strokeWeight: 3,
                            editable: true,
                            zIndex: 1,
                            strokeColor: '#33D085',
                            draggable: true
                        }
                    }}
                    onPolygonComplete={onPolygonComplete}
                />
                {!isSearchDisabled &&
                    <StandaloneSearchBox
                        onLoad={onLoadSearchBox}
                        onPlacesChanged={onPlacesChanged}>
                        <input
                            type="text"
                            placeholder={t('createParkingArea.search')}
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width: `240px`,
                                height: `44px`,
                                padding: `0 12px`,
                                borderRadius: `12px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `16px`,
                                fontWeight: "400",
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                left: "12px",
                                top: "12px"
                            }}
                        />
                    </StandaloneSearchBox>
                }
                <LocateUserButton />
                {setParkingArea &&
                    <RemovePolygonButton />
                }
                <ZoomInButton />
                <ZoomOutButton />
                <ChangeMapTypeButton />
            </GoogleMap>
        </LoadScriptNext>
    )
}

export default ParkingAreaMap;