import { getParkingArea } from 'api/locations';
import { getAirPasses } from 'api/airPass';
import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import AirPassTable from 'components/pageSpecific/airPass/airPassTable';
import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const AirPassPage = () => {

    const { t } = useTranslation();
    const [airPasses, setAirPasses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { showToast } = useMessages();
    const { parkingAreaExtId } = useParams();
    const [parkingArea, setParkingArea] = useState();

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const airPassesResult = await getAirPasses(token, parkingAreaExtId);
                const parkingAreaResult = await getParkingArea(token, parkingAreaExtId);

                if (airPassesResult.error) {
                    console.error(airPassesResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setAirPasses(airPassesResult);
                }

                if (parkingAreaResult.error) {
                    console.error(parkingAreaResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setParkingArea(parkingAreaResult);
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            } finally {
                setIsLoading(false);
            }
        }

        load();
    }, []);

    return (
        <>
            <Widget className="mb-6">
                <div className="flex flex-col">
                    <h2 className="text-xl font-medium mb-1">{t('airPassPage.title')}</h2>
                    <span>{t('airPassPage.description')}</span>
                </div>
            </Widget>
            {isLoading &&
                <WidgetLoadingSkeleton />
            }
            {!isLoading && airPasses && parkingArea &&
                <Widget>
                    <AirPassTable
                        airPasses={airPasses}
                        setAirPasses={setAirPasses} />
                </Widget>
            }
        </>
    );
};

export default AirPassPage;