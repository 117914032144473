import React from "react";

/**
 * @typedef {Object} Tab
 * @property {string} title - The title displayed on the tab header.
 * @property {any} value - The value associated with the tab, can be any type.
 */

/**
 * Tabs component to render a set of tabs with customizable styles and behaviors.
 *
 * @param {Object} props - Props passed to the Tabs component.
 * @param {Tab[]} props.tabs - Array of tab objects, each containing a title and a value.
 * @param {any} props.activeTab - The index of the currently active tab.
 * @param {Function} props.setActiveTab - Function to set the active tab index.
 * @param {string} [props.className] - Additional class names for the container.
 * @returns {JSX.Element} The rendered Tabs component.
 */
const Tabs = ({ tabs, activeTab, setActiveTab, className = '' }) => {
    return (
        <div className={`flex w-full ${className}`}>
            {tabs.map(({ title, value}) => (
                <button
                    key={value}
                    onClick={() => setActiveTab(value)}
                    className={`w-fit mr-4 py-2 text-center font-medium border-b-2 transition-all duration-300 ${
                        activeTab === value
                            ? "border-airpark-green"
                            : "border-transparent text-airpark-gray-500 hover:text-airpark-oil-500"
                    }`}
                >
                    {title}
                </button>
            ))}
        </div>
    );
};

export default Tabs;
