import React, { useEffect, useState } from 'react';
import { CircleFlag } from 'components/common/flagIcon';
import { ReactComponent as IconExpand } from 'assets/icons/ico_arrow_down_green.svg';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/common/tooltip';
import Modal from 'components/common/modal';
import countries from 'helpers/countries';
import InputText from 'components/common/inputText';

const InputPhoneNumber = ({ title, tooltip, setPhoneNumber, defaultCountryCode, resetTrigger, defaultNumber }) => {
    const { t } = useTranslation();

    const [country, setCountry] = useState();
    const [number, setNumber] = useState();
    const [displayTelephoneNumberCountryPrefixModal, setDisplayTelephoneNumberCountryPrefixModal] = useState();

    useEffect(() => {
        if (defaultCountryCode) {
            /* Try to get a default country for the user */
            const defaultCountry = countries.find(({ country }) => country.toLowerCase() == defaultCountryCode.toLowerCase());

            if (defaultCountry) {
                setCountry(defaultCountry);
            }
        } else {
            setCountry({ code: '+1', country: 'US', name: 'United States' });
        }
    }, [defaultCountryCode]);

    useEffect(() => {
        setNumber('');
    }, [resetTrigger]);

    useEffect(() => {
        if (number) {
            /* Combine country code phone prefix with the number when the number changes */
            setPhoneNumber(number, country.code);
        } else {
            setPhoneNumber('', '');
        }
    }, [number]);

    useEffect(() => {
        if (country) {
            /* Hide the modal if the country changes (e.g. the user picks another country) */
            setDisplayTelephoneNumberCountryPrefixModal(false);
            setPhoneNumber(number, country.code);
        }
    }, [country]);

    const handleNumberChange = (value) => {
        if (value) {
            var regex = /[^0-9]|\./;
            setNumber(value.replace(regex, ''));
        } else {
            setNumber('');
        }
    };

    return (
        <>
            <div className="flex relative items-center">
                <span className="text-base font-medium pb-1">{title}</span>
                {tooltip &&
                    <Tooltip tooltip={tooltip} />
                }
            </div>
            {country &&
                <>
                    <div className="flex grow gap-3">
                        <button onClick={(e) => { e.preventDefault(); setDisplayTelephoneNumberCountryPrefixModal(true); }} className="flex w-24 h-11 gap-1 rounded-xl border-airpark-gray-300 hover:border-airpark-gray-400 border-2 items-center justify-center">
                            <CircleFlag countryCode={country.country.toLowerCase()} className="h-5 w-5" />
                            <span>{country.code}</span>
                            <IconExpand />
                        </button>
                        <input value={number ?? ''} inputMode="tel" onChange={e => handleNumberChange(e.target.value)} type='tel' className="flex grow rounded-xl p-2 pl-4 h-11 bg-airpark-gray-300 focus:outline-none" placeholder={t('loginPage.mobileNumber')}></input>
                    </div>
                    <TelephoneNumberCountryPrefixModal display={displayTelephoneNumberCountryPrefixModal} setDisplay={setDisplayTelephoneNumberCountryPrefixModal} setCountry={setCountry} />
                </>
            }
        </>
    )
};

const TelephoneNumberCountryPrefixModal = ({ display, setDisplay, setCountry }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState();

    return (
        <Modal title={t('inputPhoneNumber.chooseCountryRegion')} setDisplay={(value) => { setDisplay(false); }} display={display}>
            <div className="flex flex-col h-min-screen-safe">
                <InputText className='mb-3' placeHolder={t('generic.search')} value={search} onChange={(value) => { setSearch(value) }} />
                {countries && countries.map((country, i) => {
                    if ((search && country.name.toLowerCase().includes(search.toLowerCase())) || !search) {
                        return (
                            <CountryListItem key={i} country={country} handleSelect={(country) => { setCountry(country); }} />);
                    }
                })}
            </div>
        </Modal>
    )
}

const CountryListItem = ({ country, handleSelect }) => {
    return (
        <button onClick={(e) => { e.preventDefault(); handleSelect(country); }} className="flex flex-row h-11 border-b-[1px] border-gray-200 items-center mr-[-24px] ml-[-24px] pl-6 cursor-pointer">
            <CircleFlag countryCode={country.country.toLowerCase()} className="h-7 mr-2" />
            <span>{country.name} ({country.code})</span>
        </button>);
}

export default InputPhoneNumber;