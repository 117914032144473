import React, { useRef, useState } from "react";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/ico_thumbs_up_green.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/ico_warning_yellow.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ico_sad_smiley_red.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ico_menu_close.svg';
import { ReactComponent as ArrowDownIcon } from "assets/icons/ico_arrow_down_blue.svg";
import { ReactComponent as ArrowUpIcon } from "assets/icons/ico_arrow_up_blue.svg";

const Alert = ({ title, description, template, onClose, clickTitle, onClick, children}) => {
    const isInfoAlert = template === 'info'
    const [isOpen, setIsOpen] = useState(!isInfoAlert);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };
    const alertRef = useRef();
    const alertBaseStyle = `flex z-0 min-h-24 relative ${isOpen ? 'p-6' : 'items-start py-2 px-6'} w-full rounded-[30px] shadow border-[1px] border-solid`;      
    let alertStyle = '';

    switch (template) {
        case 'info':
            alertStyle = `${alertBaseStyle} bg-airpark-transparent-background border-airpark-blue`;
            break;
        case 'success':
            alertStyle = `${alertBaseStyle} bg-airpark-green-background border-airpark-green`;
            break;
        case 'warning':
            alertStyle = `${alertBaseStyle} bg-airpark-yellow-background border-airpark-yellow`;
            break;
        case 'error':
            alertStyle = `${alertBaseStyle} bg-airpark-red-background border-airpark-red`;
            break;
        default:
            alertStyle = `${alertBaseStyle} bg-airpark-blue-background border-airpark-blue`;
            break;
    }

    return (
        <div ref={alertRef} onClick={() => { if(onClose) {onClose();} }} className={`${alertStyle} ${isOpen ? 'items-start' : 'items-center'}`}>
            {template == 'info'  &&
                <InfoIcon className={`md:flex  ${isOpen ? 'w-8 mr-8 hidden' : 'w-6 mr-4 md:mr-10'}`} />
            
            }
            {template == 'success' &&
                <SuccessIcon className="md:flex w-8 hidden mr-8" />
            }
            {template == 'warning' &&
                <WarningIcon className="md:flex w-8 hidden mr-6" />
            }
            {template == 'error' &&
                <ErrorIcon className="md:flex w-8 hidden mr-8" />
            }
            <div className="flex flex-col w-full" onClick={isInfoAlert ? toggleAccordion : ''} >
                <span className={`font-medium  ${isOpen ? 'text-lg' : 'text-base'}`}>{title}</span>
                {isOpen && <span className="font-normal text-base">{description} {clickTitle && onClick && <a href="#" onClick={(e) => { e.preventDefault(); onClick(); }} className="underline">{clickTitle}</a>}</span>}
                {children}
            </div>
               {isInfoAlert && <div onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </div> }
            {onClose &&
                <button className="flex absolute right-9 top-6" onClick={(e) => { e.preventDefault(); onClose(); }}><CloseIcon className="h-5 w-5" /></button>
            }
        </div>
    );
}

export default Alert;