import React from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';
import InviteItem from './communityInvitesItem';

const CommunityInvitesTable = ({ invites, setInvites }) => {
    const { t } = useTranslation();

    if (!invites) {
        return (null);
    }

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">{t('invitesTable.phone')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('invitesTable.issuedBy')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {invites && invites.length > 0 && invites.map((invite, i) => {
                            return (<InviteItem 
                                        key={i} 
                                        invite={invite} 
                                        invites={invites} 
                                        setInvites={setInvites} />)
                        })}
                        {invites && invites.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={3}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        <span>{t('invitesTable.noInvites')}</span>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default CommunityInvitesTable;