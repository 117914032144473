import React, { useState, useEffect, useContext } from 'react';
import { exchangeRefreshToken, exchangeCodeAuth0 } from 'api/auth0';
import jwtDecode from "jwt-decode";

export const AuthContext = React.createContext({});

const useAuth = () => useContext(AuthContext);
export default useAuth;

export const getToken = async (forceNewtoken) => {

  const accessToken = localStorage.getItem('auth0_access_token');
  const decodedToken = jwtDecode(accessToken);

  if ((decodedToken.exp * 1000) > Date.now() && !forceNewtoken) {
    return accessToken;
  } else {
    // token expired, refresh it
    console.info('Token had expired. Refreshing token...');
    const refreshToken = localStorage.getItem('auth0_refresh_token');
    let res;

    try {
      res = await exchangeRefreshToken(refreshToken);
    } catch {
      return { error: 'Refresh token eror' };
    }

    const json = await res.json();

    localStorage.setItem('auth0_access_token', json.access_token);
    localStorage.setItem('auth0_refresh_token', json.refresh_token);

    return json.access_token;
  }
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isFinishedCheckingIfUserIsAuthenticated, setFinishedCheckingIfUserIsAuthenticated] = useState(false);

  useEffect(() => {
    checkUserAuth();
  }, []);

  const checkUserAuth = async () => {
    try {
      const token = await getToken();
      if (token && !token.error) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch { }
    finally { setFinishedCheckingIfUserIsAuthenticated(true); setLoading(false); }
  }

  // const requestSmsCode = async (number) => {
  //   setLoading(true);
  //   localStorage.setItem('auth0_username', number);

  //   const res = await requestAuth0SmsCode(number);
  //   setLoading(false);
  //   if (!res.ok) {
  //     throw new Error(`Error exchanging code with status ${res.status}`);
  //   }

  //   return res.ok
  // };

  // const exchangeCodeSms = async (code) => {
  //   setLoading(true);

  //   const username = localStorage.getItem('auth0_username');
  //   const res = await exchangeSmsCodeAuth0(code, username);
  //   if (!res.ok) {
  //     throw new Error(`Error exchanging code with status ${res.status}`);
  //   }

  //   const tokens = await res.json();
  //   localStorage.setItem('auth0_access_token', tokens.access_token);
  //   localStorage.setItem('auth0_refresh_token', tokens.refresh_token);

  //   setIsAuthenticated(true);
  //   setLoading(false);

  //   return tokens.access_token
  // };

  const exchangeCode = async (code) => {
    setLoading(true);
    var result = await exchangeCodeAuth0(code);

    if (!result.ok) {
      throw new Error(`Error exchanging code with status ${result.status}`);
    }

    const tokens = await result.json();
    localStorage.setItem('auth0_access_token', tokens.access_token);
    localStorage.setItem('auth0_refresh_token', tokens.refresh_token);

    setIsAuthenticated(true);
    setLoading(false);

    return tokens.access_token;
  };

  const logout = async (callback = () => {
   }) => {
    setLoading(true);
    localStorage.removeItem('auth0_access_token');
    localStorage.removeItem('auth0_refresh_token');
    localStorage.removeItem('auth0_username');
    localStorage.setItem('redirect_path', 'logout');
    setIsAuthenticated(false);
    setLoading(false);
    window.location.href = `https://auth.airpark.app/v2/logout?client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&returnTo=${window.location.origin}`;
    callback();
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loading,
        getToken,
        // requestSmsCode,
        // exchangeCodeSms,
        exchangeCode,
        logout,
        isFinishedCheckingIfUserIsAuthenticated
      }}
    >
      {children}
    </AuthContext.Provider>
  );

}