import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getToken } from "context/auth";

import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import { ReactComponent as ShareIcon } from "assets/icons/ico_share.svg";

import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';

import { useMessages } from "context/messages";
import TableItemContextMenu from "components/dashboard/tableItemContextMenu/itemContextMenu";
import { useNavigate, useParams } from "react-router-dom";
import { deletePermit, revokePermit } from "api/permits";
import formatDaysToDaySpan from "helpers/formatDaysToDaySpan";

const PermitItem = ({ permit, permits, setPermits }) => {

    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState();
    const { showToast, showModal, hideModal } = useMessages();
    const [isDeleting, setIsDeleting] = useState();
    const navigate = useNavigate();
    const { organizationExtId, parkingAreaExtId } = useParams();
    const editPermitUrl = permit.isMarketplace ? `/${organizationExtId}/parking-areas/${parkingAreaExtId}/edit-marketplace-permit/${permit.permitExtId.toLowerCase()}` :
        `/${organizationExtId}/parking-areas/${parkingAreaExtId}/edit-permit/${permit.permitExtId.toLowerCase()}`;

    const baseCssClass = `pl-6 whitespace-nowrap ${permit.status === 'expired' ? 'text-gray-400' : 'text-black'}`;

    const formatDate = (date) => {
        const d = new Date(date);

        // Format date to yyyy-mm-dd
        return d.toISOString().split('T')[0];
    }

    const handleRevokePermit = () => {

        setShowMenu(false);
        let revokeDate = new Date();

        if (permit.paymentStatus === 'Paid') {
            var now = new Date()
            var month = now.getMonth();

            if (now.getDate > 27) {
                month = month + 1;
            }

            revokeDate = new Date(
                now.getFullYear(),
                month,
                27, 0, 0);
        }

        showModal(
            t('permitTable.revokePermitTitle'),
            t('permitTable.revokePermitDescription', { date: formatDate(revokeDate) }),
            t('generic.yes'),
            async () => {
                setIsDeleting(true);
                hideModal();

                try {
                    const token = await getToken();
                    const result = await revokePermit(token, permit.permitExtId);

                    if (result.error) {
                        console.error(result);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    } else {
                        /* Successful, update item with new end date */
                        const index = permits.findIndex(({ permitExtId }) => permitExtId === permit.permitExtId);

                        permits[index] = result;
                        setPermits([...permits]);

                        showToast(t('permitTable.revokedPermitTitle'), t('permitTable.revokedPermitDescription'), 'success');
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                setIsDeleting(false);
            },
            t('generic.cancel'),
            () => { hideModal() },
            'warning');
    }

    const handleDeletePermit = () => {

        setShowMenu(false);

        showModal(
            t('permitTable.deletePermitTitle'),
            t('permitTable.deletePermitDescription'),
            t('generic.yes'),
            async () => {
                setIsDeleting(true);
                hideModal();

                try {
                    const token = await getToken();
                    const result = await deletePermit(token, permit.permitExtId);

                    if (result.error) {
                        console.error(result);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    } else {
                        /* Successful, remove item from list */
                        const index = permits.findIndex(({ permitExtId }) => permitExtId === permit.permitExtId);

                        permits.splice(index, 1);
                        setPermits([...permits]);

                        showToast(t('permitTable.deletedTitle'), t('permitTable.deletedDescription'), 'success');
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                setIsDeleting(false);
            },
            t('generic.cancel'),
            () => { hideModal() },
            'warning');
    }

    const getFriendlyParkingSpotsDescription = (permit) => {

        let friendlyParkingSpotsDescription = '';

        if (permit.parkingSpots?.length >= 1) {
            friendlyParkingSpotsDescription = permit.parkingSpots.map(ps => ps.spotIdentifier).join(', ');
        }

        if (friendlyParkingSpotsDescription.length > 0) {
            return friendlyParkingSpotsDescription;
        }

        return t('permitTable.parkingArea');
    }

    return (
        <tr className={`h-16 border-b-[1px] border-airpark-gray-300`}>
            <td className={baseCssClass}>{permit.permitExtId}</td>
            <td className={baseCssClass}>{getFriendlyParkingSpotsDescription(permit)}</td>
            <td className={baseCssClass}>{t(`permitTable.${permit.status}`)}</td>
            {permit.isMarketplace &&
                <td className={baseCssClass}>{`${permit.isCommunity ? t('permitTable.community') : t('permitTable.everyone')}`}</td>
            }
            {!permit.isMarketplace &&
                <>
                    <td className={baseCssClass}>{permit.email}</td>
                    <td className={baseCssClass}>{permit.firstName}</td>
                    <td className={baseCssClass}>{permit.lastName}</td>
                    <td className={baseCssClass}>{permit.phone}</td>
                </>
            }
            <td className={baseCssClass}>{permit.description}</td>
            {!permit.isMarketplace &&
                <td className={baseCssClass}>{permit.registrationIdentifier}</td>
            }

            <td className={baseCssClass}>{`${permit.endDateTime != null ? permit.friendlyPeriod : `${permit.friendlyPeriod} - ${t('permitTable.ongoing')}`}`}</td>
            {!permit.isRecurring &&
                <td className={baseCssClass} colSpan={2}>{t('parkingArea.asDefinedByPeriod')}</td>
            }
            {permit.isRecurring &&
                <>
                    <td className={baseCssClass}>{formatDaysToDaySpan(permit, t)}</td>
                    <td className={baseCssClass}>{permit.friendlyTime}</td>
                </>
            }
            <td className={baseCssClass}>{permit.isSublettingAllowed ? `${t('permitTable.allowed')} (${permit.isCommunity ? t('permitTable.community').toLowerCase() : t('permitTable.everyone').toLowerCase()})` : t('permitTable.notallowed')}</td>
            <td className={baseCssClass}>{permit.isPaymentHandledManually ? t('permitTable.thirdParty') : t('generic.airPark')}</td>
            <td className={`${baseCssClass} pr-6`}>
                {!permit.isPaymentHandledManually && permit.friendlyPrice && permit.unit &&
                    <>
                        {permit.friendlyPrice}/{t(`priceUnit.${permit.unit}`)}
                    </>
                }
                {!permit.friendlyPeriod && permit.unit &&
                    <>-</>
                }
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <TableItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onEditClick={() => { navigate(editPermitUrl); }}
                        onDeleteClick={
                            !permit.userExtId ? () => {
                                handleDeletePermit();
                            } : () => {
                                handleRevokePermit();
                            }}
                        deleteTitle={permit.userExtId ? t('generic.revoke') : t('generic.remove')}>
                        {permit.isMarketplace &&
                            <button className="flex items-center" onClick={(e) => { e.preventDefault(); navigate(`/${organizationExtId}/parking-areas/${parkingAreaExtId}/share-marketplace-permit/${permit.permitExtId}`); }} >
                                <ShareIcon className="h-5 w-5" /><span className="ml-1">{t('generic.share')}</span>
                            </button>
                        }
                    </TableItemContextMenu>
                }
            </td>
        </tr>
    );
}

export default PermitItem;