import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';
import ControlFeesItem from './controlFeesItem';
import Paging from 'components/common/paging';

const ControlFeesTable = ({ controlFees }) => {
    const [controlFeesPages, setControlFeesPages] = useState();
    const [currentControlFeesPage, setCurrentControlFeesPage] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (controlFees) {
            setControlFeesPages(Math.ceil(controlFees.length / 10));
            setCurrentControlFeesPage(0);
        }
    }, [controlFees]);

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">{t('controlFeesTable.createdAt')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('controlFeesTable.reason')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('controlFeesTable.notes')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('controlFeesTable.status')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('controlFeesTable.amount')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('controlFeesTable.paymentDueDate')}</th>
                            <th className="pl-6 pr-6 whitespace-nowrap">{t('controlFeesTable.paymentStatus')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {controlFees.slice(currentControlFeesPage * 10, ((currentControlFeesPage * 10) + 10)).map((controlFee, i) => {
                            return (<ControlFeesItem 
                                        key={i} 
                                        controlFee={controlFee} />)
                        })}
                        {controlFees && controlFees.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={6}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        <span>{t('controlFeesTable.noControlFeesFound')}</span>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            {controlFeesPages > 0 &&
                <Paging 
                    totalNumberOfPages={controlFeesPages} 
                    currentPage={currentControlFeesPage} 
                    setCurrentPage={setCurrentControlFeesPage} />
            }
        </>
    );
};

export default ControlFeesTable;