import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteCommunityImage, getCommunity, updateCommunity } from 'api/communities';

import { ReactComponent as DeleteIcon } from 'assets/icons/ico_delete.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';

import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';

import ButtonText from 'components/common/buttonText';
import InputFileUpload from 'components/common/inputFileUpload';
import InputText from 'components/common/inputText';
import InputTextArea from 'components/common/inputTextArea';
import BackButton from 'components/dashboard/backButton';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';

const ManageCommunityPage = () => {

    const [isLoading, setIsLoading] = useState();
    const [isSaving, setIsSaving] = useState();
    const [community, setCommunity] = useState({ description: '' });

    const { showToast, showModal, hideModal } = useMessages();
    const { communityExtId } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        const load = async () => {

            setIsLoading(true);

            try {
                const token = await getToken();
                const communityResult = await getCommunity(token, communityExtId);

                if (communityResult && !communityResult.error) {
                    setCommunity({ ...communityResult, images: [] });
                } else {
                    console.error(communityResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        if (communityExtId) {
            load();
        }
    }, [communityExtId]);

    const handleUpdateCommunity = async () => {

        if (isSaving) {
            return;
        }

        setIsSaving(true);

        try {
            var token = await getToken();
            const result = await updateCommunity(token, community);

            if (result && !result.error) {
                setCommunity({ ...result, images: [] });
                showToast(t('manageCommunityPage.communityUpdatedTitle'), t('manageCommunityPage.communityUpdatedDescription'), 'success');
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSaving(false);
    }

    const handleDeleteImage = async (communityExtId, fileName) => {
        hideModal();

        try {
            const token = await getToken();
            const result = await deleteCommunityImage(token, {
                communityExtId: communityExtId,
                imageFileName: fileName
            });

            if (result && !result.error) {
                setCommunity({ ...community, imageUrls: result });
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }
    }

    return (
        <>
            <BackButton />
            <div className="flex flex-col m-auto max-w-3xl w-full">
                <div className="flex flex-col w-full">
                    <h1 className="mt-14 text-center text-2xl font-medium mb-8">{t('manageCommunityPage.title')}</h1>
                    {!isLoading && community &&
                        <>
                            <div className="md:w-1/2">
                                <InputText value={community.name} onChange={(value) => { setCommunity({ ...community, name: value }); }} className="mb-3" title={t('createCommunityPage.name')} />
                            </div>
                            <InputTextArea value={community.description} onChange={(value) => { setCommunity({ ...community, description: value }); }} className="mb-3" title={t('createCommunityPage.description')} />

                            <span className="font-medium">{t('manageCommunityPage.photos')}</span>
                            <div className="flex flex-wrap w-full gap-6 mb-6">
                                {community.imageUrls?.length > 0 && community.imageUrls.map((image, i) => {
                                    return (
                                        <a key={i} href={image} target="_blank" className="mt-4 relative">
                                            <div className="flex h-8 w-8 rounded-full bg-white shadow-lg absolute -top-4 -right-4 items-center justify-center hover:scale-105 transition-all duration-200 ease-in-out cursor-pointer"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    showModal(
                                                        t('generic.warning'),
                                                        t('generic.areyousure'),
                                                        t('generic.yes'),
                                                        async () => {
                                                            const fileName = image.substring(image.lastIndexOf('/') + 1);
                                                            await handleDeleteImage(community.communityExtId, fileName);
                                                        },
                                                        t('generic.cancel'),
                                                        () => { hideModal(); },
                                                        'warning');
                                                }}>
                                                <DeleteIcon className="h-5 w-5" />
                                            </div>
                                            <img className="h-32 rounded-xl shadow" src={`${image}?height=128`} />
                                        </a>
                                    )
                                })
                                }
                                {community.imageUrls?.length < 1 &&
                                    <div className="flex mt-3 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        <span>{t('manageCommunityPage.noPhotos')}</span>
                                    </div>
                                }
                            </div>
                            <InputFileUpload
                                title={t('manageCommunityPage.uploadPhotos')}
                                tooltip={t('manageCommunityPage.photosTooltip')}
                                files={community.images}
                                setFiles={(files) => { setCommunity({ ...community, images: files }) }}
                                allowOnlySingleFile={true} />
                            <ButtonText className="mt-6" onClick={() => { handleUpdateCommunity(); }} isLoading={isSaving}>
                                {t('generic.update')}
                            </ButtonText>
                        </>
                    }
                    {isLoading &&
                        <WidgetLoadingSkeleton />
                    }
                </div>
            </div>
        </>
    );
};

export default ManageCommunityPage;