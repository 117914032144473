const isCountriesVisible = process.env.REACT_APP_SHOW_DEV_COUNTRIES === 'true';

const countries = [
    { code: '+1', country: 'US', name: 'United States' },
    { code: '+46', country: 'SE', name: 'Sverige' },
    { code: '+47', country: 'NO', name: 'Norge' },
    { code: '+45', country: 'DK', name: 'Danmark' },
    { code: '+358', country: 'FI', name: 'Suomi' },
    { code: '+49', country: 'DE', name: 'Deutschland' },
    { code: '+33', country: 'FR', name: 'France' },
    { code: '+44', country: 'GB', name: 'Great Britain' },
    { code: '+48', country: 'PL', name: 'Polska' },
    { code: '+39', country: 'IT', name: 'Italia' },
    { code: '+34', country: 'ES', name: 'España' },
    { code: '+30', country: 'GR', name: 'Greece' },
    { code: '+43', country: 'AT', name: 'Österreich' },
    { code: '+40', country: 'RO', name: 'România' },
    { code: '+31', country: 'NL', name: 'Nederland' },
    { code: '+32', country: 'BE', name: 'Belgique' },
    { code: '+36', country: 'HU', name: 'Magyarország' },
    { code: '+41', country: 'CH', name: 'Schweiz' },
    { code: '+354', country: 'IS', name: 'Ísland' },
    { code: '+370', country: 'LT', name: 'Lietuva' },
    { code: '+371', country: 'LV', name: 'Latvija' },
    { code: '+372', country: 'EE', name: 'Eesti' },
    { code: '+351', country: 'PT', name: 'Portugal	' },
  ].sort((a, b) => (a.name > b.name));

  if(isCountriesVisible) {
    countries.push({ code: '+385', country: 'HR', name: 'Hrvatska' })
    countries.push({ code: '+387', country: 'BA', name: 'Bosna i Hercegovina' })
  }

  export default countries;