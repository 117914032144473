const BASE_URL = `${process.env.REACT_APP_API_CONTROL_FEES_BASE_URL}/ControlFees`;

export const getControlFeesForParkingArea = async (token, parkingAreaExtId) => {
    const response = await fetch(BASE_URL + `/get-control-fees-for-parking-area/${parkingAreaExtId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })

    if (response.status === 200) {
        return response.json();
    }

    throw response;
}