import { getOrganizationForUser } from "api/organizations";
import { getUser } from "api/users";
import { getOrganizationsForUser } from "api/organizations";
import { getNotificationsForUser } from "api/notifications";

import useAuth, { getToken } from "context/auth";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
export const DashboardContext = React.createContext({});
export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
  const [organization, setOrganization] = useState();
  const [organizations, setOrganizations] = useState();
  const [notifications, setNotifications] = useState([]);
  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const { organizationExtId } = useParams();
  const { logout } = useAuth();

  useEffect(() => {
    const getData = async () => {
      const token = await getToken();

      try {
        const organizationResult = await getOrganizationForUser(token, organizationExtId);
        setOrganization(organizationResult);
      } catch (error) {
        console.error(error);
      }

      try {
        const userResult = await getUser(token);
        setUser(userResult);
      } catch (error) {
        console.error(error);
        logout();
      }

      try {
        const organizationsResult = await getOrganizationsForUser(token);
        setOrganizations(organizationsResult);
      } catch (error) {
        console.error(error);
      }

      try {
        const notificationsResult = await getNotificationsForUser(token);

        if (notificationsResult && !notificationsResult.error) {
          setNotifications(notificationsResult);
        }
      } catch (error) {
        console.error(error);
      }

      setLoading(false);
    }
    setLoading(true);
    getData();
  }, [organizationExtId]);

  return (
    <DashboardContext.Provider
      value={{
        organization,
        organizations,
        user,
        notifications,
        setNotifications,
        showNotificationsMenu,
        setShowNotificationsMenu,
        loading
      }}>
      {children}
    </DashboardContext.Provider>
  );
}