import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ico_arrow_left.svg';
import { useTranslation } from 'react-i18next';
import ButtonText from 'components/common/buttonText';
import { useMessages } from 'context/messages';
import PageLoad from 'components/common/pageLoad';
import Loading from 'components/common/loading';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';

const PermitSharePage = ({ isCreated, isUpdated }) => {

    const [isLoading, setIsLoading] = useState();
    const { organizationExtId, parkingAreaExtId, permitExtId } = useParams();
    const { showToast } = useMessages();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const shareLink = `${process.env.REACT_APP_LINK_BASE_URL}/marketplace/permit/${permitExtId}`;

    const handleBackButton = () => {
        navigate(-1);
    }

    const handleOKButton = () => {
        navigate(`/${organizationExtId}/parking-areas/${parkingAreaExtId}/permits/marketplace`)
    }

    return (
        <>
            <button className="flex p-6 absolute top-12 left-12 md:visible invisible" onClick={(e) => { e.preventDefault(); handleBackButton(); }}><ArrowLeftIcon /></button>
            <div className="flex flex-col m-auto max-w-[448px] w-full">
                {!isLoading &&
                    <>
                        <h1 className="mt-14 text-center text-2xl font-medium mb-8">
                            {isCreated && t('permitShare.createdTitle')}
                            {isUpdated && t('permitShare.updatedTitle')}
                            {(!isCreated && !isUpdated) && t('permitShare.shareTitle')}
                        </h1>
                        {isCreated &&
                            <span>{t('permitShare.createdDescription')}</span>
                        }
                        {isUpdated &&
                            <span>{t('permitShare.updatedDescription')}</span>
                        }
                        {(!isCreated && !isUpdated) &&
                            <span>{t('permitShare.shareDescription')}</span>
                        }
                        <a target="_blank" href={shareLink} className="text-airpark-green mt-1">{shareLink}</a>
                        <ButtonText isSecondary={true} className="mt-3 mb-3" 
                            onClick={() => 
                                { 
                                    navigator.clipboard.writeText(shareLink);
                                    showToast(t('permitShare.linkCopiedTitle'), t('permitShare.linkCopiedDescription'), 'success') 
                                }}>
                            {t('permitShare.copyLink')}
                        </ButtonText>
                        <ButtonText onClick={() => { handleOKButton(); }}>
                            {t('generic.ok')}
                        </ButtonText>
                    </>
                }
                {isLoading &&
                    <WidgetLoadingSkeleton />
                }
            </div>
        </>
    );
};

export default PermitSharePage;