import React, { useState } from 'react';
import TableItemContextMenu from 'components/dashboard/tableItemContextMenu/itemContextMenu';

import { useTranslation } from 'react-i18next';

import { ReactComponent as UsersIcon } from 'assets/icons/ico_users.svg';
import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import { useNavigate, useParams } from 'react-router-dom';

const CommunitiesItem = ({ community }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organizationExtId, parkingAreaExtId } = useParams();
    const [showMenu, setShowMenu] = useState();

    const handleNavigateToManageCommunity = () => {
        navigate(`/${organizationExtId}/parking-areas/${parkingAreaExtId}/manage-community/${community.communityExtId}`);
    }

    const handleNavigateToManageCommunityMembers = () => {
        navigate(`/${organizationExtId}/parking-areas/${parkingAreaExtId}/manage-community-members/${community.communityExtId}`);
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">
                <a href="#" onClick={(e) => { handleNavigateToManageCommunity(); }}>
                    {community.name}
                </a>
            </td>
            <td className="pl-6 whitespace-nowrap">{community.description}</td>
            
            {community.numberOfMembers === 0 &&
                <td className="pl-6 whitespace-nowrap"><a href="#" onClick={(e) => { e.preventDefault(); handleNavigateToManageCommunityMembers(); }}>{t('communitiesTable.noMembersManageMembers')}</a></td>
            }
            {community.numberOfMembers > 0 &&
                <td className="pl-6 whitespace-nowrap"><a href="#" onClick={(e) => { e.preventDefault(); handleNavigateToManageCommunityMembers(); }}>{community.numberOfMembers}</a></td>
            }
            
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] min-w-[72px] relative">
                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                    <ThreeDotsIcon className="block" />
                </button>
                {showMenu &&
                    <TableItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onEditClick={() => {
                            handleNavigateToManageCommunity();
                        }}
                        editTitle={t('communitiesTable.editCommunity')}>
                        <button className="flex items-center" onClick={(e) => { e.preventDefault(); handleNavigateToManageCommunityMembers(); }} >
                            <UsersIcon className="h-5 w-5" /><span className="ml-1">{t('communitiesTable.manageMembers')}</span>
                        </button>
                    </TableItemContextMenu>
                }
            </td>
        </tr>
    );
};

export default CommunitiesItem;