const BASE_URL = `${process.env.REACT_APP_API_USERS_BASE_URL}/Users`

export const getUser = async (token) => {
  const response = await fetch(BASE_URL, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}

export const getUsersForOrganization = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/get-users-for-organization/${organizationExtId}/PortalAdmin`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}

export const getSublessorsForOrganization = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/get-users-for-organization/${organizationExtId}/Sublessor`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}

export const getUsersForCommunity = async (token, communityExtId) => {
  const response = await fetch(BASE_URL + `/get-users-for-community/${communityExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}

export const createUser = async (token, user) => {
  const response = await fetch(BASE_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(user)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const resendEmailVerificationCode = async (token) => {
  const response = await fetch(BASE_URL + '/resend-verification-email', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
  })

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const getHasUserAcceptedTermsAndConditions = async (token) => {
  const response = await fetch(BASE_URL + `/has-user-approved-terms-and-conditions`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}

export const acceptTermsAndConditions = async (token) => {
  const response = await fetch(BASE_URL + `/approve-terms-and-conditions`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return true;
  } else {
    return false;
  }

  throw response
}

export const searchUserByPhoneNumber = async (token, phoneNumber) => {
  const response = await fetch(BASE_URL + `/search/${phoneNumber}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}