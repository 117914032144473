import React, { useState } from 'react';
import TableItemContextMenu from 'components/dashboard/tableItemContextMenu/itemContextMenu';

import { useMessages } from 'context/messages';
import { getToken } from 'context/auth';

import { deleteOrganizationInvite } from 'api/organizations';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';
import { deleteCommunityInvite } from 'api/communities';

const CommunityInviteItem = ({ invite, invites, setInvites }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState();
    const { showToast, showModal, hideModal } = useMessages();
    const [isDeleting, setIsDeleting] = useState();

    const handleDeleteCommunityInvite = async () => {
        setShowMenu(false);
        showModal(
            t('invitesTable.deleteInviteTitle'),
            t('invitesTable.deleteInviteDescription'),
            t('generic.yes'),
            async () => {
                setIsDeleting(true);
                hideModal();

                try {
                    const token = await getToken();
                    const deleteCommunityInvitationResult = await deleteCommunityInvite(token, invite.communityInviteExtId);

                    if (deleteCommunityInvitationResult && !deleteCommunityInvitationResult.error) {
                        /* Successful, remove item from list */
                        const newInvites = invites.filter(({ communityInviteExtId }) => communityInviteExtId !== invite.communityInviteExtId);
                        setInvites(newInvites);

                        showToast(t('invitesTable.removedInviteTitle'), t('invitesTable.removedInviteDescription'), 'success');
                    } else {
                        console.error(deleteCommunityInvitationResult);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                setIsDeleting(false);
            },
            t('generic.cancel'),
            () => { hideModal() },
            'warning');
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">{invite.phoneNumber}</td>
            <td className="pl-6 whitespace-nowrap">{invite.issuedBy}</td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <TableItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onDeleteClick={() => {
                            handleDeleteCommunityInvite();
                        }}
                        deleteTitle={t('generic.remove')}>
                    </TableItemContextMenu>
                }
            </td>
        </tr>
    );
};

export default CommunityInviteItem;