import { useDashboard } from 'context/dashboard';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/ico_close.svg';
import { ReactComponent as AirParkIcon } from 'assets/icons/ico_airpark_p_green.svg';
import { useTranslation } from 'react-i18next';
import { markAllNotificationsAsRead } from 'api/notifications';
import { getToken } from 'context/auth';

const NotificationsMenu = () => {

  const { showNotificationsMenu, setShowNotificationsMenu, notifications, setNotifications } = useDashboard();
  const { t } = useTranslation();

  useEffect(() => {

    const markNotificationsAsRead = async () => {
      try {
        const token = await getToken();
        await markAllNotificationsAsRead(token);
        
        setNotifications(notifications.map(notification => {
          return { ...notification, isRead: true }
        }));
      }
      catch (error) {
        console.error(error);
      }
    }

    if (showNotificationsMenu) {
      document.body.classList.add('overflow-hidden');
      markNotificationsAsRead();
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showNotificationsMenu]);

  const handleCloseNotificationsMenu = (e) => {
    e.preventDefault();
    setShowNotificationsMenu(false);
  };

  return (
    <>
      {showNotificationsMenu &&
        <div className="fixed inset-0 z-50 bg-black opacity-70" onClick={(e) => { e.preventDefault(); handleCloseNotificationsMenu(e); }}>
        </div>
      }
      <nav className={`flex h-screen fixed top-0 bg-white z-50 max-w-[375px] w-full transition-all ease-in-out duration-300 ${showNotificationsMenu ? 'right-0 overflow-y-scroll' : '-right-[375px] h-screen overflow-hidden'}`}>
        <div className="p-6 w-full">
          <button className="flex h-11 w-11 mb-6 items-center justify-center -ml-3" onClick={(e) => { handleCloseNotificationsMenu(e); }}>
            <CloseIcon className="h-6 w-6" />
          </button>
          <h2 className="font-medium text-2xl">{t('notificationsMenu.title')}</h2>
          <div className="mt-6">
            {notifications && notifications.length === 0 && <span>{t('notificationsMenu.noNotifications')}</span>
            }
            {notifications && notifications.length > 0 &&
              <ul className="flex flex-col gap-y-3 ">
                {notifications && notifications.length > 0 && notifications.map((notification, index) => {
                  return (
                    <li key={index}>
                      <button className="flex text-left items-start w-full gap-x-3">
                        <div className="min-w-11 relative">
                          {!notification.isRead &&
                            <span className="h-3 w-3 bg-airpark-red rounded-full text-white text-xs flex items-center justify-center absolute -top-1 -left-1 shadow" />
                          }
                          <AirParkIcon className="h-11 w-11" />
                        </div>
                        <div className="flex flex-col">
                          <span className="font-medium">{notification.title}</span>
                          <span className="text-gray-500 text-sm">{notification.body}</span>
                        </div>
                      </button>
                    </li>)
                })}
              </ul>
            }
          </div>
        </div>
      </nav>
    </>
  );
};

export default NotificationsMenu;
