import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';

import Paging from "components/common/paging";
import PermitItem from './permitItem';

const PermitsTable = ({ permits, setPermits, setShowEditPermitModal, setPermitToEdit, isMarketplace, noResult }) => {

    const [permitPages, setPermitPages] = useState();
    const [currentPermitPage, setCurrentPermitPage] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (permits) {
            setPermitPages(Math.ceil(permits.length / 10));
            setCurrentPermitPage(0);
        }
    }, [permits]);

    if (!permits) {
        return (null);
    }

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">{t('permitTable.id')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('permitTable.spotIdentifier')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('permitTable.status')}</th>
                            {isMarketplace &&
                                <th className="pl-6 whitespace-nowrap">{t('permitTable.availability')}</th>
                            }
                            {!isMarketplace &&
                                <>
                                    <th className="pl-6 whitespace-nowrap">{t('permitTable.email')}</th>
                                    <th className="pl-6 whitespace-nowrap">{t('permitTable.firstName')}</th>
                                    <th className="pl-6 whitespace-nowrap">{t('permitTable.lastName')}</th>
                                    <th className="pl-6 whitespace-nowrap">{t('permitTable.phoneNumber')}</th>
                                </>
                            }
                            <th className="pl-6 whitespace-nowrap">{t('permitTable.description')}</th>
                            
                            {!isMarketplace &&
                                <th className="pl-6 whitespace-nowrap">{t('permitTable.licensePlateNumber')}</th>
                            }
                            <th className="pl-6 whitespace-nowrap">{t('permitTable.period')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('permitTable.days')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('permitTable.time')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('permitTable.subletting')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('permitTable.invoicedBy')}</th>
                            <th className="pl-6 pr-6 whitespace-nowrap">{t('permitTable.price')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {permits && permits.length > 0 && permits.slice(currentPermitPage * 10, ((currentPermitPage * 10) + 10)).map((pa, i) => {
                            return (<PermitItem
                                key={i}
                                permit={pa}
                                permits={permits}
                                setPermits={setPermits}
                                setShowEditPermitModal={setShowEditPermitModal}
                                setPermitToEdit={setPermitToEdit} />)
                        })}
                        {permits && permits.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={15}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        {!noResult &&
                                            <span>{isMarketplace ? t('permitTable.noMarketplacePermits') : t('permitTable.noPermits')}</span>
                                        }
                                        {noResult &&
                                            <span>{t('permitTable.noPermitsFound')}</span>
                                        }
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

            </div>
            {permitPages != 0 &&
                <Paging totalNumberOfPages={permitPages} currentPage={currentPermitPage} setCurrentPage={setCurrentPermitPage} />
            }
        </>
    );
};

export default PermitsTable;