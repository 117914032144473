import React, { useState } from 'react';
import TableItemContextMenu from 'components/dashboard/tableItemContextMenu/itemContextMenu';

import { useTranslation } from 'react-i18next';

import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from 'context/auth';
import { removeUserFromCommunity } from 'api/communities';
import { useMessages } from 'context/messages';

const CommunityMemberItem = ({ communityMember, communityMembers, setCommunityMembers }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState();
    const navigate = useNavigate();
    const [isDeleting, setIsDeleting] = useState();
    const { communityExtId } = useParams();
    const { showToast } = useMessages();

    const handleRemoveMember = async () => {
        try {
            setShowMenu(false);
            setIsDeleting(true);
            const token = await getToken();
            const result = await removeUserFromCommunity(token, { userExtId: communityMember.userExtId, communityExtId: communityExtId });

            if (result && !result.error) {
                setCommunityMembers(communityMembers.filter((cm) => cm.userExtId !== communityMember.userExtId));
                showToast(t('generic.updated'), t('generic.updatedDescription'), 'success');
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsDeleting(false);
        }
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">{communityMember.firstName}</td>
            <td className="pl-6 whitespace-nowrap">{communityMember.lastName}</td>
            <td className="pl-6 whitespace-nowrap">{communityMember.phone}</td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <TableItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onDeleteClick={() => {
                            handleRemoveMember();
                        }}
                        deleteTitle={t('generic.remove')}>
                    </TableItemContextMenu>
                }
            </td>
        </tr>
    );
};

export default CommunityMemberItem;