import React, { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/ico_close.svg';
import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';
import { useTranslation } from 'react-i18next';
import { getPermitsForParkingSpot } from 'api/permits';
import ButtonText from 'components/common/buttonText';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import { useNavigate, useParams } from 'react-router-dom';

const PermitsModal = ({ selectedParkingSpot, display, setDisplay }) => {
    const { showToast } = useMessages();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [permits, setPermits] = useState();
    const navigate = useNavigate();
    const { organizationExtId, parkingAreaExtId } = useParams();

    const closeModal = () => {
        setDisplay(false);
    }

    const handleOverlayOnClick = (e) => {
        closeModal();
        e.stopPropagation();
    }



    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const result = await getPermitsForParkingSpot(token, selectedParkingSpot?.parkingSpotExtId);

                if (result && !result.error) {
                    setPermits(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        }

        if (selectedParkingSpot) {
            load();
        }
    }, [selectedParkingSpot]);

    const navigateToEditPermit = (permit) => {
        const editPermitUrl = permit.isMarketplace ? `/${organizationExtId}/parking-areas/${parkingAreaExtId}/edit-marketplace-permit/${permit.permitExtId.toLowerCase()}` :
        `/${organizationExtId}/parking-areas/${parkingAreaExtId}/edit-permit/${permit.permitExtId.toLowerCase()}`;

        navigate(editPermitUrl);
    }

    if (!display) {
        return (null);
    }

    return (
        <>
            {/* Overlay */}
            <div className="fixed h-screen inset-0 z-50 bg-black bg-opacity-60" />
            {/* Modal */}
            <div onClick={(e) => handleOverlayOnClick(e)} className="fixed inset-0 z-50 h-min-screen-safe justify-center overflow-y-auto px-6">
                <div className="flex justify-center pt-3 pb-3">
                    <div onClick={(e) => { e.stopPropagation(); }} className="flex flex-col w-full bg-white max-w-5xl rounded-lg p-6">
                        <div className={!loading ? 'airpark-scroll overflow-x-scroll' : ''}>
                            <div className="flex justify-end">
                                <button className="h-4" onClick={() => { closeModal(); }}>
                                    <CloseIcon className="h-5 w-5" />
                                </button>
                            </div>
                            <div className="flex justify-center mb-6">
                                <span className="font-medium">{t('permitsModal.permits')}</span>
                            </div>
                            {!loading &&
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className="text-left bg-airpark-gray-300 h-11">
                                            <th className="pl-6">{t('permitsModal.id')}</th>
                                            <th className="pl-6">{t('permitsModal.spotIdentifier')}</th>
                                            <th className="pl-6">{t('permitsModal.name')}</th>
                                            <th className="pl-6">{t('permitsModal.notes')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {permits?.map((permit, i) => {
                                            return (
                                                <tr key={i} className='h-16 border-b-[1px] border-airpark-gray-300 items-center justify-center'>
                                                    <td className="pl-6">{permit?.permitExtId}</td>
                                                    <td className="pl-6">{permit?.spotIdentifier}</td>
                                                    <td className="pl-6">{permit?.firstName} {permit?.lastName}</td>
                                                    <td className="pl-6 truncate overflow-hidden max-w-[124px]">{permit?.description}</td>
                                                    <td className="pl-6 flex h-16 justify-end items-center">
                                                        <ButtonText onClick={() => { navigateToEditPermit(permit); }}>
                                                            {t('generic.edit')}
                                                        </ButtonText>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            }
                            {loading &&
                                <WidgetLoadingSkeleton />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PermitsModal;