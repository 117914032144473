import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/ico_close.svg';
import { useTranslation } from 'react-i18next';
import ButtonText from 'components/common/buttonText';

import { createCommunityConnectRequest, getParkingAreasThatHasCommunities } from 'api/communities';
import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';

import CommunitiesMap from './communitiesMap';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import DropdownSelector from 'components/common/dropdownSelector/dropdownSelector';

import { ReactComponent as MapPin } from 'assets/icons/ico_map_pin_green_p_drop_shadow.svg';
import Loading from 'components/common/loading';
import Alert from 'components/common/alert';
import { useParams } from 'react-router-dom';


const CreateConnectCommunityRequestModal = ({ display, setDisplay, communities, communityConnectRequests, setCommunityConnectRequests }) => {

    const { t } = useTranslation();
    const [isSendingConnectRequest, setIsSendingConnectRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [parkingAreas, setParkingAreas] = useState();
    const { showToast } = useMessages();
    const [communityToConnectWith, setCommunityToConnectWith] = useState();
    const [community, setCommunity] = useState();
    const [communitiesToConnectWith, setCommunitiesToConnectWith] = useState();
    const [selectedParkingArea, setSelectedParkingArea] = useState();
    const [isLoadingCommunities, setIsLoadingCommunities] = useState(false);
    const { parkingAreaExtId } = useParams();

    const closeModal = () => {
        setDisplay(false);
    }

    const handleOverlayOnClick = (e) => {
        closeModal();
        e.stopPropagation();
    }

    useEffect(() => {

        const load = async () => {

            setIsLoading(true);

            try {
                const token = await getToken();
                const result = await getParkingAreasThatHasCommunities(token);

                if (result && !result.error) {
                    setParkingAreas(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }
        if (display) {
            load();
        }
    }, [display]);

    useEffect(() => {
        if (display) {
            document.body.classList.add('overflow-hidden');
        } else {
            setCommunitiesToConnectWith();
            setCommunityToConnectWith();
            setSelectedParkingArea();
            setCommunity();
            document.body.classList.remove('overflow-hidden');
        }
    }, [display]);

    useEffect(() => {
        /* Set default community when communitiesToConnectWith change */
        if (communitiesToConnectWith && communitiesToConnectWith.length > 0) {
            setCommunityToConnectWith(communitiesToConnectWith[0]);
        }
    }, [communitiesToConnectWith]);

    useEffect(() => {
        /* Set default community */
        if (communities && communities.length > 0) {
            setCommunity(communities[0]);
        }
    }, [communities]);

    const handleSendCommunityConnectRequest = async () => {

        setIsSendingConnectRequest(true);

        try {
            
            const token = await getToken();
            const result = await createCommunityConnectRequest(token, {
                communityExtId: community.communityExtId,
                communityRecipientExtId: communityToConnectWith.communityExtId
              });

            if (result && !result.error) {
                setCommunityConnectRequests([...communityConnectRequests, result]);
                closeModal();
                showToast(t('createConnectCommunityRequestModal.connectRequestSentTitle'), t('createConnectCommunityRequestModal.connectRequestSentDescription'), 'success');
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch(error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSendingConnectRequest(false);
    }

    if (!display) {
        return (null);
    }

    return (
        <>
            {/* Overlay */}
            <div className="fixed h-screen inset-0 z-50 bg-black bg-opacity-60">
            </div>
            {/* Modal */}
            <div onClick={(e) => handleOverlayOnClick(e)} className="fixed inset-0 z-50 h-min-screen-safe justify-center overflow-y-auto px-6">
                <div className="flex justify-center pt-3 pb-3">
                    <div onClick={(e) => { e.stopPropagation(); }} className="flex flex-col w-full bg-white max-w-3xl rounded-lg p-6">
                        <div className="flex justify-end">
                            <button className="h-4" onClick={() => { closeModal(); }}>
                                <CloseIcon className="h-5 w-5" />
                            </button>
                        </div>
                        <div className="flex flex-col items-center justify-center mb-3">
                            <span className="font-medium">{t('createConnectCommunityRequestModal.title')}</span>
                        </div>
                        {!isLoading && parkingAreas && parkingAreas.length > 0 &&
                            <>
                                <span className="mt-3">{t('createConnectCommunityRequestModal.stepsStart')}</span>
                                <ol className="flex flex-col pl-6 pr-6 mt-3 list-decimal">
                                    <li>{t('createConnectCommunityRequestModal.stepOne')}</li>
                                    <li>{t('createConnectCommunityRequestModal.stepTwo')}</li>
                                    <li>{t('createConnectCommunityRequestModal.stepThree')}</li>
                                </ol>
                                <span className="mt-3 mb-3">{t('createConnectCommunityRequestModal.stepsEnd')}</span>
                                <CommunitiesMap
                                    parkingAreas={parkingAreas}
                                    setCommunities={setCommunitiesToConnectWith}
                                    setSelectedParkingArea={setSelectedParkingArea}
                                    setIsLoading={setIsLoadingCommunities}
                                    disabled={communities?.length === 0} />
                                {isLoadingCommunities &&
                                    <div className="flex mt-6 justify-center items-center">
                                        <Loading />
                                    </div>
                                }
                                {selectedParkingArea && communitiesToConnectWith && communitiesToConnectWith.length > 0 && !isLoadingCommunities && selectedParkingArea.parkingAreaExtId.toLowerCase() !== parkingAreaExtId &&
                                    <div className="mt-6">
                                        <div className="flex items-center gap-x-1">
                                            <MapPin className="h-11 w-11" /> <span>{selectedParkingArea.name}, {selectedParkingArea.address} {selectedParkingArea.city}</span>
                                        </div>
                                        <div className="mt-3">
                                            <DropdownSelector
                                                title={t('createConnectCommunityRequestModal.selectCommunity')}
                                                options={communitiesToConnectWith.map(community => { return { value: community.communityExtId, title: community.name } })}
                                                defaultValue={communitiesToConnectWith[0].communityExtId}
                                                onChange={(value) => { setCommunityToConnectWith(value) }} />
                                        </div>
                                        <div className="mt-3">
                                            <DropdownSelector
                                                title={t('createConnectCommunityRequestModal.selectYourCommunity')}
                                                options={communities.map(community => { return { value: community.communityExtId, title: community.name } })}
                                                defaultValue={communities[0].communityExtId}
                                                onChange={(value) => { setCommunity(value) }} />
                                        </div>
                                    </div>
                                }
                                {!selectedParkingArea && communities && communities.length > 0 &&
                                    <div className="mt-3">
                                        <Alert title={t('createConnectCommunityRequestModal.noCommunitySelectedTitle')} description={t('createConnectCommunityRequestModal.noCommunitySelectedDescription')} template="info" />
                                    </div>
                                }
                                {selectedParkingArea && selectedParkingArea.parkingAreaExtId.toLowerCase() === parkingAreaExtId && communities && communities.length > 0 &&
                                    <div className="mt-3">
                                        <Alert title={t('createConnectCommunityRequestModal.youCantConnectWithYourOwnParkingAreaTitle')} description={t('createConnectCommunityRequestModal.youCantConnectWithYourOwnParkingAreaDescription')} template="warning" />
                                    </div>
                                }
                                {communities?.length < 1 &&
                                    <div className="mt-3">
                                        <Alert title={t('createConnectCommunityRequestModal.noCommunitiesCreatedForParkingAreaTitle')} description={t('createConnectCommunityRequestModal.noCommunitiesCreatedForParkingAreaDescription')} template="warning" />
                                    </div>
                                }
                                <ButtonText
                                    className="mt-6"
                                    onClick={() => { handleSendCommunityConnectRequest(); }}
                                    isLoading={isSendingConnectRequest}
                                    disabled={!communityToConnectWith || !community || selectedParkingArea?.parkingAreaExtId.toLowerCase() === parkingAreaExtId}>
                                    {t('createConnectCommunityRequestModal.sendConnectRequest')}
                                </ButtonText>
                            </>
                        }
                        {(isLoading || !parkingAreas) &&
                            <WidgetLoadingSkeleton />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateConnectCommunityRequestModal;