import React, { useEffect, useState } from "react";
import { useDashboard } from "context/dashboard";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

/* Wizard step views */
import AreaView from 'components/pageSpecific/createParkingArea/area';
import ParkingSpotsView from "components/pageSpecific/createParkingArea/parkingSpots";
import UploadProofOfOwnershipView from "components/pageSpecific/createParkingArea/uploadProofOfOwnership";
import DetailsView from "components/pageSpecific/createParkingArea/details";

const CreateParkingAreaPage = () => {

    const { organization } = useDashboard();
    const { t } = useTranslation();
    const { step } = useParams();
    const navigate = useNavigate();

    const [parkingArea, setParkingArea] = useState(
        {
            name: '',
            numberOfParkings: '',
            polygonCoordinates: [],
            proofOfOwnershipFiles: [],
            parkingAreaImageFiles: [],
            city: '',
            streetAddress: '',
            zipCode: '',
            country: '',
            parkingGroups: [],
            organizationExtId: organization.organizationExtId.toLowerCase()
        }
    );

    /* Prevent user from jumping into the middle of the wizard */
    useEffect(() => {
        if (!parkingArea.name && step != 'area') {
            navigate(`/${organization.organizationExtId.toLowerCase()}/create-parking-area/area`);
        }
    }, [parkingArea]);

    return (
        <>
            {step == 'area' &&
                <AreaView parkingArea={parkingArea} setParkingArea={setParkingArea} />
            }
            {step == 'parkingspots' &&
                <ParkingSpotsView parkingArea={parkingArea} setParkingArea={setParkingArea} />
            }
            {step == 'proofofownership' &&
                <UploadProofOfOwnershipView parkingArea={parkingArea} setParkingArea={setParkingArea} />
            }
            {step == 'details' &&
                <DetailsView parkingArea={parkingArea} setParkingArea={setParkingArea} />
            }
        </>
    )
}

export default CreateParkingAreaPage;