import React, { useState } from 'react';
import TableItemContextMenu from 'components/dashboard/tableItemContextMenu/itemContextMenu';

import { useTranslation } from 'react-i18next';

import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import { ReactComponent as CopyIcon } from "assets/icons/ico_copy.svg";
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from 'context/auth';
import { deleteCommunityInvite } from 'api/communities';
import { useMessages } from 'context/messages';

const CommunityInviteLinkItem = ({ communityInviteLink, communityInviteLinks, setCommunityInviteLinks }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState();
    const [isDeleting, setIsDeleting] = useState();
    const { showToast } = useMessages();
    const communityInviteLinkUrl = `${process.env.REACT_APP_LINK_BASE_URL}/community/${communityInviteLink.communityInviteExtId}`;

    const handleRemoveInviteLink = async () => {
        try {
            setShowMenu(false);
            setIsDeleting(true);
            const token = await getToken();
            const result = await deleteCommunityInvite(token, communityInviteLink.communityInviteExtId);

            if (result && !result.error) {
                setCommunityInviteLinks(communityInviteLinks.filter((cil) => cil.communityInviteExtId !== communityInviteLink.communityInviteExtId));
                showToast(t('generic.updated'), t('generic.updatedDescription'), 'success');
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsDeleting(false);
        }
    }

    const handleCopyClick = () => {
        navigator.clipboard.writeText(communityInviteLinkUrl);
        setShowMenu(false);
        showToast(t('generic.copied'), t('generic.copiedDescription'), 'success');
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">
                <a href={communityInviteLinkUrl} target="_blank" rel="noreferrer">{communityInviteLinkUrl}</a>
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <TableItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onDeleteClick={() => {
                            handleRemoveInviteLink();
                        }}
                        deleteTitle={t('generic.remove')}>
                        <button className="flex items-center" onClick={(e) => { e.preventDefault(); handleCopyClick(); }} >
                            <CopyIcon className="h-5 w-5" /><span className="ml-1">{t('generic.copy')}</span>
                        </button>
                    </TableItemContextMenu>
                }
            </td>
        </tr>
    );
};

export default CommunityInviteLinkItem;