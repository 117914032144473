import { useEffect, useState } from 'react';
import { getToken } from "context/auth";
import { useDashboard } from "context/dashboard";
import { useMessages } from "context/messages";
import { useTranslation } from "react-i18next";

const useOrganizationInvites = ({ getPortalAdminInvites, getSublessorInvites }) => {
    const [displayInviteUserModal, setDisplayInviteUserModal] = useState();
    const [isLoading, setIsLoading] = useState();
    const [portalAdminInvites, setPortalAdminInvites] = useState([]);
    const [sublessorInvites, setSublessorInvites] = useState([]);
    const [filteredPortalAdminInvites, setFilteredPortalAdminInvites] = useState();
    const [filteredSublessorInvites, setFilteredSublessorInvites] = useState();
    const { organization } = useDashboard();
    const { showToast } = useMessages();
    const [searchText, setSearchText] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        const load = async () => {

            setIsLoading(true);

            try {
                const token = await getToken();
                const [portalAdminInvitesResult, sublessorInvitesResult] = await Promise.all([
                    getPortalAdminInvites(token, organization.organizationExtId),
                    getSublessorInvites(token, organization.organizationExtId)
                ])

                if (portalAdminInvitesResult && !portalAdminInvitesResult.error) {
                    setPortalAdminInvites(portalAdminInvitesResult);
                } else {
                    console.error(portalAdminInvitesResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                if (sublessorInvitesResult && !sublessorInvitesResult.error) {
                    setSublessorInvites(sublessorInvitesResult)
                } else {
                    console.error(sublessorInvitesResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, []);

    useEffect(() => {
        if (searchText && portalAdminInvites) {
            setFilteredPortalAdminInvites([...portalAdminInvites.filter((user) => {
                if (user.firstName.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }
                if (user.lastName?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }

                if (user.email?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }

                if (user.phone?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }
            })]);
        } else if (portalAdminInvites) {
            setFilteredPortalAdminInvites([...portalAdminInvites]);
        }
    }, [searchText, portalAdminInvites]);

    useEffect(() => {
        if (searchText && sublessorInvites) {
            setFilteredSublessorInvites([...sublessorInvites.filter((invite) => {
                if (invite.firstName?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }
                if (invite.lastName?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }

                if (invite.email?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }
                if (invite.phoneNumber?.toLowerCase().includes(searchText.toLowerCase())) { 
                    return true; 
                }
            })]);
        } else if (sublessorInvites) {
            setFilteredSublessorInvites([...sublessorInvites]);
        }
    }, [searchText, sublessorInvites]);

    return {
        searchText,
        setSearchText,
        displayInviteUserModal,
        setDisplayInviteUserModal,
        isLoading,
        filteredPortalAdminInvites,
        filteredSublessorInvites,
        setFilteredPortalAdminInvites,
        setFilteredSublessorInvites
    }
}

export default useOrganizationInvites;