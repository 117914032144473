import DropDownMultiSelector from 'components/common/dropDownMultiSelector';
import Paging from 'components/common/paging';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams} from 'react-router-dom';
import { getToken } from 'context/auth';
import { getBookingsForParkingArea } from 'api/bookings';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import { useTranslation } from 'react-i18next';
import Widget from 'components/dashboard/widget';
import InputSearch from 'components/common/inputSearch';
import { is } from 'date-fns/locale';

const BookingsPage = () => {
    const [isLoading, setIsLoading] = useState();
    const [bookings, setBookings] = useState();
    const [filteredBookings, setFilteredBookings] = useState();
    const [bookingsPages, setBookingsPages] = useState();
    const [currentBookingsPage, setCurrentBookingsPage] = useState(0);
    const [searchText, setSearchText] = useState();
    
    const [searchParams]= useSearchParams();
    const { parkingAreaExtId } = useParams();

    const bookingExtId = searchParams.get("bookingExtId");
    
    const { t } = useTranslation();
    const [filterBookingOptions, setFilterBookingOptions] = useState([
        { title: t('bookingsPage.expired'), value: 'expired', isChecked: false },]);

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const result = await getBookingsForParkingArea(token, parkingAreaExtId);

                if (result && !result.error) {
                    setBookings(result);
                    setFilteredBookings(result);
                    setBookingsPages(Math.ceil(result.length / 10));
                    setCurrentBookingsPage(0);
                } else {
                    console.error(result);
                }
            } catch (error) {
                console.error(error);
            }

            setIsLoading(false);
        }

        if(bookingExtId) {
            setSearchText(bookingExtId)
            handleFilterBookings(filterBookingOptions.find(option => option.value === 'expired'))
        }

        setIsLoading(true);
        load();
    }, []);

    useEffect(() => {
        if (bookings) {
            let filteredBookings = [...bookings];

            filteredBookings = [...filteredBookings.filter((booking) => {

                // Check if booking has expired
                const isExpired = new Date(booking.endDateTime) < new Date();

                if (!isExpired) {
                    return true;
                }

                if (filterBookingOptions[0].isChecked && isExpired) {
                    return true;
                }
            })];

            if (searchText) {
                filteredBookings = [...filteredBookings.filter((booking) => {
                    if (booking.spotIdentifier?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (booking.registrationIdentifier?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }
                    if (booking.bookingExtId?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }
                })];
            }

            setFilteredBookings(filteredBookings);
            setBookingsPages(Math.ceil(filteredBookings?.length / 10));
        }
    }, [filterBookingOptions, searchText, bookings]);

    const handleFilterBookings = (option) => {
        const newOptions = [...filterBookingOptions];
        const optionIndex = newOptions.findIndex((opt) => opt.value === option.value);
        newOptions[optionIndex].isChecked = !newOptions[optionIndex].isChecked;
        setFilterBookingOptions(newOptions);
    }

    if (filteredBookings && !isLoading) {
        return (
            <>
                <Widget className="mb-6">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-medium mb-1">{t('bookingsPage.title')}</h2>
                        <span>{t('bookingsPage.description')}</span>
                    </div>
                </Widget>

                <Widget>
                    <div className="flex flex-row justify-end">
                        <div className="flex items-center gap-3">
                            <div className="hidden md:flex gap-3">
                                <InputSearch placeHolder={t('generic.search')} value={searchText} onChange={(value) => { setSearchText(value); }} />
                                <DropDownMultiSelector
                                    options={filterBookingOptions}
                                    onOptionSelected={(option) => { handleFilterBookings(option); }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                        <table className="table-auto mt-3 w-full">
                            <thead>
                                <tr className="text-left bg-airpark-gray-300 h-11">
                                    <th className="pl-6 whitespace-nowrap">{t('generic.id')}</th>
                                    <th className="pl-6 whitespace-nowrap">{t('bookingsPage.parkingSpot')}</th>
                                    <th className="pl-6 whitespace-nowrap">{t('bookingsPage.startDate')}</th>
                                    <th className="pl-6 whitespace-nowrap">{t('bookingsPage.endDate')}</th>
                                    <th className="pl-6 whitespace-nowrap">{t('bookingsPage.duration')}</th>
                                    <th className="pl-6 whitespace-nowrap">{t('bookingsPage.paidAmount')}</th>
                                    <th className="pl-6 pr-6 whitespace-nowrap">{t('bookingsPage.registrationIdentifier')}</th>
                                    {/* <th className="pl-6 whitespace-nowrap"></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredBookings && filteredBookings.slice(currentBookingsPage * 10, ((currentBookingsPage * 10) + 10)).map((item, i) => {
                                    return (<BookingItem key={i} booking={item} />)
                                })}

                            </tbody>
                        </table>
                        {filteredBookings?.length == 0 &&
                            <div className="flex w-full flex-row items-center justify-center mt-3 mb-3">
                                <span className="p-3">{t('bookingsPage.noResult')}</span>
                            </div>
                        }
                    </div>
                    <div className="p-6">
                        <Paging
                            totalNumberOfPages={bookingsPages}
                            currentPage={currentBookingsPage}
                            setCurrentPage={setCurrentBookingsPage}
                        />
                    </div>
                </Widget>
            </>
        );
    } else {
        return (
            <WidgetLoadingSkeleton />
        )
    }
};

const getHoursAndMinutesBetweenDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const diff = end.getTime() - start.getTime();

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff - (hours * 1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`;
}

const BookingItem = ({ booking }) => {
    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6">{booking.bookingExtId}</td>
            <td className="pl-6">{booking.spotIdentifier}</td>
            <td className="pl-6 whitespace-nowrap">{booking.startDateTime.substring(0, 10)} {booking.startDateTime.substring(11, 16)}</td>
            <td className="pl-6 whitespace-nowrap">{booking.endDateTime.substring(0, 10)} {booking.endDateTime.substring(11, 16)}</td>
            <td className="pl-6 whitespace-nowrap">{getHoursAndMinutesBetweenDates(booking.startDateTime, booking.endDateTime)}</td>
            <td className="pl-6 whitespace-nowrap">{booking.friendlyPrice}</td>
            <td className="pl-6">{booking.registrationIdentifier}</td>
            {/* <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); navigate(booking.bookingExtId) }}>
                    <NextIcon />
                </button>
            </td> */}
        </tr>
    )
}

export default BookingsPage;