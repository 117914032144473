import React, { useEffect, useRef, useState } from "react"; import { useTranslation } from "react-i18next";

import { ReactComponent as PenIcon } from "assets/icons/ico_pen.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/ico_delete.svg";

const TableItemContextMenu = ({ onEditClick, onDeleteClick, onCloseClick, editTitle, deleteTitle, children }) => {
    const { t } = useTranslation();
    const [className, setClassName] = useState('flex fixed flex-col gap-3 min-w-[224px] z-20 shadow-xl rounded-lg bg-white p-6 transition-all ease-in-out duration-100 scale-75 opacity-50');
    const containerRef = useRef();
    const [position, setPosition] = useState();


    useEffect(() => {
        setPositionOfElement(containerRef.current);
        setTimeout(() => {
            setClassName('flex fixed flex-col gap-3 min-w-[224px] z-20 shadow-xl rounded-lg bg-white p-6 transition-all duration-100 scale-100 opacity-100');
        }, 10);


        const handleScroll = event => {
            onCloseClick();
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [onCloseClick]);

    const setPositionOfElement = (element) => {
        const rect = element.getBoundingClientRect();
        setPosition({ top: rect.top - 24, left: rect.left - 200 });
    }

    return (
        <div ref={containerRef}>
            {position &&
                <>
                    <div className="fixed inset-0 z-10" onClick={(e) => { e.preventDefault(); onCloseClick(); }} />
                    <div style={{ top: position.top, left: position.left }} className={className}>
                        {children}
                        {onEditClick &&
                            <button className="flex items-center" onClick={(e) => { e.preventDefault(); onEditClick(); }} >
                                <PenIcon className="h-5 w-5" /><span className="ml-1">{editTitle ? editTitle : t('generic.edit')}</span>
                            </button>
                        }
                        {onDeleteClick &&
                            <button className="flex items-center" onClick={(e) => { e.preventDefault(); onDeleteClick(); }}>
                                <DeleteIcon className="h-5 w-5" /><span className="ml-1">{deleteTitle ? deleteTitle : t('generic.remove')}</span>
                            </button>
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default TableItemContextMenu;