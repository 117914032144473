import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'lottie-react';

import { useMessages } from 'context/messages';

import TableItemContextMenu from 'components/dashboard/tableItemContextMenu/itemContextMenu';

import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import { ReactComponent as NextIcon } from 'assets/icons/ico_next_green.svg';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import { getToken } from 'context/auth';
import { deleteCommunityConnectRequest } from 'api/communities';

const ReceivedRequestsItem = ({ receivedRequest, receivedRequests, setReceivedRequests }) => {

    const [showMenu, setShowMenu] = useState();
    const { showToast, showModal, hideModal } = useMessages();
    const [isDeleting, setIsDeleting] = useState();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organizationExtId, parkingAreaExtId } = useParams();

    const handleDeleteReceivedRequest = async () => {
        setShowMenu(false);
        showModal(
            t('receivedRequestsTable.deleteReceivedRequestTitle'),
            t('receivedRequestsTable.deleteReceivedRequestDescription'),
            t('generic.yes'),
            async () => {
                setIsDeleting(true);
                hideModal();

                try {
                    const token = await getToken();
                    const deleteCommunityConnectResult = await deleteCommunityConnectRequest(token, receivedRequest.communityConnectRequestExtId);

                    if (!deleteCommunityConnectResult?.error) {
                        /* Successful, remove item from list */
                        const index = receivedRequests.findIndex(({ communityConnectRequestExtId }) => communityConnectRequestExtId === receivedRequest.communityConnectRequestExtId);
                        receivedRequests.splice(index, 1);
                        setReceivedRequests([...receivedRequests]);
                        showToast(t('receivedRequestsTable.removedReceivedRequestTitle'), t('receivedRequestsTable.removedReceivedRequestDescription'), 'success');
                    } else {
                        console.error(deleteCommunityConnectResult);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                setIsDeleting(false);
            },
            t('generic.cancel'),
            () => { hideModal() },
            'warning');
    }

    const handleNavigateToCommunityConnectRequestPage = () => {
        navigate(`/${organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId}/community/connect-request/${receivedRequest.communityConnectRequestExtId}`);
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">{receivedRequest.communityRecipientName}</td>
            <td className="pl-6 whitespace-nowrap">{receivedRequest.communityName}</td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <TableItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onDeleteClick={() => {
                            handleDeleteReceivedRequest();
                        }}
                        deleteTitle={t('generic.remove')}>
                    </TableItemContextMenu>
                }
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] relative">
                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); handleNavigateToCommunityConnectRequestPage(); }}>
                    <NextIcon />
                </button>
            </td>
        </tr>
    );
};

export default ReceivedRequestsItem;

