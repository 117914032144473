import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';

import Paging from "components/common/paging";
import PayoutDetailsItem from './payoutDetailsItem';

const PayoutDetailsTable = ({ payout, payoutLines }) => {

    const [payoutLinePages, setPayoutLinePages] = useState();
    const [currentPayoutLinePage, setCurrentPayoutLinePage] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (payoutLines) {
            setPayoutLinePages(Math.ceil(payoutLines.length / 20));
            setCurrentPayoutLinePage(0);
        }
    }, [payoutLines]);

    if (!payoutLines) {
        return (null);
    }

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.type')}
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.sum')} ({payout.currencySymbol})
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.vat')} ({payout.currencySymbol})
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.transactionFee')} ({payout.currencySymbol})
                            </th>

                            {/* AirPark fee */}
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.airParkFee')} (%)
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.airParkFee')} ({payout.currencySymbol})
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.airParkFee')}, {t('payoutDetailsTable.vat')} ({payout.currencySymbol})
                            </th>

                            {/* Owner fee */}
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.propertyOwnerFee')} (%)
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.propertyOwnerFee')} ({payout.currencySymbol})
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.propertyOwnerFee')}, {t('payoutDetailsTable.vat')} (%)
                            </th>
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.propertyOwnerFee')}, {t('payoutDetailsTable.vat')} ({payout.currencySymbol})
                            </th>

                            {/* Subletting */}
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.subletting')},({payout.currencySymbol})
                            </th>
                            <th className="pl-6 whitespace-nowrap pr-6">
                                {t('payoutDetailsTable.subletting')}, (%)
                            </th>

                            {/* Payment provider */}
                            <th className="pl-6 whitespace-nowrap">
                                {t('payoutDetailsTable.paymentProvider')}
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {payoutLines && payoutLines.length > 0 && payoutLines.slice(currentPayoutLinePage * 20, ((currentPayoutLinePage * 20) + 20)).map((payoutLine, i) => {
                            return (<PayoutDetailsItem
                                key={i}
                                payoutLine={payoutLine} />)
                        })}
                        {payoutLines?.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={15}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        <span>{t('payoutDetailsTable.noPayoutLines')}</span>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

            </div>
            {payoutLinePages != 0 &&
                <Paging totalNumberOfPages={payoutLinePages} currentPage={currentPayoutLinePage} setCurrentPage={setCurrentPayoutLinePage} />
            }
        </>
    );
};

export default PayoutDetailsTable;