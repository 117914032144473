import { ReactComponent as HomeIcon } from 'assets/icons/ico_home.svg';
import { ReactComponent as ReportsIcon } from 'assets/icons/ico_reports.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/ico_users.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/ico_settings.svg';
import { ReactComponent as MoneyIcon } from 'assets/icons/ico_money.svg';
import { ReactComponent as AirParkIcon } from 'assets/icons/ico_p_square.svg';
import { ReactComponent as CommunityIcon } from 'assets/icons/ico_community.svg';
import { ReactComponent as AreaIcon } from 'assets/icons/ico_map_pin.svg';
import { ReactComponent as ControlFeeIcon } from 'assets/icons/ico_ticket.svg';
import { ReactComponent as PermitIcon } from 'assets/icons/ico_permit.svg';
import { ReactComponent as SharingIcon } from 'assets/icons/ico_sharing.svg';
import { ReactComponent as ChargingIcon } from 'assets/icons/ico_charging.svg';
import { ReactComponent as GarageIcon } from 'assets/icons/ico_garage_black.svg';
import { ReactComponent as BookingsIcon } from 'assets/icons/ico_bookings.svg';

export const organizationMenuItems =
    [
        { translationKey: 'leftMenu.start', icon: <HomeIcon className="w-6 h-6" />, to: '' },
        { translationKey: 'leftMenu.areas', icon: <AreaIcon className="w-6 h-6" />, to: 'parking-areas' },
        { translationKey: 'leftMenu.statistics', icon: <ReportsIcon className="w-6 h-6" />, to: 'statistics'},
        {
            translationKey: 'leftMenu.payouts', icon: <MoneyIcon className="w-6 h-6" />, to: 'payouts',
            subItems: [
                {
                    translationKey: 'leftMenu.payoutSettings',
                    to: 'payouts/settings',
                    icon: <SettingsIcon className="w-6 h-6" />
                },
            ]
        }, ,
        { translationKey: 'leftMenu.users', icon: <UsersIcon className="w-6 h-6" />, to: 'users' },
        {
            translationKey: 'leftMenu.settings',
            icon: <SettingsIcon className="w-6 h-6" />,
            to: 'settings',
            subItems: [
                {
                    translationKey: 'leftMenu.revenueSharing',
                    to: 'settings/revenue-sharing',
                    icon: <SharingIcon className="w-6 h-6" />
                }
            ]
        },
    ];

export const leftMenuParkingAreaItems =
    [
        {
            translationKey: 'leftMenuParkingArea.parking', icon: <AirParkIcon className="w-6 h-6" />, to: '',
            subItems: [
                { translationKey: 'leftMenuParkingArea.signs', to: 'signs' },
            ]
        },
        {
            translationKey: 'leftMenuParkingArea.permits', icon: <PermitIcon className="w-6 h-6" />, to: 'permits',
            subItems: [
                { translationKey: 'leftMenuParkingArea.parkingSpots', to: 'permits/parking-spots' },
                { translationKey: 'leftMenuParkingArea.marketplace', to: 'permits/marketplace' },
                { translationKey: 'leftMenuParkingArea.queue', to: 'permits/queue' },
                { translationKey: 'leftMenuParkingArea.customAgreement', to: 'permits/custom-agreement' },
            ]
        },
        {
            translationKey: 'leftMenuParkingArea.controlfees',
            icon: <ControlFeeIcon className="w-6 h-6" />,
            to: 'control-fees',
            subItems: [
                { translationKey: 'leftMenuParkingArea.controlFeesSettings', to: 'control-fees/settings' },
            ]
        },
        {
            translationKey: 'leftMenuParkingArea.community',
            icon: <CommunityIcon className="w-6 h-6" />,
            to: 'community',
            subItems: [
                { translationKey: 'leftMenuParkingArea.linkedCommunities', to: 'community/connections' }
            ]
        },
        {
            translationKey: 'leftMenuParkingArea.charging',
            icon: <ChargingIcon className="w-6 h-6" />,
            to: 'charging'
        },
        {
            translationKey: 'leftMenuParkingArea.airPass',
            icon: <GarageIcon className="w-6 h-6" />,
            to: 'airpass'
        },
        {
            translationKey: 'leftMenuParkingArea.bookings',
            icon: <BookingsIcon className="w-6 h-6" />,
            to: 'bookings'
        }
    ];