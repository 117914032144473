
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

/* Layouts */
import DefaultLayout from 'layouts/defaultLayout';
import LoginLayout from 'layouts/loginLayout';
import AuthenticatedLayout from 'layouts/authenticatedLayout';
import DashboardLayout from 'layouts/dashboardLayout';
import DashboardLeftMenuLayout from 'layouts/dashboardLeftMenuLayout';
import DashboardParkingAreaLeftMenuLayout from 'layouts/dashboardParkingAreaLeftMenuLayout';
import DashboardNoMenuLayout from 'layouts/dashboardNoMenuLayout';

/* Login pages */
import LoginPage from 'pages/login';
import VerifySmsCodePage from 'pages/verifySmsCode';
import RegisterPage from 'pages/register';
import ChooseOrganizationPage from 'pages/organization/choose';
import CreateOrganizationPage from 'pages/organization/create';
import AuthenticationCallbackPage from 'pages/authenticationCallback/authenticationCallback';
import VerifyEmailPage from 'pages/verifyEmail';

/* Dashboard */
import DashboardPage from 'pages/dashboard';
import ParkingAreasPage from 'pages/parkingAreas';
import SettingsPage from 'pages/settings';
import StatisticsPage from 'pages/statistics';
import PayoutPrintPage from 'pages/payoutPrint';
import OrganizationUsersPage from 'pages/organizationUsers';
import AboutPage from 'pages/about';

/* Parking area pages */
import ParkingAreaPage from 'pages/parkingArea';
import CreateParkingAreaPage from 'pages/createParkingArea';
import PermitsPage from 'pages/permits';
import PermitQueuePage from 'pages/permitQueue';
import PermitCustomAgreementPage from 'pages/permitCustomAgreement';
import ControlFeesPage from 'pages/controlFees';
import EditParkingAreaPage from 'pages/editParkingArea/editParkingArea';
import PermitPage from 'pages/permit';
import PermitMarketplacePage from 'pages/permitMarketplace';
import ParkingAreaAddPriceRuleSet from 'pages/parkingAreaAddPriceRuleSet';
import ParkingAreaFixedTimePage from 'pages/parkingAreaFixedTime';
import SignsPage from 'pages/signs';
import ChargingPage from 'pages/charging/charging';
import ChargingStationPage from 'pages/chargingStation';
import AirPassPage from 'pages/airPass';
import EditAirPassPage from 'pages/airPass/editAirPass';
import BookingsPage from 'pages/bookings';

/* Components */
import ScrollToTop from 'components/common/scrollToTop';
import PermitSharePage from 'pages/permitShare';
import OrganizationInvitePage from 'pages/organizationInvite';

/* Community */
import CommunitiesPage from 'pages/communities';
import CreateCommunityPage from 'pages/createCommunity';
import ManageCommunityPage from 'pages/manageCommunity/manageCommunity';
import ManageCommunityMembersPage from 'pages/manageCommunityMembers';
import CommunityConnectRequestPage from 'pages/communityConnectRequest';
import CommunityConnectionsPage from 'pages/communityConnections';

/* Parking area reports */
import PayoutsPage from 'pages/payouts';
import PayoutDetailsPage from 'pages/payoutDetails';
import PermitParkingSpotsPage from 'pages/permitParkingSpots';
import ControlFeesSettingsPage from 'pages/controlFeesSettings';
import PayoutSettingsPage from 'pages/payoutSettings';
import RevenueSharingSettingsPage from 'pages/revenueSharingSettings';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route element={<DefaultLayout />}>
            <Route element={<LoginLayout />}>
              <Route path="/" element={<LoginPage />} />
              <Route path="verify-sms-code" element={<VerifySmsCodePage />} />
              <Route path="verify-email" element={<VerifyEmailPage />} />
              <Route path="verified" element={<AuthenticatedLayout />}>
                <Route path="register" element={<RegisterPage />} />
                <Route path="choose-organization" element={<ChooseOrganizationPage />} />
                <Route path="create-organization" element={<CreateOrganizationPage />} />
              </Route>
              <Route path="authentication-callback" element={<AuthenticationCallbackPage />} />
            </Route>
            <Route path="organization-invite/:organizationInviteExtId" element={<OrganizationInvitePage />} />
            <Route path=":organizationExtId" element={<DashboardLayout />}>
              <Route path="" element={<DashboardLeftMenuLayout />}>
                <Route path="" element={<DashboardPage />} />
                <Route path="parking-areas" element={<ParkingAreasPage />} />
                <Route path="settings" element={<SettingsPage />} />
                <Route path="settings/revenue-sharing" element={<RevenueSharingSettingsPage />} />
                <Route path="users" element={<OrganizationUsersPage />} />
                <Route path="statistics" element={<StatisticsPage />} />
                <Route path="payouts" element={<PayoutsPage />} />
                <Route path="payouts/settings" element={<PayoutSettingsPage />} />
                <Route path="payouts/:payoutExtId" element={<PayoutDetailsPage />} />
                <Route path="about" element={<AboutPage />} />
              </Route>
              <Route path="parking-areas/:parkingAreaExtId" element={<DashboardParkingAreaLeftMenuLayout />}>
                <Route path="" element={<ParkingAreaPage />} />
                <Route path="fixed-times" element={<ParkingAreaFixedTimePage />} />
                <Route path="signs" element={<SignsPage />} />
                <Route path="permits" element={<PermitsPage />} />
                <Route path="permits/parking-spots" element={<PermitParkingSpotsPage />} />
                <Route path="permits/queue" element={<PermitQueuePage />} />
                <Route path="permits/marketplace" element={<PermitMarketplacePage />} />
                <Route path="permits/custom-agreement" element={<PermitCustomAgreementPage />} />
                <Route path="control-fees" element={<ControlFeesPage />} />
                <Route path="control-fees/settings" element={<ControlFeesSettingsPage />} />
                <Route path="community" element={<CommunitiesPage />} />
                <Route path="community/connections" element={<CommunityConnectionsPage />} />
                <Route path="charging" element={<ChargingPage />} />
                <Route path="airpass" element={<AirPassPage />} />
                <Route path="bookings" element={<BookingsPage />} />
              </Route>
              <Route path="parking-areas/:parkingAreaExtId" element={<DashboardNoMenuLayout />}>
                <Route path="edit" element={<EditParkingAreaPage />} />
                <Route path="create-permit" element={<PermitPage isMarketplace={false} />} />
                <Route path="create-marketplace-permit" element={<PermitPage isMarketplace={true} />} />
                <Route path="create-permit-for-queue/:permitQueueItemExtId" element={<PermitPage isMarketplace={false} />} />
                <Route path="edit-permit/:permitExtId" element={<PermitPage />} />
                <Route path="edit-marketplace-permit/:permitExtId" element={<PermitPage isMarketplace={true} />} />
                <Route path="created-marketplace-permit/:permitExtId" element={<PermitSharePage isCreated={true} />} />
                <Route path="updated-marketplace-permit/:permitExtId" element={<PermitSharePage isUpdated={true} />} />
                <Route path="share-marketplace-permit/:permitExtId" element={<PermitSharePage />} />
                <Route path="create-community" element={<CreateCommunityPage />} />
                <Route path="manage-community/:communityExtId" element={<ManageCommunityPage />} />
                <Route path="manage-community-members/:communityExtId" element={<ManageCommunityMembersPage />} />
                <Route path="community/connect-request/:communityConnectRequestExtId" element={<CommunityConnectRequestPage />} />
                <Route path="charging/add-charging-station" element={<ChargingStationPage />} />
                <Route path="charging/edit-charging-station/:chargingStationExtId" element={<ChargingStationPage />} />
                <Route path="airpass/edit/:airPassExtId" element={<EditAirPassPage />} />
              </Route>
              <Route path="parking-areas/:parkingAreaExtId/add-price-and-availability" element={<DashboardNoMenuLayout />}>
                <Route path="" element={<ParkingAreaAddPriceRuleSet />} />
                <Route path=":priceRuleSetExtId" element={<ParkingAreaAddPriceRuleSet />} />
              </Route>
              <Route path="create-parking-area" element={<DashboardNoMenuLayout />}>
                <Route path=":step" element={<CreateParkingAreaPage />} />
              </Route>
              <Route path="payouts/:payoutExtId/print" element={<DashboardLayout />}>
                <Route path="" element={<PayoutPrintPage />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter >);
}

export default App;