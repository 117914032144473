import React from "react";
import { Outlet } from "react-router-dom";
import Logo from "components/common/logo";

const DashboardNoMenuLayout = () => {

    return (
        <div className="flex flex-col p-6">
            <div className="flex justify-center">
                <Logo className="p-6" />
            </div>
            <Outlet />
        </div>
    )
}

export default DashboardNoMenuLayout;