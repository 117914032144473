import React, { useEffect, useState } from 'react';

import ButtonSlider from 'components/common/buttonSlider';
import RadioButton from 'components/common/radioButton';
import InputTextArea from 'components/common/inputTextArea';
import ButtonToggle from 'components/common/buttonToggle';
import ButtonText from 'components/common/buttonText';
import ToggleText from 'components/common/toggleText';
import InputText from 'components/common/inputText/inputText';
import ParkingSpotMap from './parkingSpotMap';

import { ReactComponent as CloseIcon } from 'assets/icons/ico_close.svg';
import { ReactComponent as GarageIcon } from 'assets/icons/ico_garage_black.svg';
import { ReactComponent as SunIcon } from 'assets/icons/ico_sun.svg';
import { ReactComponent as ElectricityIconBlack } from 'assets/icons/ico_electricity_black.svg';
import { ReactComponent as ElectricityIconWhite } from 'assets/icons/ico_electricity_white.svg';
import { ReactComponent as FenceIconBlack } from 'assets/icons/ico_fence_black.svg';
import { ReactComponent as FenceIconWhite } from 'assets/icons/ico_fence_white.svg';
import { getToken } from 'context/auth';
import { updateParkingSpot } from 'api/locations';
import { useMessages } from 'context/messages';
import { useTranslation } from 'react-i18next';
import CollapsibleContainer from 'components/common/collapsibleContainer/collapsibleContainer';
import InputNumber from "../../common/inputNumber";

const EditParkingSpotModal = ({ parkingSpotToEdit, setParkingSpotToEdit, display, setDisplay, updateParkingSpotInLists, parkingArea }) => {
    const [showDescription, setShowDescription] = useState(parkingSpotToEdit?.description);
    const [showFloor, setShowFloor] = useState(parkingSpotToEdit?.floor != null);
    const [isUpdating, setIsUpdating] = useState();
    const { showToast } = useMessages();
    const { t } = useTranslation();

    const closeModal = () => {
        setDisplay(false);
    }

    const handleOverlayOnClick = (e) => {
        closeModal();
        e.stopPropagation();
    }

    const handleUpdateParkingSpot = () => {
        const update = async () => {
            if (isUpdating) {
                return;
            }

            setIsUpdating(true);

            try {
                const token = await getToken();
                const result = await updateParkingSpot(token, parkingSpotToEdit);

                if (result.error) {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    updateParkingSpotInLists(result);
                    closeModal();
                    showToast(t('editParkingSpotModal.parkingSpotUpdatedTitle'), t('editParkingSpotModal.parkingSpotUpdatedDescription'), 'success');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsUpdating(false);
        }

        update();
    }

    useEffect(() => {
        if (display) {
            document.body.classList.add('overflow-hidden');
            setShowFloor(parkingSpotToEdit?.floor != null);
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [display]);

    if (!display || !parkingSpotToEdit) {
        return (null);
    }

    return (
        <>
            {/* Overlay */}
            <div className="fixed h-screen inset-0 z-50 bg-black bg-opacity-60" />
            {/* Modal */}
            <div onClick={(e) => handleOverlayOnClick(e)} className="fixed inset-0 z-50 h-min-screen-safe justify-center overflow-y-auto px-6">
                <div className="flex justify-center pt-3 pb-3 ">
                    <div onClick={(e) => { e.stopPropagation(); }} className="flex flex-col w-full bg-white max-w-3xl rounded-lg p-6">
                        <div className="flex justify-end">
                            <button className="h-4" onClick={() => { closeModal(); }}>
                                <CloseIcon className="h-5 w-5" />
                            </button>
                        </div>
                        <div className="flex justify-center mb-6">
                            <span className="font-medium">{t('editParkingSpotModal.editParkingSpot')}</span>
                        </div>
                        <div className="flex items-center mb-6">
                            <ButtonSlider title={parkingSpotToEdit.isUnavailable ? t('editParkingSpotModal.parkingSpotDisabled') : t('editParkingSpotModal.parkingSpotActive')} isToggled={!parkingSpotToEdit.isUnavailable} onClick={() => { setParkingSpotToEdit({ ...parkingSpotToEdit, isUnavailable: !parkingSpotToEdit.isUnavailable }) }} />
                        </div>
                        <div className="flex gap-4 mb-6">
                            <div className="flex gap-2 items-center">
                                <RadioButton isChecked={!parkingSpotToEdit.isIndoor} onClick={() => { setParkingSpotToEdit({ ...parkingSpotToEdit, isIndoor: false }) }} />
                                <SunIcon />
                                <span>{t('editParkingSpotModal.outdoors')}</span>
                            </div>
                            <div className="flex gap-2 items-center">
                                <RadioButton isChecked={parkingSpotToEdit.isIndoor} onClick={() => { setParkingSpotToEdit({ ...parkingSpotToEdit, isIndoor: true }) }} />
                                <GarageIcon />
                                <span>{t('editParkingSpotModal.indoors')}</span>
                            </div>
                        </div>
                        <div className="flex flex-col mb-6">
                            <span className="font-medium pb-1">
                                {t('editParkingSpotModal.features')}
                            </span>
                            <div className="flex">
                                <ButtonToggle defaultIcon={<ElectricityIconBlack />} toggledIcon={<ElectricityIconWhite />} isToggled={parkingSpotToEdit.hasChargingPost} title={t('editParkingSpotModal.chargingPost')} onClick={() => { setParkingSpotToEdit({ ...parkingSpotToEdit, hasChargingPost: !parkingSpotToEdit.hasChargingPost }) }} />
                                <ButtonToggle defaultIcon={<FenceIconBlack />} toggledIcon={<FenceIconWhite />} isToggled={parkingSpotToEdit.isFenced} title={t('editParkingSpotModal.fenced')} onClick={() => { setParkingSpotToEdit({ ...parkingSpotToEdit, isFenced: !parkingSpotToEdit.isFenced }) }} className="ml-2" />
                            </div>
                        </div>
                        <div className="flex flex-col mb-6">
                            <ButtonSlider title={t('editParkingSpotModal.description')} isToggled={showDescription} onClick={() => { setShowDescription(!showDescription); }} />
                            <CollapsibleContainer isExpanded={showDescription}>
                                {showDescription &&
                                    <div className="mt-3">
                                        <InputTextArea value={parkingSpotToEdit.description} onChange={(value) => { setParkingSpotToEdit({ ...parkingSpotToEdit, description: value }) }} />
                                    </div>
                                }
                            </CollapsibleContainer>
                        </div>
                        <div className="flex items-center mb-6">
                            <ButtonSlider title={t('editParkingSpotModal.floor')}
                                          isToggled={showFloor}
                                          onClick={() => {
                                              const enabled = !showFloor;
                                              if (!enabled) {
                                                  setParkingSpotToEdit({ ...parkingSpotToEdit, floor: null })
                                              }
                                              setShowFloor(enabled);
                                          }} />
                            <div className='ml-3'>
                                <InputNumber value={parkingSpotToEdit.floor}
                                             disabled={!showFloor}
                                             onChange={(value) => {
                                                 setParkingSpotToEdit({ ...parkingSpotToEdit, floor: value })
                                             }}/>
                            </div>
                        </div>
                        <div className="flex flex-col mb-6">
                            <span className="font-medium">{t('editParkingSpotModal.positionSpecific')}</span>
                            <ToggleText defaultText={t('generic.no')} toggledText={t('generic.yes')} isToggled={parkingSpotToEdit.isNumbered} onClick={() => { setParkingSpotToEdit({ ...parkingSpotToEdit, isNumbered: !parkingSpotToEdit.isNumbered }) }} />

                            <CollapsibleContainer isExpanded={parkingSpotToEdit.isNumbered}>
                                {parkingSpotToEdit.isNumbered &&
                                    <div className="mt-3">
                                        <InputText value={parkingSpotToEdit.spotIdentifier} title={t('editParkingSpotModal.number')} onChange={(value) => { setParkingSpotToEdit({ ...parkingSpotToEdit, spotIdentifier: value }) }} />
                                    </div>
                                }
                            </CollapsibleContainer>

                        </div>

                        <div className="flex flex-col mb-6">
                            <span className="font-medium">{t('editParkingSpotModal.position')}</span>
                            <span className="mb-6">{t('editParkingSpotModal.positionDescription')}</span>
                            {parkingSpotToEdit &&
                                <ParkingSpotMap parkingArea={parkingArea} parkingSpot={parkingSpotToEdit} setParkingSpot={setParkingSpotToEdit} />
                            }
                        </div>
                        <div className="flex">
                            <ButtonText onClick={() => { handleUpdateParkingSpot(); }} className="w-full" isLoading={isUpdating}>
                                {t('generic.save')}
                            </ButtonText>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditParkingSpotModal;