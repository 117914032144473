import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as GarageIcon } from 'assets/icons/ico_garage_black.svg';
import { ReactComponent as ElectricityIcon } from 'assets/icons/ico_electricity_black.svg';
import { ReactComponent as FenceIcon } from 'assets/icons/ico_fence_black.svg';
import { ReactComponent as SunIcon } from 'assets/icons/ico_sun.svg';

const PermitParkingSpotItem = ({ parkingSpot, setDisplayPermitsModal, setSelectedParkingSpot }) => {
    const { t } = useTranslation();

    const handleShowPermitsModal = () => {
        setSelectedParkingSpot(parkingSpot);
        setDisplayPermitsModal(true);
    }

    if (!parkingSpot) {
        return (null);
    }

    const getPermitsButton = (parkingSpot) => {
        if (parkingSpot.numberOfActivePermits > 0) {
            return (
                <button onClick={() => {handleShowPermitsModal()}} className="flex px-6 items-center justify-center text-white rounded-full shadow h-11 bg-airpark-sea hover:bg-airpark-sea-500">
                    {parkingSpot.numberOfActivePermits} {t('permitParkingSpotsTable.permits').toLowerCase()}
                </button>
            )
        } else if (parkingSpot.numberOfMarketplacePermits > 0) {
            return (
                <button onClick={() => {handleShowPermitsModal()}} className="flex px-6 items-center justify-center rounded-full shadow h-11 border-airpark-sea border-2 bg-white text-airpark-sea hover:border-airpark-sea-500">
                    {parkingSpot.numberOfMarketplacePermits} {t('permitParkingSpotsTable.marketplacePermits').toLowerCase()}
                </button>
            )
        }

        return null;
    }

    return (
        <tr className='h-16 border-b-[1px] border-airpark-gray-300'>
            <td className='pl-6'>{parkingSpot.parkingSpotExtId}</td>
            <td className='pl-6'>{parkingSpot.isNumbered ? t('parkingArea.yes') : t('parkingArea.no')}</td>
            <td className='pl-6'>{parkingSpot.floor ?? t('parkingArea.unspecified')}</td>
            <td className='pl-6'>{parkingSpot.isNumbered ? parkingSpot.spotIdentifier : t('parkingArea.notNumbered')}</td>
            <td className='pl-6'>
                <div className="flex">
                    {parkingSpot.isIndoor &&
                        <GarageIcon className="h-6 w-6" />
                    }
                    {!parkingSpot.isIndoor &&
                        <SunIcon className="h-6 w-6" />
                    }
                    {parkingSpot.hasChargingPost &&
                        <ElectricityIcon className="h-6 w-6 ml-2" />
                    }
                    {parkingSpot.isFenced &&
                        <FenceIcon className="h-6 w-6 ml-2" />
                    }
                </div>
            </td>
            <td className='pl-6 pr-6'>
                {getPermitsButton(parkingSpot)}
            </td>
        </tr>
    );
}

export default PermitParkingSpotItem;