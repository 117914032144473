import { Outlet, useNavigate } from "react-router-dom";
import useAuth from "context/auth";
import { useEffect } from "react";
import { DashboardProvider } from "context/dashboard";
import DashboardLoading from "components/dashboard/dashboardLoading";
import AcceptTermsAndConditions from "components/dashboard/acceptTermsAndConditions";

export const DashboardLayout = () => {
  const { isAuthenticated, isFinishedCheckingIfUserIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isFinishedCheckingIfUserIsAuthenticated && !isAuthenticated) {

      if (!localStorage.getItem('redirect_path') === 'logout') {
        localStorage.setItem('redirect_path', window.location.pathname);
      } else {
        localStorage.removeItem('redirect_path');
      }
      
      navigate('/');
    }
  }, [isAuthenticated, isFinishedCheckingIfUserIsAuthenticated]);

  if (isAuthenticated) {
    return (
      <DashboardProvider>
        <DashboardLoading>
          <AcceptTermsAndConditions>
            <Outlet />
          </AcceptTermsAndConditions>
        </DashboardLoading>
      </DashboardProvider>
    )
  } else {
    return (null);
  }
};

export default DashboardLayout;