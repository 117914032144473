import { addCustomAgreement, deleteCustomAgreement, getCustomAgreement } from 'api/permits';
import ButtonText from 'components/common/buttonText';
import InputFileUpload from 'components/common/inputFileUpload';
import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as FileIcon } from 'assets/icons/ico_file.svg';

const PermitCustomAgreementPage = () => {

    const { t } = useTranslation();
    const { showToast, showModal, hideModal } = useMessages();
    const [uploadCustomAgreement, setUploadCustomAgreement] = useState({ customAgreementFile: [] });
    const [customAgreement, setCustomAgreement] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { parkingAreaExtId } = useParams();

    useEffect(() => {
        const load = async () => {
            setIsLoading(true);
            try {
                const token = await getToken();
                const result = await getCustomAgreement(token, parkingAreaExtId);
                if (result && !result.error) {
                    setCustomAgreement(result.customAgreementFileUrl);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            } finally {
                setIsLoading(false);
            }
        }

        load();
    }, [parkingAreaExtId]);

    const handleUpload = async () => {
        if (isUploading) {
            return;
        }

        setIsUploading(true);

        try {
            const token = await getToken();
            const result = await addCustomAgreement(token, uploadCustomAgreement, parkingAreaExtId);

            if (result && !result.error) {
                setCustomAgreement(result.customAgreementFileUrl);
                showToast(t('generic.successTitle'), t('generic.successDescription'), 'success');
            } else {
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        } finally {
            setIsUploading(false);
        }
    }

    const handleDelete = async () => {
        if (isDeleting) {
            return;
        }

        setIsDeleting(true);

        try {
            const token = await getToken();
            const result = await deleteCustomAgreement(token, parkingAreaExtId);

            if (result && !result.error) {
                setCustomAgreement(null);
                setUploadCustomAgreement({ customAgreementFile: [] });
                showToast(t('generic.successTitle'), t('generic.successDescription'), 'success');
            } else {
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        } finally {
            setIsDeleting(false);
        }
    }

    return (
        <>
            {isLoading &&
                <WidgetLoadingSkeleton />
            }
            {!isLoading && !customAgreement &&
                <Widget>
                    <h2 className="text-2xl font-medium pb-1">{t('customAgreementPage.title')}</h2>
                    <span className="mb-6">{t('customAgreementPage.description')}</span>
                    <InputFileUpload
                        files={uploadCustomAgreement.customAgreementFile}
                        setFiles={(files) => { setUploadCustomAgreement({ ...uploadCustomAgreement, customAgreementFile: [...files] }) }}
                        handleFileError={() => { showToast(t('permitCustomAgreementPage.fileNotValidTitle'), t('permitCustomAgreementPage.fileNotValidDescription'), 'error'); }}
                        isDocuments={true}
                        allowOnlySingleFile={true} />
                    <div className="flex mt-6 justify-center">
                        <ButtonText
                            isLoading={isUploading}
                            disabled={isUploading || !uploadCustomAgreement?.customAgreementFile?.length}
                            className='max-w-xs w-full'
                            onClick={() => { handleUpload(); }}>{t('generic.upload')}</ButtonText>
                    </div>
                </Widget>
            }
            {!isLoading && customAgreement &&
                <Widget>
                    <h2 className="text-2xl font-medium pb-1">{t('customAgreementPage.title')}</h2>
                    <span className="mb-6">{t('customAgreementPage.description')}</span>
                    <div className="flex flex-row items-center justify-center gap-x-3">
                        <FileIcon className='h-5 w-5' />
                        <a href={customAgreement} target="_blank">{t('customAgreementPage.customAgreement')}</a>
                    </div>
                    <div className="flex mt-6 justify-center">
                        <ButtonText
                            template='secondary'
                            isDelete={true}
                            isLoading={isDeleting}
                            disabled={isDeleting}
                            className='max-w-xs w-full'
                            onClick={() => {
                                showModal(
                                    t('generic.warning'),
                                    t('generic.areyousure'),
                                    t('generic.yes'),
                                    async () => {
                                        hideModal();
                                        await handleDelete();
                                    },
                                    t('generic.no'),
                                    () => { hideModal(); },
                                    'warning');
                            }}>{t('generic.remove')}</ButtonText>
                    </div>
                </Widget>
            }
        </>
    );
};

export default PermitCustomAgreementPage;