import { getToken } from "context/auth";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { getParkingArea } from "api/locations";
export const ParkingAreaContext = React.createContext({});
export const useParkingArea = () => useContext(ParkingAreaContext);

export const ParkingAreaProvider = ({ children }) => {
    const [parkingArea, setParkingArea] = useState();
    const [loading, setLoading] = useState();
    const { parkingAreaExtId } = useParams();

    useEffect(() => {
        const getData = async() => {
            const token = await getToken();
            const parkingAreaResult = await getParkingArea(token, parkingAreaExtId)
            .catch((e) => {
                console.error(e);
            });

            setParkingArea(parkingAreaResult);
            setLoading(false);
        }
        setLoading(true);
        getData();
    }, [parkingAreaExtId]);

    return (
        <ParkingAreaContext.Provider
          value={{
            parkingArea,
            loading
          }}>
          {children}
        </ParkingAreaContext.Provider>
      );
}