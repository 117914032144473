import React, { useEffect, useState } from "react";
import Widget from "components/dashboard/widget";
import { useTranslation } from "react-i18next";
import PayoutsTable from "components/pageSpecific/payouts/payoutsTable";
import { getToken } from "context/auth";
import { getPayoutsForOrganization } from "api/payouts";
import { useMessages } from "context/messages";
import { useParams } from "react-router-dom";
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";

const PayoutsPage = () => {

    const { t } = useTranslation();
    const { organizationExtId } = useParams();
    const { showToast } = useMessages();
    const [payouts, setPayouts] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        const load = async () => {
            try {
                const token = await getToken();
                const result = await getPayoutsForOrganization(token, organizationExtId);

                if(result && !result.error) {
                    setPayouts(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        
            setLoading(false);
        };

        load();
    }, [organizationExtId]);

    return (
        <div className="flex flex-col gap-y-6">
            <Widget>
                <div className="flex gap-12 justify-between items-center">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-medium mb-1">{t('payoutsPage.title')}</h2>
                        <span>{t('payoutsPage.description')}</span>
                    </div>
                </div>
            </Widget>
            {!loading &&
                <Widget>
                <div className="flex md:flex-row flex-col justify-between">
                    <h2 className="font-medium text-xl mb-6">{t('payoutsPage.payouts')}</h2>
                </div>
                <PayoutsTable payouts={payouts} />
            </Widget>
            }
            {loading &&
                <WidgetLoadingSkeleton />
            }
        </div>
    )
}

export default PayoutsPage;