import { getChargingStations } from 'api/charging';
import { getParkingArea, updateParkingArea } from 'api/locations';
import ButtonText from 'components/common/buttonText';
import DropdownSelector from 'components/common/dropdownSelector/dropdownSelector';
import InputNumber from 'components/common/inputNumber';
import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import ChargingStationsTable from 'components/pageSpecific/charging/chargingStationsTable';
import { getToken } from 'context/auth';
import { useDashboard } from 'context/dashboard';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const ChargingPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [chargingStations, setChargingStations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { showToast } = useMessages();
    const { parkingAreaExtId } = useParams();
    const [parkingArea, setParkingArea] = useState();
    const { organization } = useDashboard();
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const chargingStationsResult = await getChargingStations(token, parkingAreaExtId);
                const parkingAreaResult = await getParkingArea(token, parkingAreaExtId);

                if (chargingStationsResult.error) {
                    console.error(chargingStationsResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setChargingStations(chargingStationsResult);
                }

                if (parkingAreaResult.error) {
                    console.error(parkingAreaResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setParkingArea(parkingAreaResult);
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            } finally {
                setIsLoading(false);
            }
        }

        load();
    }, []);

    const handleSaveChargePrice = async () => {
        try {
            setIsSaving(true);

            const token = await getToken();
            const result = await updateParkingArea(token, parkingArea);

            if (result.error) {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            } else {
                showToast(t('generic.successTitle'), t('generic.successDescription'), 'success');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        } finally {
            setIsSaving(false);
        }
    }

    return (
        <>
            <Widget className="mb-6">
                <div className="flex flex-col">
                    <h2 className="text-xl font-medium mb-1">{t('chargingPage.title')}</h2>
                    <span>{t('chargingPage.description')}</span>
                </div>
            </Widget>
            {isLoading &&
                <WidgetLoadingSkeleton />
            }
            {!isLoading && chargingStations && parkingArea &&
                <>
                    <Widget>
                        <div className="flex flex-col">
                            <h2 className="text-xl font-medium mb-1">{t('chargingPage.pricingTitle')}</h2>
                            <span>{t('chargingPage.pricingDescription')}</span>
                        </div>
                        <div className="flex md:flex-row flex-col md:gap-x-3">
                            <div className="flex flex-col md:w-1/2 mt-3">
                               <DropdownSelector 
                               title={t('generic.unit')}
                               options={[{ title: `${organization.currencySymbol}/kWh` }]} />
                            </div>
                            <div className="flex flex-col md:w-1/2 mt-3">
                                <InputNumber
                                    title={t('chargingPage.priceForCharging')}
                                    value={parkingArea.chargingPriceKWh}
                                    onChange={(value) => { setParkingArea({ ...parkingArea, chargingPriceKWh: value }); }}
                                    tooltip={t('chargingPage.priceForChargingTooltip')}
                                    unit={`${organization.currencySymbol}/kWh`} />
                            </div>
                        </div>

                        <div className="flex w-full justify-center mt-6">
                            <ButtonText
                                onClick={() => { handleSaveChargePrice(); }}
                                isLoading={isSaving}
                                disabled={isSaving}
                                className="max-w-xs w-full">{t('generic.save')}
                            </ButtonText>
                        </div>
                    </Widget>
                    <Widget className="mt-6">
                        <div className="flex items-center justify-between mb-6">
                            <div className="flex flex-col">
                                <h2 className="text-xl font-medium mb-1">{t('chargingPage.addChargingStationTitle')}</h2>
                                <span>{t('chargingPage.addChargingStationDescription')}</span>
                            </div>
                            <ButtonText onClick={() => { navigate('add-charging-station'); }}>
                                {t('generic.add')}
                            </ButtonText>
                        </div>
                        <ChargingStationsTable
                            chargingStations={chargingStations}
                            setChargingStations={setChargingStations} />
                    </Widget>
                </>
            }
        </>
    );
};

export default ChargingPage;