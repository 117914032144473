import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';
import googleMapStyle from 'styles/googleMapStyle';

import { ReactComponent as LayersIcon } from 'assets/icons/ico_layers.svg';
import ButtonText from 'components/common/buttonText';

const ParkingSpotMap = ({ parkingSpot, setParkingSpot, parkingArea, disableRemoveMarkerButton }) => {
    const [libraries] = useState(['places', 'drawing']);
    const [map, setMap] = useState();
    const [center] = useState({ lat: 56, lng: 12 });
    const { t } = useTranslation();

    const onLoadGoogleMap = (mapRef) => {
        setMap(mapRef);
        mapRef.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        mapRef.setTilt(0);
    }

    /* Focus map on the parking area polygon */
    useEffect(() => {
        if (parkingArea && parkingArea.polygonCoordinates && window.google && window.google.maps && map) {
            let polygonCoordinates = [];

            parkingArea.polygonCoordinates.forEach((c) => {
                polygonCoordinates.push({ lat: c.latitude, lng: c.longitude })
            });

            var bounds = new window.google.maps.LatLngBounds();

            for (let i = 0; i < polygonCoordinates.length; i++) {
                bounds.extend(polygonCoordinates[i]);
            }

            map.fitBounds(bounds);
        }

    }, [parkingArea, window.google, map]);

    const RemoveMarkerButton = () => {
        return (
            <div className="absolute bottom-2 right-2">
                <ButtonText isSecondary={true} onClick={() => { setParkingSpot({ ...parkingSpot, latitude: null, longitude: null }) }}>
                    {t('editParkingSpotModal.removePin')}
                </ButtonText>
            </div>
        )
    }

    const ZoomInButton = () => {
        return (
            <button onClick={(e) => {
                e.preventDefault();
                const currentZoom = map.getZoom();
                map.setZoom(currentZoom + 1);
            }}
                className="flex absolute top-4 right-2 bg-white h-11 w-11 rounded-full shadow items-center justify-center font-medium text-2xl">
                <span>+</span>
            </button>
        )
    }

    const ZoomOutButton = () => {
        return (
            <button onClick={(e) => {
                e.preventDefault();
                const currentZoom = map.getZoom();
                map.setZoom(currentZoom - 1);
            }}
                className="flex absolute top-[64px] right-2 bg-white h-11 w-11 rounded-full shadow items-center justify-center font-medium text-2xl">
                <span>-</span>
            </button>
        )
    }

    const ChangeMapTypeButton = () => {
        return (
            <button onClick={(e) => {
                e.preventDefault();
                if (map.getMapTypeId() != window.google.maps.MapTypeId.SATELLITE) {
                    map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
                    map.setTilt(0);
                } else {
                    map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
                    map.setTilt(0);
                }
            }}
                className="flex absolute top-4 right-[60px] bg-white h-11 w-11 rounded-full shadow items-center justify-center font-medium text-2xl"
            ><LayersIcon /></button>
        )
    }

    return (
        <LoadScriptNext libraries={libraries} googleMapsApiKey="AIzaSyD6UTBXOfbCvJWhKlK7gKGCBYzQ9ECNu2c">
            <GoogleMap
                onClick={(e) => {
                    setParkingSpot({ ...parkingSpot, latitude: e.latLng.lat(), longitude: e.latLng.lng() });
                }}
                onLoad={onLoadGoogleMap}
                id='map'
                mapContainerStyle={{
                    width: '100%',
                    maxWidth: '100%',
                    height: '324px'
                }}
                center={center}
                zoom={4}
                options={{
                    styles: googleMapStyle,
                    disableDefaultUI: true
                }}>
                {parkingSpot.latitude && parkingSpot.longitude && parkingSpot.latitude != 0 && parkingSpot.longitude != 0 &&
                    <Marker
                        zIndex={2}
                        icon='/assets/icons/map/pin.svg'
                        position={{ lat: parkingSpot.latitude, lng: parkingSpot.longitude }} />
                }
                <ChangeMapTypeButton />
                <ZoomInButton />
                <ZoomOutButton />
                {!disableRemoveMarkerButton &&
                    <RemoveMarkerButton />
                }
            </GoogleMap>
        </LoadScriptNext>
    );
};

export default ParkingSpotMap;