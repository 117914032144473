import React, { useState, useEffect, useContext } from 'react';
import useAuth from 'context/auth';
import countries from 'helpers/countries';

export const PhoneNumberLoginContext = React.createContext({});
export const usePhoneNumberLogin = () => useContext(PhoneNumberLoginContext);

export const PhoneNumberLoginProvider = ({ children }) => {

  const { requestSmsCode, exchangeCodeSms } = useAuth();
  const [country, setCountry] = useState({ code: '', country: '', name: '' });
  const [fullPhoneNumber, setFullPhoneNumber] = useState();
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    setFullPhoneNumber(country.code + phoneNumber);
  }, [country, phoneNumber]);

  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;

    switch (userLang.substring(0, 2).toLowerCase()) {
      case "sv":
        setCountry({ code: '+46', country: 'SE', name: 'Sverige' });
        break;
      default:
        setCountry({ code: '+1', country: 'US', name: 'USA' });
        break;
    }
  }, []);

  const requestSmsCodeToVerify = async () => {
    const phoneRegex = RegExp("^\\+?[1-9]\\d{1,14}$");

    if (phoneNumber.length > 0 && phoneRegex.test(fullPhoneNumber)) {
      try {
        return await requestSmsCode(fullPhoneNumber);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  }

  const verifySmsCode = async (code) => {
    if (code && code.length == 4) {
      try {
        return await exchangeCodeSms(code);
      } catch (error) {
        console.error(error);
        throw error;
      } 
    }
  }

  return (
    <PhoneNumberLoginContext.Provider
      value={{
        country,
        phoneNumber,
        fullPhoneNumber,
        setCountry,
        setPhoneNumber,
        requestSmsCodeToVerify,
        verifySmsCode,
        countries
      }}>
      {children}
    </PhoneNumberLoginContext.Provider>
  );
};

export default usePhoneNumberLogin;