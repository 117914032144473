import { getControlFeeSettings, getParkingArea, updateControlFeeSettings } from 'api/locations';
import ButtonText from 'components/common/buttonText';
import DropdownSelector from 'components/common/dropdownSelector/dropdownSelector';
import InputNumber from 'components/common/inputNumber';
import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import { getToken } from 'context/auth';
import { useDashboard } from 'context/dashboard';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const ControlFeesSettingsPage = () => {

    const { t } = useTranslation();
    const { organization } = useDashboard();
    const { parkingAreaExtId } = useParams();
    const { showToast } = useMessages();
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState();
    const [controlFeeSettings, setControlFeeSettings] = useState();

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true);
                const token = await getToken();
                const result = await getParkingArea(token, parkingAreaExtId);

                if (result && !result.error) {
                    setControlFeeSettings(
                        {
                            controlFeeAmount: result.controlFeeAmount,
                            issueControlFeePermission: result.issueControlFeePermission
                        });
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setLoading(false);
        }

        load();
    }, [])

    const handleSave = async () => {
        try {
            setIsSaving(true);

            const token = await getToken();
            const result = await updateControlFeeSettings(token, { ...controlFeeSettings, parkingAreaExtId: parkingAreaExtId });

            if (result && !result.error) {
                setControlFeeSettings(result);
                showToast(t('controlFeesSettingsPage.controlFeeSettingsUpdatedTitle'), t('controlFeesSettingsPage.controlFeeSettingsUpdatedDescription'), 'success');
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSaving(false);
    }

    return (
        <>
            <Widget className="mt-6">
                <div className="flex flex-col">
                    <h1 className="text-xl font-medium mb-3">{t('controlFeesSettingsPage.title')}</h1>
                    <span>{t('controlFeesSettingsPage.description')}</span>
                </div>
            </Widget>
            {loading &&
                <div className="mt-6">
                    <WidgetLoadingSkeleton />
                </div>
            }
            {!loading && controlFeeSettings &&
                <Widget className="mt-6">
                    <div className="flex md:flex-row flex-col gap-3 mt-3">
                        <div className="md:w-1/2">
                            <InputNumber
                                title={t('controlFeesSettingsPage.controlFeeAmount')}
                                value={controlFeeSettings.controlFeeAmount}
                                onChange={(value) => { setControlFeeSettings({ ...controlFeeSettings, controlFeeAmount: value }) }}
                                className='min-w-[240px]'
                                unit={organization.currencySymbol} />
                        </div>
                        <div className="md:w-1/2">
                            <DropdownSelector
                                title={t('controlFeesSettingsPage.whoIsAllowedToIssueControlFees')}
                                defaultValue={controlFeeSettings.issueControlFeePermission}
                                onChange={(value) => { setControlFeeSettings({ ...controlFeeSettings, issueControlFeePermission: value }) }}
                                options={[
                                    { title: t('controlFeesSettingsPage.everyone'), value: 'everyone' },
                                    { title: t('controlFeesSettingsPage.communityMembers'), value: 'community' },
                                    { title: t('controlFeesSettingsPage.noone'), value: 'noone' }
                                ]} />
                        </div>
                    </div>
                    <div className="flex w-full justify-center mt-6">
                        <ButtonText
                            onClick={() => { handleSave(); }}
                            isLoading={isSaving}
                            className="max-w-xs w-full">{t('generic.save')}
                        </ButtonText>
                    </div>
                </Widget>
            }

        </>
    );
};

export default ControlFeesSettingsPage;