import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import ButtonText from "components/common/buttonText";
import InputDate from "components/common/inputDate";
import InputDateAndTime from "components/common/inputDateAndTime/inputDateAndTime";
import ToggleText from "components/common/toggleText";
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import DropdownSelector from "components/common/dropdownSelector/dropdownSelector";
import InputNumber from "components/common/inputNumber";
import InputText from "components/common/inputText/inputText";
import InputTime from "components/common/inputTime";
import SelectParkingSpotsModal from "components/dashboard/selectParkingSpotsModal";
import ButtonSlider from "components/common/buttonSlider";
import CollapsibleContainer from "components/common/collapsibleContainer";
import InputPhoneNumber from "components/common/inputPhoneNumber";
import Alert from "components/common/alert";

import { getParkingSpots } from "api/locations";
import { createMarketplacePermit, createPermit, getPermit, updatePermit } from "api/permits";

import { getToken } from "context/auth";
import { useDashboard } from "context/dashboard";
import { useMessages } from "context/messages";

import { format } from "date-fns";
import BackButton from "components/dashboard/backButton";
import { getCommunitiesForParkingArea } from "api/communities";
import { getPermitQueueItem } from "api/permitQueue";
import { PermitQueueType } from "../../helpers/consts";

const PermitPage = ({ isMarketplace }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { parkingAreaExtId, permitExtId, permitQueueItemExtId } = useParams();

    const { organization } = useDashboard();
    const { showToast, showBetweenPagesMessage } = useMessages();

    const [permit, setPermit] = useState({});
    const [isLoading, setIsLoading] = useState();
    const [isSaving, setIsSaving] = useState();
    const [parkingSpots, setParkingSpots] = useState();
    const [displayParkingSpotsModal, setDisplayParkingSpotsModal] = useState();
    const [communities, setCommunities] = useState();
    const [permitQueueItem, setPermitQueueItem] = useState();

    useEffect(() => {
        const loadPermit = async () => {

            setIsLoading(true);

            try {
                const token = await getToken();

                /* Always get parking spots for the parking area */
                const parkingSpotsResult = await getParkingSpots(token, parkingAreaExtId);

                if (parkingSpotsResult && !parkingSpotsResult.error) {
                    setParkingSpots(parkingSpotsResult.filter((parkingSpot) => { return parkingSpot.isNumbered === true }));
                } else {
                    console.error(parkingSpotsResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                const communitiesResult = await getCommunitiesForParkingArea(token, parkingAreaExtId);

                if (communitiesResult && !communitiesResult.error) {
                    setCommunities(communitiesResult);
                } else {
                    console.error(communitiesResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                let permitQueueItem;

                if (permitQueueItemExtId) {
                    permitQueueItem = await getPermitQueueItem(token, permitQueueItemExtId);

                    if (permitQueueItem && !permitQueueItem.error) {
                        setPermitQueueItem(permitQueueItem);
                    } else {
                        console.error(permitQueueItem);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    }
                }

                /* If we don't have a permitExtId then we are adding a new permit, default to all parking spots */
                if (!permitExtId) {
                    const description = (permitQueueItem?.firstName || permitQueueItem?.lastName) ? permitQueueItem.firstName + ' ' + permitQueueItem.lastName : '';

                    const isSpecificSpotsPermit = permitQueueItem?.permitQueueType === PermitQueueType.AnyNumberedSpot;

                    setPermit({
                        phone: '',
                        phoneCountryNumberPrefix: '',
                        isSpecificParkingSpots: isSpecificSpotsPermit,
                        parkingSpots: [],
                        description,
                        isRecurring: false,
                        parkingAreaExtId: parkingAreaExtId,
                        price: null,
                        unit: 'month',
                        startDateTime: new Date(),
                        endDateTime: null,
                        monday: true,
                        tuesday: true,
                        wednesday: true,
                        thursday: true,
                        friday: true,
                        saturday: true,
                        sunday: true,
                        timeFrom: '00:00:00',
                        timeTo: '23:59:00',
                        isSublettingAllowed: true,
                        isInvoicedByAirPark: true,
                        noEndDate: true,
                        isCommunity: false,
                        isMarketplace: isMarketplace ?? false,
                        addUserToCommunities: false,
                    });
                } else {
                    const result = await getPermit(token, permitExtId);

                    if (result && !result.error) {
                        setPermit({
                            ...result,
                            startDateTime: new Date(result.startDateTime),
                            endDateTime: result.endDateTime ? new Date(result.endDateTime) : null,
                            noEndDate: result.endDateTime == null,
                            parkingSpots: result.parkingSpots ?? [],
                            parkingSpotExtIds: result.parkingSpots?.map((parkingSpot) => { return parkingSpot.parkingSpotExtId }),
                            isInvoicedByAirPark: !result.isPaymentHandledManually,
                            isSpecificParkingSpots: result.parkSpotExtIds?.length > 0,
                            isMarketplace: isMarketplace,
                            addUserToCommunities: result.includeUserInParkingAreaCommunities
                        });

                    } else {
                        console.error(result);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    }
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        loadPermit();
    }, [parkingAreaExtId, permitExtId]);

    useEffect(() => {
        if (permit.parkingSpots && permit.parkingSpots.length > 0) {

            const parkingSpotExtIds = permit
                .parkingSpots
                .map((parkingSpot) => { return parkingSpot.parkingSpotExtId })

            setPermit(
                {
                    ...permit,
                    isSpecificParkingSpots: true,
                    parkingSpotExtIds: parkingSpotExtIds
                });
        }
    }, [permit.parkingSpots])

    const handleToggleSpecificSpots = () => {
        if (permit.isSpecificParkingSpots) {
            setPermit({ ...permit, isSpecificParkingSpots: !permit.isSpecificParkingSpots, parkingSpots: [], parkingSpotExtIds: null })
        } else {
            setPermit({ ...permit, isSpecificParkingSpots: !permit.isSpecificParkingSpots })
        }
    }

    const handleToggleSpecificDates = () => {
        setPermit({ ...permit, isRecurring: !permit.isRecurring, monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, timeFrom: '00:00:00', timeTo: '23:59:00' })
    }

    const handleToggleNoEndDate = () => {
        if (permit.noEndDate) {
            setPermit({ ...permit, noEndDate: false, endDateTime: new Date() })
        } else {
            setPermit({ ...permit, noEndDate: true, endDateTime: null })
        }
    }

    const handleSave = () => {
        if (isSaving) {
            return;
        }

        if (!isMarketplace && !permitExtId && !permitQueueItemExtId && (!permit.phone || !permit.phoneCountryNumberPrefix)) {
            showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error');
            return;
        }

        if (permit.isInvoicedByAirPark && (!permit.unit || !permit.price)) {
            showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error');
            return;
        }

        const save = async () => {
            setIsSaving(true);

            try {
                const token = await getToken();

                if (!permitExtId) {
                    let result;
                    const createPermitRequestDto = {
                        description: permit.description,
                        parkingAreaExtId: parkingAreaExtId,
                        parkingSpotExtIds: permit.parkingSpotExtIds,
                        isSublettingAllowed: permit.isSublettingAllowed,
                        isPaymentHandledManually: !permit.isInvoicedByAirPark,
                        monday: permit.monday,
                        tuesday: permit.tuesday,
                        wednesday: permit.wednesday,
                        thursday: permit.thursday,
                        friday: permit.friday,
                        saturday: permit.saturday,
                        sunday: permit.sunday,
                        timeFrom: permit.timeFrom,
                        timeTo: permit.timeTo,
                        startDateTime: `${format(permit.startDateTime, 'yyyy-MM-dd')}T${format(permit.startDateTime, 'HH:mm')}`,
                        endDateTime: permit.endDateTime ? `${format(permit.endDateTime, 'yyyy-MM-dd')}T${format(permit.endDateTime, 'HH:mm')}` : null,
                        price: permit.price,
                        unit: permit.unit,
                        phone: permit.phone,
                        phoneCountryNumberPrefix: permit.phoneCountryNumberPrefix,
                        isRecurring: permit.isRecurring,
                        isCommunity: permit.isCommunity,
                        isMarketplace: isMarketplace,
                        includeUserInParkingAreaCommunities: permit.addUserToCommunities,
                        permitQueueItemExtId: permitQueueItemExtId
                    };

                    if (isMarketplace) {
                        result = await createMarketplacePermit(token, createPermitRequestDto);
                    } else {
                        result = await createPermit(token, createPermitRequestDto);
                    }

                    if (result && !result.error) {
                        if (isMarketplace) {
                            navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId}/created-marketplace-permit/${result.permitExtId}`);
                        } else {
                            showBetweenPagesMessage(
                                t('createPermitPage.permitCreatedTitle'),
                                t('createPermitPage.permitCreatedDescription', { phone: permit.phone }),
                                () => { navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId}/permits`) },
                                'success');
                        }

                    } else {
                        console.error(result);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    }
                } else {
                    const result = await updatePermit(token, {
                        permitExtId: permit.permitExtId,
                        description: permit.description,
                        parkingAreaExtId: parkingAreaExtId,
                        parkingSpotExtIds: permit.parkingSpotExtIds,
                        isSublettingAllowed: permit.isSublettingAllowed,
                        isPaymentHandledManually: !permit.isInvoicedByAirPark,
                        monday: permit.monday,
                        tuesday: permit.tuesday,
                        wednesday: permit.wednesday,
                        thursday: permit.thursday,
                        friday: permit.friday,
                        saturday: permit.saturday,
                        sunday: permit.sunday,
                        timeFrom: permit.timeFrom,
                        timeTo: permit.timeTo,
                        startDateTime: `${format(permit.startDateTime, 'yyyy-MM-dd')}T${format(permit.startDateTime, 'HH:mm')}`,
                        endDateTime: permit.endDateTime ? `${format(permit.endDateTime, 'yyyy-MM-dd')}T${format(permit.endDateTime, 'HH:mm')}` : null,
                        price: permit.price,
                        unit: permit.unit,
                        isRecurring: permit.isRecurring,
                        isMarketplace: permit.isMarketplace,
                        isCommunity: permit.isCommunity,
                        includeUserInParkingAreaCommunities: permit.addUserToCommunities
                    });

                    if (result && !result.error) {

                        if (isMarketplace) {
                            navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId}/updated-marketplace-permit/${result.permitExtId}`);
                        } else {
                            showBetweenPagesMessage(
                                t('createPermitPage.permitUpdatedTitle'),
                                t('createPermitPage.permitUpdatedDescription'),
                                () => { navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId}/permits${isMarketplace ? '/marketplace' : ''}`) },
                                'success');
                        }
                    } else {
                        console.error(result);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    }
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
            setIsSaving(false);
        }

        save();
    }

    return (
        <>
            <BackButton />
            <div className="flex flex-col m-auto max-w-[448px] w-full">
                {!isLoading && permit && organization && parkingSpots &&
                    <>
                        <div className="flex flex-col">
                            <h1 className="mt-14 text-center text-2xl font-medium mb-8">{permitExtId ? t('createPermitPage.editTitle') : isMarketplace ? t('createPermitPage.createTitle') : t('createPermitPage.issueTitle')}</h1>

                            {!permitExtId && !isMarketplace && !permitQueueItemExtId &&
                                <div className="flex flex-col mb-3 gap-3">
                                    <InputPhoneNumber
                                        title={t('createPermitPage.phoneNumber')}
                                        defaultCountryCode={organization.countryCode}
                                        defaultNumber={permit.phone}
                                        phoneNumber={permit.phone}
                                        setPhoneNumber={(phoneNumber, countryCode) => { setPermit({ ...permit, phone: phoneNumber, phoneCountryNumberPrefix: countryCode }) }}
                                        tooltip={t('createPermitPage.phoneNumberTooltip')} />
                                </div>
                            }

                            {permitQueueItem &&
                                <div className="flex flex-col mb-3 gap-3">
                                    <InputText value={permitQueueItem.phone} disabled={true} title={t('createPermitPage.phoneNumber')} />
                                </div>
                            }

                            {permitQueueItem?.description &&
                                <div className="flex flex-col mb-3 gap-3">
                                    <InputText value={permitQueueItem.description} disabled={true} title={t('createPermitPage.specialRequests')} />
                                </div>
                            }

                            <div className="mb-3">
                                <InputText
                                    placeHolder='Jane Doe, Acme Company'
                                    title={t('createPermitPage.description')}
                                    value={permit.description} tooltip={t('createPermitPage.descriptionTooltip')}
                                    onChange={(value) => { setPermit({ ...permit, description: value }) }} />
                            </div>
                            
                            <div className="flex flex-col mb-3 gap-3">
                                <ToggleText
                                    onClick={() => { handleToggleSpecificSpots(); }}
                                    title={t('createPermitPage.chooseParkingSpot')}
                                    defaultText={t('createPermitPage.allParkingSpots')}
                                    toggledText={t('createPermitPage.specificParkingSpots')}
                                    isToggled={permit.isSpecificParkingSpots} />

                                <CollapsibleContainer isExpanded={permit.isSpecificParkingSpots}>
                                    <div className="flex flex-col gap-y-3">
                                        {permit.parkingSpots?.length > 0 &&
                                            <Alert
                                                template='info'
                                                title={t('createPermitPage.parkingSpots')}
                                                description={permit.parkingSpots
                                                    .map((parkingSpot) => { return parkingSpot.spotIdentifier })
                                                    .sort((a, b) => { return a - b })
                                                    .join(', ')}
                                            />
                                        }
                                        <ButtonText className="w-full mb-3" onClick={() => { setDisplayParkingSpotsModal(true); }} isSecondary={permit.parkingSpots?.length > 0}>
                                            {permit.parkingSpots?.length > 0 ? t('generic.change') : t('generic.choose')}
                                        </ButtonText>

                                    </div>
                                </CollapsibleContainer>
                            </div>

                            <div className="flex flex-col mb-3">
                                <ToggleText
                                    onClick={() => { handleToggleSpecificDates(); }}
                                    isToggled={permit.isRecurring}
                                    title={t('createPermitPage.period')}
                                    defaultText={t('createPermitPage.betweenDates')}
                                    toggledText={t('createPermitPage.specificDays')}
                                    tooltip={t('createPermitPage.tooltipPeriod')} />

                                {!permit.isRecurring &&
                                    <div className="flex flex-col gap-3 mt-3">
                                        <div className="flex flex-col">
                                            <InputDateAndTime title={t('createPermitPage.from')} value={permit.startDateTime} onChange={(date) => { setPermit({ ...permit, startDateTime: date }) }} />
                                        </div>
                                        <ButtonSlider title={t('createPermitPage.noEndDate')} isToggled={permit.noEndDate} onClick={() => { handleToggleNoEndDate(); }}></ButtonSlider>

                                        <CollapsibleContainer isExpanded={!permit.noEndDate}>
                                            <div className="flex flex-col">
                                                <InputDateAndTime title={t('createPermitPage.to')} value={permit.endDateTime ?? new Date()} onChange={(date) => { setPermit({ ...permit, endDateTime: date }) }} />
                                            </div>
                                        </CollapsibleContainer>
                                    </div>
                                }

                                {permit.isRecurring &&
                                    <div className="flex flex-col gap-3 mt-3 mb-3">
                                        <div className="flex flex-col">
                                            <InputDate title={t('createPermitPage.from')} value={permit.startDateTime} onChange={(value) => { setPermit({ ...permit, startDateTime: value }) }} className="w-full" />
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="font-medium pb-1">{t('createPermitPage.onWeekdays')}</span>
                                            <div className="flex justify-between">
                                                <ButtonWeekday title={t('dayShortNames.monday')} isSelected={permit.monday} onClick={() => { setPermit({ ...permit, monday: !permit.monday }) }} />
                                                <ButtonWeekday title={t('dayShortNames.tuesday')} isSelected={permit.tuesday} onClick={() => { setPermit({ ...permit, tuesday: !permit.tuesday }) }} />
                                                <ButtonWeekday title={t('dayShortNames.wednesday')} isSelected={permit.wednesday} onClick={() => { setPermit({ ...permit, wednesday: !permit.wednesday }) }} />
                                                <ButtonWeekday title={t('dayShortNames.thursday')} isSelected={permit.thursday} onClick={() => { setPermit({ ...permit, thursday: !permit.thursday }) }} />
                                                <ButtonWeekday title={t('dayShortNames.friday')} isSelected={permit.friday} onClick={() => { setPermit({ ...permit, friday: !permit.friday }) }} />
                                                <ButtonWeekday title={t('dayShortNames.saturday')} isSelected={permit.saturday} onClick={() => { setPermit({ ...permit, saturday: !permit.saturday }) }} />
                                                <ButtonWeekday title={t('dayShortNames.sunday')} isSelected={permit.sunday} onClick={() => { setPermit({ ...permit, sunday: !permit.sunday }) }} />
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="font-medium pb-1">{t('createPermitPage.between')}</span>
                                            <div className="flex md:flex-row flex-col">
                                                <InputTime value={permit.timeFrom} className="w-full md:mr-1" onChange={(value) => { setPermit({ ...permit, timeFrom: value + ':00' }) }} />
                                                <InputTime value={permit.timeTo} className="w-full md:ml-1" onChange={(value) => { setPermit({ ...permit, timeTo: value + ':00' }) }} />
                                            </div>
                                        </div>
                                        <CollapsibleContainer isExpanded={!permit.noEndDate}>
                                            <div className="flex flex-col">
                                                <InputDate title={t('createPermitPage.to')} value={permit.endDateTime ?? new Date()} onChange={(value) => { setPermit({ ...permit, endDateTime: value }) }} className="w-full" />
                                            </div>
                                        </CollapsibleContainer>
                                        <ButtonSlider title={t('createPermitPage.noEndDate')} isToggled={permit.noEndDate} onClick={() => { handleToggleNoEndDate(); }}></ButtonSlider>
                                    </div>
                                }
                            </div>

                            {isMarketplace &&
                                <>
                                    <hr className="w-full mt-4 mb-6 border-airpark-gray-400" />
                                    <div className="flex flex-col mb-3">
                                        <DropdownSelector
                                            title={t('createPermitPage.marketplaceAvailability')}
                                            options={[
                                                { title: t('createPermitPage.everyone'), value: 'everyone' },
                                                { title: t('createPermitPage.community'), value: 'community' }]}
                                            defaultValue={permit.isCommunity ? 'community' : 'everyone'}
                                            onChange={(value) => { setPermit({ ...permit, isCommunity: value == 'community' }) }}
                                            tooltip={t('createPermitPage.marketplacePermit')} />
                                    </div>
                                </>
                            }

                            <hr className="w-full mb-6 border-airpark-gray-400" />

                            <div className="flex flex-col">
                                <ButtonSlider
                                    title={t('createPermitPage.isSublettingAllowed')}
                                    onClick={() => { setPermit({ ...permit, isSublettingAllowed: !permit.isSublettingAllowed }) }}
                                    isToggled={permit.isSublettingAllowed}
                                    tooltip={isMarketplace ? t('createPermitPage.isSublettingAllowedMarketplaceDescription') : t('createPermitPage.isSublettingAllowedDescription')} />

                                {!isMarketplace &&
                                    <CollapsibleContainer isExpanded={permit.isSublettingAllowed}>
                                        <div className="mt-3">
                                            <DropdownSelector
                                                title={t('createPermitPage.canBeSublettedTo')}
                                                options={[
                                                    { title: t('createPermitPage.everyone'), value: 'everyone' },
                                                    { title: t('createPermitPage.community'), value: 'community' }]}
                                                defaultValue={permit.isCommunity ? 'community' : 'everyone'}
                                                onChange={(value) => { setPermit({ ...permit, isCommunity: value == 'community' }) }} />
                                        </div>
                                    </CollapsibleContainer>
                                }
                            </div>

                            <hr className="w-full mt-6 mb-6 border-airpark-gray-400" />

                            {!permitExtId &&
                                <>
                                    <div className="flex flex-col">
                                        <ButtonSlider
                                            title={t('createPermitPage.addUserToCommunity')}
                                            onClick={() => { setPermit({ ...permit, addUserToCommunities: !permit.addUserToCommunities }) }}
                                            isToggled={permit.addUserToCommunities}
                                            tooltip={t('createPermitPage.addUserToCommunityTooltip')}
                                        />
                                        {permit.addUserToCommunities && communities?.length === 0 &&
                                            <div className="flex flex-col mt-3">
                                                <Alert
                                                    template="warning"
                                                    title={t('createPermitPage.noCommunitiesFoundTitle')}
                                                    description={t('createPermitPage.noCommunitiesFoundDescription')} />
                                            </div>
                                        }
                                        <CollapsibleContainer isExpanded={permit.addUserToCommunities && communities?.length > 0}>
                                            <div className="flex flex-col mt-3">
                                                <Alert
                                                    template="info"
                                                    title={t('createPermitPage.userWillBeAddedToCommunitiesTitle')}
                                                    description={t('createPermitPage.userWillBeAddedToCommunitiesDescription', { communities: communities.map((community, index) => { return community.name }).join(', ') })} />
                                            </div>
                                        </CollapsibleContainer>
                                    </div>

                                    <hr className="w-full mt-6 mb-6 border-airpark-gray-400" />
                                </>
                            }
                            
                            <div className="flex flex-col mb-6 gap-3">
                                <ToggleText
                                    title={t('createPermitPage.invoicedBy')}
                                    defaultText={t('generic.airPark')}
                                    toggledText={t('createPermitPage.externally')}
                                    isToggled={!permit.isInvoicedByAirPark}
                                    onClick={() => { setPermit({ ...permit, isInvoicedByAirPark: !permit.isInvoicedByAirPark, unit: 'month' }) }}
                                    tooltip={t('createPermitPage.invoicedByTooltip')}></ToggleText>

                                {permit.isInvoicedByAirPark &&
                                    <>
                                        <DropdownSelector
                                            title={t('createPermitPage.price')}
                                            options={[
                                                { title: `${organization.currencySymbol}/${t('priceUnit.month')}`, value: 'month' },
                                                { title: `${organization.currencySymbol}/${t('priceUnit.onetime')}`, value: 'onetime' }]}
                                            defaultValue={permit.unit}
                                            onChange={(value) => { setPermit({ ...permit, unit: value }) }} />
                                        <InputNumber value={permit.price} unit={`${organization.currencySymbol}/${t(`priceUnit.${permit.unit}`)}`} onChange={(value) => { setPermit({ ...permit, price: value }) }} />
                                    </>
                                }
                                {!permit.isInvoicedByAirPark &&
                                    <Alert
                                        title={t('createPermitPage.invoicedExternallyTitle')}
                                        description={t('createPermitPage.invoicedExternallyDescription')}
                                        template='info' />
                                }
                            </div>

                            <ButtonText isLoading={isSaving} onClick={() => { handleSave(); }}>{permitExtId ? t('generic.update') : isMarketplace ? t('generic.create') : t('createPermitPage.issue')}</ButtonText>
                        </div>
                        <SelectParkingSpotsModal
                            display={displayParkingSpotsModal}
                            setDisplay={(value) => { setDisplayParkingSpotsModal(value); }}
                            parkingSpots={parkingSpots}
                            selectedParkingSpots={permit.parkingSpots?.filter((parkingSpot) => { return parkingSpot.isNumbered === true })}
                            setSelectedParkingSpots={(value) => { setPermit({ ...permit, parkingSpots: value }) }}
                            isSingleSelectionOnly={false} />
                    </>
                }
                {isLoading &&
                    <WidgetLoadingSkeleton />
                }
            </div>
        </>);
};


const ButtonWeekday = ({ title, isSelected, onClick }) => {

    const className = isSelected ?
        'flex h-12 w-12 rounded-lg bg-airpark-green hover:bg-airpark-green-400 items-center justify-center text-white' :
        'flex h-12 w-12 rounded-lg border-[1px] border-airpark-gray-400 items-center justify-center hover:border-airpark-gray-500';

    return (
        <button className={className} onClick={() => { onClick(); }}>
            <span>{title}</span>
        </button>
    );
}

export default PermitPage; 