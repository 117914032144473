import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from 'context/auth';
import { MessagesProvider } from 'context/messages';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
      <MessagesProvider>
          <App />
      </MessagesProvider>
    </AuthProvider>
);