import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createCommunity } from 'api/communities';

import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';

import ButtonText from 'components/common/buttonText';
import InputFileUpload from 'components/common/inputFileUpload';
import InputText from 'components/common/inputText';
import InputTextArea from 'components/common/inputTextArea';
import BackButton from 'components/dashboard/backButton';

const CreateCommunityPage = () => {
    const [isSaving, setIsSaving] = useState();
    const [community, setCommunity] = useState({ name: '', description: '', images: [] });

    const navigate = useNavigate();
    const { showToast } = useMessages();
    const { organizationExtId, parkingAreaExtId } = useParams();
    const { t } = useTranslation();

    const handleCreateCommunity = async () => {

        setIsSaving(true);

        try {
            var token = await getToken();
            const result = await createCommunity(token, { ...community, parkingAreaExtId: parkingAreaExtId });

            if (result && !result.error) {
                navigate(`/${organizationExtId}/parking-areas/${parkingAreaExtId}/community`);
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSaving(false);
    }

    return (
        <>
            <BackButton />
            <div className="flex flex-col m-auto max-w-[448px] w-full">
                <div className="flex flex-col">
                    <h1 className="mt-14 text-center text-2xl font-medium mb-8">{t('createCommunityPage.title')}</h1>
                    <InputText 
                        value={community.name} 
                        onChange={(value) => { setCommunity({ ...community, name: value }); }} 
                        className="mb-3" title={t('createCommunityPage.name')} />
                    <InputTextArea 
                        value={community.description} 
                        onChange={(value) => { setCommunity({ ...community, description: value }); }} 
                        className="mb-3" title={t('createCommunityPage.description')} />
                    <InputFileUpload
                        title={t('createCommunityPage.photos')}
                        tooltip={t('createCommunityPage.photosTooltip')}
                        files={community.images}
                        setFiles={(files) => { setCommunity({ ...community, images: files }) }}
                        allowOnlySingleFile={true} />
                    <ButtonText 
                        className="mt-6" 
                        onClick={() => { handleCreateCommunity(); }} 
                        isLoading={isSaving}>
                        {t('generic.create')}
                    </ButtonText>
                </div>
            </div>
        </>
    );
};

export default CreateCommunityPage;