import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRight } from 'assets/icons/ico_arrow_right.svg';
import { useMessages } from 'context/messages';
import Button from 'components/common/button';

const EmailLogin = () => {
    const { t } = useTranslation();
    const { showToast } = useMessages();
    const [isLoading, setIsLoading] = useState();
    const [email, setEmail] = useState();

    const handleEmailLogin = () => {
        if (!validateEmail(email)) {
            showToast(t('generic.errorTitle'), t('loginPage.emailIsNotValid'), 'error');
        } else {
            setIsLoading(true);
            window.location.href = `https://auth.airpark.app/authorize?audience=https://logic.airpark.app&scope=offline_access email&response_type=code&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${window.location.origin}/authentication-callback&login_hint=${email}`;
        }
    }

    const handleEmailSignup = () => {
        setIsLoading(true);
        window.location.href = `https://auth.airpark.app/authorize?audience=https://logic.airpark.app&scope=offline_access email&response_type=code&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${window.location.origin}/authentication-callback&screen_hint=signup`;
    }

    const validateEmail = (emailToValidate) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailToValidate)) {
            return true;
        } else {
            return false;
        }
    }

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleEmailLogin();
        }
    }

    return (
        <>
            <div className="flex flex-col">
                <input
                    type="email"
                    value={email ?? ''}
                    onChange={(e) => { setEmail(e.target.value); }}
                    onKeyDown={(e) => { handleEnterKeyPress(e); }}
                    autoComplete="email" placeholder={t('generic.email')}
                    className="w-full h-11 bg-white/[0.2] text-base align-middle rounded-lg placeholder-white/[0.6] p-2 focus:outline-0 text-white" />
            </div>
            <div className="flex mt-6 justify-between items-center">
                <span className="text-white text-sm mr-3 w-2/3">{t('loginPage.byLoggingInYouApprove')} <a href={t('generic.termsAndConditionsUrl')} target="_blank" className="text-airpark-green">{t('loginPage.conditions')}</a></span>
                <Button onClick={() => { handleEmailLogin(); }} loading={isLoading}>
                    <ArrowRight />
                </Button>
            </div>
            <div className="flex justify-end mt-6">
                <a href="" onClick={(e) => { e.preventDefault(); handleEmailSignup(); }} className="text-white opacity-70 text-sm">
                    {t('loginPage.noAccountSignup')}
                </a>
            </div>
        </>
    );
};

export default EmailLogin;