import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ico_arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ico_arrow_right.svg';
import { ReactComponent as MapAddressIcon } from 'assets/icons/ico_map_address.svg';

import Button from "components/common/button";
import CreateParkingAreaMap from "components/dashboard/parkingAreaMap";

import { useMessages } from "context/messages";
import { useDashboard } from "context/dashboard";
import StepIndicator from "./stepIndicator";
import InputText from "components/common/inputText/inputText";
import CollapsibleContainer from "components/common/collapsibleContainer";

const AreaView = ({ parkingArea, setParkingArea }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { organization } = useDashboard();
    const { showToast } = useMessages();
    const [showAddressFields, setShowAddressFields] = useState();
    const [friendlyAddress, setFriendlyAddress] = useState();

    useEffect(() => {
        if (parkingArea.address && parkingArea.city && parkingArea.zipCode) {
            setFriendlyAddress(`${parkingArea.address}, ${parkingArea.zipCode} ${parkingArea.city}`);
        }
    }, [parkingArea.address, parkingArea.city, parkingArea.zipCode]);

    const handleParkingAreaNameChange = (value) => {
        setParkingArea({ ...parkingArea, name: value });
    }

    const handleStreetAddressChange = (value) => {
        setParkingArea({ ...parkingArea, address: value });
    }

    const handleCityChange = (value) => {
        setParkingArea({ ...parkingArea, city: value });
    }

    const handleZipCodeChange = (value) => {
        setParkingArea({ ...parkingArea, zipCode: value });
    }

    const handleCountryChange = (value) => {
        setParkingArea({ ...parkingArea, country: value });
    }

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (parkingArea.name && parkingArea.polygonCoordinates && parkingArea.polygonCoordinates.length > 2 && parkingArea.country && parkingArea.city && parkingArea.address) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }

        if (!parkingArea.country) {
            setFriendlyAddress('');
        }

    }, [parkingArea])

    return (
        <>
            <button className="flex p-6 absolute top-12 left-12 md:visible invisible" onClick={(e) => { e.preventDefault(); navigate(`/${organization.organizationExtId.toLowerCase()}/parking-areas`); }}><ArrowLeftIcon /></button>
            <div className="flex flex-col m-auto max-w-[768px] w-full">
                <h1 className="mt-14 text-center text-2xl font-medium mb-12">{t('createParkingArea.basics')}</h1>
                <div className="flex flex-col md:flex-row">
                    <div className="flex flex-col w-full md:w-1/2 md:mr-2">
                        <InputText title={t('createParkingArea.name')} value={parkingArea.name} onChange={(value) => { handleParkingAreaNameChange(value) }} />
                    </div>
                </div>

                <div className="flex flex-col mt-6">
                    <p className="text-base font-medium pb-1">{t('createParkingArea.position')}</p>
                    <p className="text-base font-base mb-3">{t('createParkingArea.positionDescription')}</p>
                    <CreateParkingAreaMap parkingArea={parkingArea} setParkingArea={setParkingArea} />
                </div>
                <div className="flex flex-col w-full mt-6">
                    <p className="text-base font-medium mb-1">{t('createParkingArea.address')}</p>
                    <div className="flex flex-row items-center">
                        <MapAddressIcon />
                        <p className="ml-3">
                            {!friendlyAddress &&
                                t('createParkingArea.noAddress')
                            }
                            {friendlyAddress &&
                                friendlyAddress
                            }
                        </p>
                    </div>
                    {parkingArea.polygonCoordinates && parkingArea.country &&
                        <a onClick={(e) => { e.preventDefault(); setShowAddressFields(true); }} className="text-airpark-green mt-1" href="#">{t('createParkingArea.changeAddress')}</a>
                    }
                    <CollapsibleContainer isExpanded={showAddressFields}>
                        {showAddressFields &&
                            <>
                                <div className="md:flex">
                                    <div className="flex flex-col mt-3 md:w-1/2 md:mr-2">
                                        <InputText title={t('createParkingArea.address')} value={parkingArea.address} onChange={(value) => { handleStreetAddressChange(value) }} />
                                    </div>
                                    <div className="flex flex-col mt-3 md:w-1/2 md:ml-2">
                                        <InputText title={t('createParkingArea.zipCode')} value={parkingArea.zipCode} onChange={(value) => { handleZipCodeChange(value) }} />
                                    </div>
                                </div>
                                <div className="md:flex">
                                    <div className="flex flex-col mt-3 md:w-1/2 md:mr-2">
                                        <InputText title={t('createParkingArea.city')} value={parkingArea.city} onChange={(value) => { handleCityChange(value) }} />
                                    </div>
                                    <div className="flex flex-col mt-3 md:w-1/2 md:ml-2">
                                        <InputText title={t('createParkingArea.country')} value={parkingArea.country} onChange={(value) => { handleCountryChange(value) }} disabled={true} />
                                    </div>
                                </div>
                            </>
                        }
                    </CollapsibleContainer>
                </div>
                <div className="flex relative justify-center mt-12">
                    <StepIndicator currentStep={1} totalNumberOfSteps={organization.isAutomaticApproval ? 3 : 4} />
                    <div className="flex absolute right-0">
                        <Button disabled={!isFormValid} onClick={() => { if (isFormValid) { navigate(`/${organization.organizationExtId.toLowerCase()}/create-parking-area/parkingspots`) } else { showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error'); setShowAddressFields(true); } }}>
                            {isFormValid &&
                                <span className="flex opacity-100">
                                    <ArrowRightIcon />
                                </span>
                            }
                            {!isFormValid &&
                                <span className="flex opacity-50">
                                    <ArrowRightIcon />
                                </span>
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AreaView;