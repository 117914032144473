import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "components/dashboard/topBar";
import LeftMenu from "components/dashboard/leftMenu";
import Breadcrumbs from "components/dashboard/breadcrumbs/breadcrumbs";
import NotificationsMenu from "components/dashboard/notificationsMenu";

const DashboardLeftMenuLayout = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden relative">
      <div className="flex flex-col">
        <TopBar />
        <Breadcrumbs />
      </div>
      <div className="flex grow pb-6">
        <LeftMenu isOrganizationLevel={true} />
        <div className="flex flex-col w-full overflow-x-hidden pl-6 pr-6 pb-1">
          <Outlet />
        </div>
      </div>
      <NotificationsMenu />
    </div>
  );
}

export default DashboardLeftMenuLayout;