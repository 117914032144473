const formatDaysToDaySpan = (itemWithDays, t) => {
    const days = [];

    if (itemWithDays.monday) {
        days.push({ name: t('dayShortNames.monday'), value: 1 });
    }

    if (itemWithDays.tuesday) {
        days.push({ name: t('dayShortNames.tuesday'), value: 2 });
    }

    if (itemWithDays.wednesday) {
        days.push({ name: t('dayShortNames.wednesday'), value: 3 });
    }

    if (itemWithDays.thursday) {
        days.push({ name: t('dayShortNames.thursday'), value: 4 });
    }

    if (itemWithDays.friday) {
        days.push({ name: t('dayShortNames.friday'), value: 5 });
    }

    if (itemWithDays.saturday) {
        days.push({ name: t('dayShortNames.saturday'), value: 6 });
    }

    if (itemWithDays.sunday) {
        days.push({ name: t('dayShortNames.sunday'), value: 7 });
    }

    days.sort((a,b) => { return a.value - b.value });

    let lastAddedValue = 0;
    let daysString = '';

    for (let index = 0; index < days.length; index++) {
        const currentValue = days[index].value;
        const previousValue = index == 0 ? days[index.value] : days[index - 1].value;
        const nextValue = (index + 1) == days.length ? 0 : days[index + 1].value;

        if (index == 0) {
            /* Always add the first day */
            daysString = days[index].name;
            lastAddedValue = days[index].value;
            continue;
        }
         else {
            /* Has siblings, ignore */
            if ((currentValue - previousValue) == 1 && (nextValue - currentValue) == 1) {
                continue;
            }

            /* Lonely, add comma */
            if ((nextValue - currentValue) != 1 && (currentValue - previousValue) != 1) {
                daysString =  daysString + ', ' + days[index].name; 
                lastAddedValue = days[index].value;
                continue;
            }

            /* Gap from the last added value, but we are not sure what the next day will be */
            if ((currentValue - lastAddedValue) > 1 && (nextValue - currentValue) == 1) {
                daysString = daysString + ', ' + days[index].name;
                lastAddedValue = days[index].value;
                continue;
            }

            /* Default to a range if we don't hit anyting else */
            daysString = daysString + '-' + days[index].name;
            lastAddedValue = days[index].value;
        }
    }

    return daysString;
}

export default formatDaysToDaySpan;