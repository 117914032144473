import { acceptCommunityConnectRequest, getCommunityConnectRequest } from 'api/communities';
import BackButton from 'components/dashboard/backButton';
import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as CommunityGroupIcon } from "assets/icons/ico_community_group.svg";
import ButtonText from 'components/common/buttonText';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import Alert from 'components/common/alert';

const CommunityConnectRequestPage = () => {

    const { organizationExtId, parkingAreaExtId, communityConnectRequestExtId } = useParams();
    const { showToast, showBetweenPagesMessage } = useMessages();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState();
    const [communityConnectRequest, setCommunityConnectRequest] = useState();
    const [isAcceptingConnectRequest, setIsAcceptingConnectRequest] = useState(false);
    const [error, setError] = useState();
    const navigate = useNavigate();

    useEffect(() => {

        setIsLoading(true);

        const load = async () => {
            try {
                const token = await getToken();
                const result = await getCommunityConnectRequest(token, communityConnectRequestExtId);

                if (result && !result.error) {
                    setCommunityConnectRequest(result);
                } else if (result.error === 'COMMUNITY_CONNECT_REQUEST_NOT_FOUND') {
                    setError({ title: t('communityConnectRequestPage.notFoundTitle'), description: t('communityConnectRequestPage.notFoundDescription') });
                } else if (result.error === 'USER_MISSING') {
                    setError({ title: t('generic.errorTitle'), description: result.errorMessage });
                }
                else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                console.error(error);
            }

            setIsLoading(false);
        }

        load();
    }, [communityConnectRequestExtId]);

    const handleAcceptConnectRequest = async () => {

        setIsAcceptingConnectRequest(true);

        try {
            const token = await getToken();
            const result = await acceptCommunityConnectRequest(token, communityConnectRequestExtId);

            if (result && !result.error) {
                showBetweenPagesMessage(t('communityConnectRequestPage.acceptedTitle'), t('communityConnectRequestPage.acceptedDescription'), () => { navigate(`/${organizationExtId}/parking-areas/${parkingAreaExtId}/community`) }, 'success');
            }
            else if (result.error === 'USER_MISSING') {
                console.error(result);
                showToast(t('communityConnectRequestPage.noAccessToAcceptTitle'), t('communityConnectRequestPage.noAccessToAcceptDescription'), 'error');
            }
            else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsAcceptingConnectRequest(false);
    }

    return (
        <>
            <BackButton />
            <div className="flex flex-col m-auto max-w-[448px] w-full">
                {error &&
                    <Alert title={error.title} description={error.description} template="error" />
                }
                {!isLoading && communityConnectRequest &&
                    <>
                        <div className="flex flex-col">
                            <h1 className="mt-14 text-center text-2xl font-medium mb-6">{t('communityConnectRequestPage.title')}</h1>
                            <div className="flex items-center gap-x-3">
                                <CommunityItem name={communityConnectRequest.communityName} imageUrl={communityConnectRequest.communityImageUrl} />
                                <span className="text-airpark-green text-3xl font-medium">+</span>
                                <CommunityItem name={communityConnectRequest.communityRecipientName} />
                            </div>
                            <span className="mt-6">{t('communityConnectRequestPage.communityWantsToConnect', { communityName: communityConnectRequest.communityName, communityRecipientName: communityConnectRequest.communityRecipientName })}</span>
                            <span className="font-medium mt-6 mb-1">{t('communityConnectRequestPage.howDoesItWorkTitle')}</span>
                            <span>{t('communityConnectRequestPage.howDoesItWorkDescription')}</span>
                            <ButtonText
                                className="mt-6"
                                onClick={() => { handleAcceptConnectRequest(); }}
                                isLoading={isAcceptingConnectRequest}>
                                {t('communityConnectRequestPage.acceptRequest')}
                            </ButtonText>
                        </div>
                    </>
                }
                {isLoading &&
                    <WidgetLoadingSkeleton />
                }
            </div>
        </>
    );
};

const CommunityItem = ({ name, imageUrl }) => {

    if (imageUrl) {
        return (
            <div className="flex justify-center items-center w-1/2">
                <div
                    style={{ backgroundImage: `url(${imageUrl}?height=220)` }}
                    className="flex w-full shadow-lg rounded-lg min-h-[220px] bg-cover p-3 relative items-end">
                    <div className="flex h-16 w-16 bg-airpark-coral rounded-lg absolute top-3 items-center justify-center p-3">
                        <CommunityGroupIcon className="h-16 w-16" />
                    </div>
                    <div className="flex w-full h-11 bg-airpark-white-background rounded-lg items-center pl-3 pr-3 font-medium whitespace-nowrap truncate">
                        {name}
                    </div>
                </div>
            </div>);
    } else {
        return (
            <div className="flex justify-center items-center w-1/2">
                <div className="flex flex-col bg-airpark-coral w-full shadow-lg rounded-lg min-h-[220px] p-3 relative justify-end">
                    <CommunityGroupIcon className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-h-[56px]" />
                    <div className="flex w-full h-11 bg-airpark-white-background rounded-lg font-medium whitespace-nowrap truncate items-center pl-3">
                        {name}
                    </div>
                </div>
            </div>);
    }
}

export default CommunityConnectRequestPage;