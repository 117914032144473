import React from "react";

import { ReactComponent as NextIcon } from 'assets/icons/ico_next.svg';
import { ReactComponent as PreviousIcon } from 'assets/icons/ico_prev.svg';
import { useTranslation } from "react-i18next";

export const Paging = ({ totalNumberOfPages, currentPage, setCurrentPage }) => {

    const { t } = useTranslation();

    if (totalNumberOfPages == 0) {
        return (null);
    }

    return (
        <div className="flex flex-row md:justify-between justify-center h-11 items-center mt-3">
            <span className="hidden md:block">{t('paging.pageOfTotal', { page: currentPage + 1, total: totalNumberOfPages })}</span>
            <div className="flex flex-row h-10 items-center">
                <button onClick={(e) => {
                    e.preventDefault();
                    if (currentPage > 0) {
                        setCurrentPage(0);
                    }
                }} className="sm:flex hidden w-9 h-10 rounded-l-lg border-[1px] border-r-0 border-airpark-gray-300 justify-center items-center">
                    <PreviousIcon className={`${currentPage == 0 ? 'opacity-30' : ''}`} />
                    <PreviousIcon className={`${currentPage == 0 ? 'opacity-30' : ''}`} />
                </button>
                <button onClick={(e) => {
                    e.preventDefault();
                    if (currentPage > 0) {
                        setCurrentPage(currentPage - 1);
                    }
                }} className="flex w-9 h-10 border-[1px] border-r-0 border-airpark-gray-300 justify-center items-center">
                    <PreviousIcon className={`${currentPage == 0 ? 'opacity-30' : ''}`} />
                </button>
                {GetPageItems(totalNumberOfPages, currentPage, setCurrentPage)}
                <button onClick={(e) => {
                    e.preventDefault();
                    if (currentPage + 1 < totalNumberOfPages) {
                        setCurrentPage(currentPage + 1);
                    }
                }} className="flex w-9 h-10 border-[1px] border-l-0 border-airpark-gray-300 justify-center items-center">
                    <NextIcon className={`${currentPage + 1 == totalNumberOfPages ? 'opacity-30' : ''}`} />
                </button>
                <button onClick={(e) => {
                    e.preventDefault();
                    if (currentPage + 1 < totalNumberOfPages) {
                        setCurrentPage(totalNumberOfPages - 1);
                    }
                }} className="sm:flex hidden w-9 h-10 rounded-r-lg border-[1px] border-l-0 border-airpark-gray-300 justify-center items-center">
                    <NextIcon className={`${currentPage + 1 == totalNumberOfPages ? 'opacity-30' : ''}`} />
                    <NextIcon className={`${currentPage + 1 == totalNumberOfPages ? 'opacity-30' : ''}`} />
                </button>
            </div>
        </div>
    )
}

export default Paging;

const GetPageItems = (totalNumberOfPages, currentPage, setCurrentPage) => {
    var pageItems = [];

    for (let i = 0; i < totalNumberOfPages; i++) {
        if (currentPage == i) {
            pageItems.push(
                <div key={i} className="flex relative w-9 h-10 border-[1px] border-airpark-green bg-airpark-green-opacity-30 items-center justify-center">
                    <span className="flex text-airpark-green">{i + 1}</span>
                </div>
            );
        } else {
            pageItems.push(
                <button key={i} onClick={(e) => { e.preventDefault(); setCurrentPage(i); }} className="flex w-9 h-10 border-[1px] first:border-l-1 border-airpark-gray-300 items-center justify-center">
                    <span className="flex">{i + 1}</span>
                </button>
            );
        }
    }

    if (pageItems.length > 5) {
        if (currentPage + 4 > pageItems.length) {
            return pageItems.splice(totalNumberOfPages - 5);
        }

        if (currentPage > 2) {
            return pageItems.splice(currentPage - 2, 5);
        }

        if (currentPage < 5) {
            return pageItems.splice(0, 5);
        }

        return pageItems.splice(currentPage, 5);
    }

    return pageItems;
}