import PageLoad from "components/common/pageLoad";
import { useDashboard } from "context/dashboard";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DashboardLoading = ({ children }) => {
    const { loading, organization, organizations } = useDashboard();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!loading && !organization) {
            if (organizations) {
                navigate(`/${organizations[0].organizationExtId}`);
            } else {
                navigate('/');
            }
        }
    }, [loading, organization, organizations]);

    if (loading) {
        return (
            <PageLoad />
        )
    } else {
        return (
            <>
                {children}
            </>
        );
    }
}

export default DashboardLoading;