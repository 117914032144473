import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font, usePDF } from '@react-pdf/renderer';
import BackGroundImage from '../../../assets/images/airpark_sign_400x300.png';
import RobotoBold from '../../../assets/fonts/Rubik-Bold.ttf';
import RobotoRegular from '../../../assets/fonts/Rubik-Regular.ttf';
import { useTranslation } from 'react-i18next';

Font.register({ family: 'Roboto-Bold', src: RobotoBold });
Font.register({ family: 'Roboto-Regular', src: RobotoRegular });

const ParkingAreaSignsPdf = ({ parkingAreaCode, spotNumbers = [] }) => {

    const { t } = useTranslation();

    const styles = StyleSheet.create({
        page: {
            width: 4724,
            height: 3543,
        },
        header: {
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            width: 4724,
            height: 2000,
        },
        image: {
            width: 4724,
            height: 3543,
            position: 'absolute',
        },
        spotNumber: {
            color: 'white',
            fontSize: 1000,
            fontFamily: 'Roboto-Bold',
            top: '200px',
            position: 'absolute'
        },
        parkingAreaCodeSmall: {
            fontFamily: 'Roboto-Regular',
            color: 'white',
            fontSize: 300,
            top: '1400px',
            position: 'absolute'
        },
        parkingAreaCodeLarge: {
            fontFamily: 'Roboto-Bold',
            color: 'white',
            fontSize: 1000,
            top: '600px',
            position: 'absolute'
        },
        parkingAreaCodeTitle: {
            fontFamily: 'Roboto-Regular',
            color: 'white',
            fontSize: 300,
            top: '350px',
            position: 'absolute'
        },
        getTheAppText: {
            fontFamily: 'Roboto-Bold',
            color: 'white',
            fontSize: 250,
            top: '2000px',
            position: 'absolute'
        }
    });

    return (<Document>
        {spotNumbers.map((number, i) => {
            return (
                <Page key={i} size={[4724, 3543]} style={styles.page}>
                    <Image src={BackGroundImage} style={styles.image}></Image>
                    <View style={styles.header}>
                        {number &&
                            <>
                                <Text style={styles.spotNumber}>{number}</Text>
                                <Text style={styles.parkingAreaCodeSmall}>{t('parkingAreaSignsPdf.area')} {parkingAreaCode}</Text>
                            </>
                        }
                        {!number &&
                            <>
                                <Text style={styles.parkingAreaCodeTitle}>{t('parkingAreaSignsPdf.parkingArea')}</Text>
                                <Text style={styles.parkingAreaCodeLarge}>{parkingAreaCode}</Text>
                            </>
                        }
                        <Text style={styles.getTheAppText}>{t('parkingAreaSignsPdf.getTheApp')}</Text>
                    </View>
                </Page>
            )
        })}
    </Document >
    );
};

export default ParkingAreaSignsPdf;