import React from "react";
import { Outlet } from "react-router-dom";
import { PhoneNumberLoginProvider } from 'context/phoneNumberLogin';

export const LoginLayout = () => {
    return (
        <PhoneNumberLoginProvider>
            <Outlet />
        </PhoneNumberLoginProvider>
    )
};

export default LoginLayout;