import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Logo from 'components/common/logo';
import SelectCountryCodeModal from 'components/login/selectCountryCodeModal';
import EmailLogin from 'components/login/emailLogin';

import usePhoneNumberLogin from 'context/phoneNumberLogin';
import { getUser } from 'api/users';

import useAuth, { getToken } from 'context/auth';
import { useMessages } from 'context/messages';

import Lottie from 'lottie-react';
import animSpinnerWhite from 'assets/animations/anim_spinner_white_20x20.json';
import Alert from 'components/common/alert';

import { ReactComponent as GooglePlayIcon } from 'assets/icons/ico_google_play.svg';
import { ReactComponent as AppleAppStoreIcon } from 'assets/icons/ico_apple_app_store.svg';

const LoginPage = () => {
  const { t } = useTranslation();
  const { isFinishedCheckingIfUserIsAuthenticated, isAuthenticated } = useAuth();
  const { requestCode } = usePhoneNumberLogin();
  const [displaySelectCountryModal, setDisplaySelectCountryModal] = useState();
  const [isPhoneNumberLogin, setIsPhoneNumberLogin] = useState();
  const [email, setEmail] = useState();
  const navigate = useNavigate();
  const { showToast } = useMessages();
  const [isLoading, setIsLoading] = useState();

  const handleLogin = () => {
    if (isPhoneNumberLogin) {
      handlePhoneNumberLogin();
    } else {
      handleEmailLogin();
    }
  }

  const handlePhoneNumberLogin = async () => {
    try {
      const result = await requestCode();
      if (result) {
        navigate('/verify-sms-code');
      } else {
        console.error(result);
        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
      }

    } catch (error) {
      console.error(error);
      showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
    }
  }

  const handleEmailLogin = () => {
    if (!validateEmail(email)) {
      showToast(t('generic.errorTitle'), t('loginPage.emailIsNotValid'), 'error');
    } else {
      window.location.href = `https://auth.airpark.app/authorize?audience=https://logic.airpark.app&scope=offline_access email&response_type=code&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${window.location.origin}/authentication-callback&login_hint=${email}`;
    }
  }

  const validateEmail = (emailToValidate) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailToValidate)) {
      return (true)
    } else {
      return (false);
    }
  }

  useEffect(() => {
    const redirectLoggedInuser = async () => {
      if (isAuthenticated) {
        const token = await getToken();
        const user = await getUser(token);

        if (user.error === 'USER_MISSING') {
          navigate('/verified/register');
        } else {
          navigate('/verified/choose-organization');
        }
      }

      setIsLoading(false);
    }

    setIsLoading(true);
    redirectLoggedInuser();
  }, [isFinishedCheckingIfUserIsAuthenticated])

  return (
    <>
      {!isLoading &&
        <>
          <div className="flex h-screen items-center justify-center bg-login-page bg-cover bg-center pb-safe p-3">
            <Logo textColor="text-white" className="absolute top-12" />
            <div className="flex flex-col">
              <div className="relative bg-black/75 max-w-[375px] w-full rounded-xl rounded-br-xl p-6">
                <EmailLogin defaultHandler={handleLogin} email={email} setEmail={setEmail} />
              </div>
              <div className="max-w-sm w-full rounded-[30px] bg-white mt-6">
                <Alert
                  title='AirPark app'
                  description={t('loginPage.useTheApp')}
                  template='info'>
                  <div className="flex gap-x-3 mt-3">
                    <a
                      title="Download the AirPark app from the Apple app store"
                      href="https://apps.apple.com/us/app/airpark/id1631313711"
                      target="_blank">
                      <AppleAppStoreIcon className="h-8" />
                    </a>
                    <a
                      title="Download the AirPark app from the Google play store"
                      href="https://play.google.com/store/apps/details?id=app.airpark.airparkapp"
                      target="_blank">
                      <GooglePlayIcon className="h-8" />
                    </a>
                  </div>
                </Alert>
              </div>
            </div>
          </div>
          <SelectCountryCodeModal display={displaySelectCountryModal} setDisplay={setDisplaySelectCountryModal} />
        </>
      }
      {isLoading &&
        <div className="flex h-screen items-center justify-center bg-login-page bg-cover bg-center pb-safe p-3">
          <Logo textColor="text-white" className="absolute top-12" />
          <div className="flex items-center justify-center bg-black/75 max-w-[375px] w-full h-60 rounded-xl p-6">
            <Lottie className="h-10 w-10" animationData={animSpinnerWhite} loop={true} />
          </div>
        </div>
      }
    </>
  );
}

export default LoginPage;