import objectToFormData from "helpers/objectToFormData";

const BASE_URL = `${process.env.REACT_APP_API_LOCATIONS_BASE_URL}/Locations`;

export const getParkingAreasForOrganization = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/parking-areas-for-organization/${organizationExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getParkingArea = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL + `/parking-area/${parkingAreaExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const createParkingArea = async (token, parkingArea) => {

  const formData = objectToFormData(parkingArea);

  const response = await fetch(BASE_URL + '/create-parking-area', {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${token}`
    },
    body: formData
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const updateParkingArea = async (token, parkingArea) => {

  const formData = objectToFormData(parkingArea);

  const response = await fetch(BASE_URL + '/update-parking-area', {
    method: 'PUT',
    headers: {
      'authorization': `Bearer ${token}`
    },
    body: formData
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const deleteParkingArea = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL + `/delete-parking-area/${parkingAreaExtId}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const deleteParkingAreaImage = async (token, parkingAreaExtId, parkingAreaImageFileName) => {
  const response = await fetch(BASE_URL + `/delete-parking-area-image/${parkingAreaExtId}/${parkingAreaImageFileName}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const getParkingSpots = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL + `/parking-spots/${parkingAreaExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const deleteParkingSpots = async (token, parkingSpotExtIds) => {
  const response = await fetch(BASE_URL + `/delete-parking-spots`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(parkingSpotExtIds)
  })

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const updateParkingSpot = async (token, parkingSpot) => {
  const response = await fetch(BASE_URL + `/update-parking-spot`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(parkingSpot)
  })

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const addParkingSpotsToParkingArea = async (token, parkingSpots) => {
  const response = await fetch(BASE_URL + `/add-parking-spots-to-parking-area`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(parkingSpots)
  })

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const getControlFeeSettings = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL + `/control-fee-settings/${parkingAreaExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const updateControlFeeSettings = async (token, controlFeeSettings) => {
  const response = await fetch(BASE_URL + '/update-control-fee-settings-for-parking-area', {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(controlFeeSettings)
  })

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}