import React, { useState } from 'react';
import TableItemContextMenu from 'components/dashboard/tableItemContextMenu/itemContextMenu';

import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';
import { useMessages } from 'context/messages';
import { useTranslation } from 'react-i18next';
import { getToken } from 'context/auth';
import { deleteCommunityConnectRequest } from 'api/communities';

const SentRequestsItem = ({ sentRequest, sentRequests, setSentRequests }) => {

    const [showMenu, setShowMenu] = useState();
    const { showToast, showModal, hideModal } = useMessages();
    const [isDeleting, setIsDeleting] = useState();
    const { t } = useTranslation();

    const handleDeleteSentRequest = async () => {
        setShowMenu(false);
        showModal(
            t('sentRequestsTable.deleteSentRequestTitle'),
            t('sentRequestsTable.deleteSentRequestDescription'),
            t('generic.yes'),
            async () => {
                setIsDeleting(true);
                hideModal();

                try {
                    const token = await getToken();
                    const deleteCommunityConnectResult = await deleteCommunityConnectRequest(token, sentRequest.communityConnectRequestExtId);

                    if (!deleteCommunityConnectResult?.error) {
                        /* Successful, remove item from list */
                        const newSentRequests = sentRequests.filter((item) => item.communityConnectRequestExtId !== sentRequest.communityConnectRequestExtId);
                        setSentRequests(newSentRequests);

                        showToast(t('sentRequestsTable.removedSentRequestTitle'), t('sentRequestsTable.removedSentRequestDescription'), 'success');
                    } else {
                        console.error(deleteCommunityConnectResult);
                        showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                setIsDeleting(false);
            },
            t('generic.cancel'),
            () => { hideModal() },
            'warning');
    }


    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">{sentRequest.communityName}</td>
            <td className="pl-6 whitespace-nowrap">{sentRequest.communityRecipientName}</td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <TableItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onDeleteClick={() => {
                            handleDeleteSentRequest();
                        }}
                        deleteTitle={t('generic.remove')}>
                    </TableItemContextMenu>
                }
            </td>
        </tr>
    );
};

export default SentRequestsItem;