const BASE_URL = `${process.env.REACT_APP_API_LOCATIONS_BASE_URL}/Charging`;

export const getChargingStations = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL + `/get-charging-stations/${parkingAreaExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getChargingStation = async (token, chargingStationExtId) => {
  const response = await fetch(BASE_URL + `/get-charging-station/${chargingStationExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getChargingProviders = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL + `/get-charging-providers/${parkingAreaExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}


export const updateChargingStation = async (token, chargingStation) => {
  const response = await fetch(BASE_URL + `/upate-charging-station`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(chargingStation)
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const addChargingStationToParkingSpot = async (token, chargingStation) => {
  const response = await fetch(BASE_URL + `/add-charging-station-to-parking-spot`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(chargingStation)
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const removechargingStation = async (token, chargingStationExtId) => {
  const response = await fetch(BASE_URL + `/remove-charging-station-from-parking-spot/${chargingStationExtId}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}