import React from "react";
import { Outlet } from "react-router-dom";
import Toast from "components/messages/toast";

import { useMessages } from "context/messages";
import BetweenPagesMessage from "components/messages/betweenPagesMessage";
import Modal from "components/messages/modal";

export const DefaultLayout = () => {

    const { toast, betweenPagesMessage, modal } = useMessages();

    return (
        <>
            <Outlet />
            <Toast display={toast.display} title={toast.title} description={toast.description} template={toast.template} />
            <BetweenPagesMessage display={betweenPagesMessage.display} title={betweenPagesMessage.title} description={betweenPagesMessage.description} onClick={betweenPagesMessage.onClick} template={betweenPagesMessage.template} />
            <Modal display={modal.display} title={modal.title} description={modal.description} template={modal.template} actionButtonOnClick={modal.actionButtonOnClick} actionButtonTitle={modal.actionButtonTitle} />
        </>
    );
};

export default DefaultLayout;