import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';

import Paging from "components/common/paging";
import PermitQueueItem from './permitQueueItem';

const PermitQueueTable = ({ queueItems, onEditClick, onRemoveQueueItem}) => {

    const [queueItemPages, setQueueItemPages] = useState();
    const [currentQueueItemPage, setCurrentQueueItemPage] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (queueItems) {
            setQueueItemPages(Math.ceil(queueItems.length / 10));
            setCurrentQueueItemPage(0);
        }
    }, [queueItems]);

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                    <tr className="text-left bg-airpark-gray-300 h-11">
                        <th className="pl-6 whitespace-nowrap">#</th>
                        <th className="pl-6 whitespace-nowrap">{t('permitQueueTable.permitType')}</th>
                        <th className="pl-6 whitespace-nowrap">{t('generic.firstName')}</th>
                        <th className="pl-6 whitespace-nowrap">{t('generic.lastName')}</th>
                        <th className="pl-6 whitespace-nowrap">{t('generic.phone')}</th>
                        <th className="pl-6 whitespace-nowrap">{t('generic.email')}</th>
                        <th className="pl-6 whitespace-nowrap">{t('permitQueueTable.inQueueSince')}</th>
                        <th className="pl-6 whitespace-nowrap">{t('permitQueueTable.specialRequests')}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {queueItems && queueItems.length > 0 && queueItems.slice(currentQueueItemPage * 10, ((currentQueueItemPage * 10) + 10)).map((item, i) => {
                            return (<PermitQueueItem
                                key={i}
                                queuePosition={i + 1}
                                queueItem={item}
                                onEditClick={onEditClick}
                                onRemoveQueueItem={onRemoveQueueItem}
                            />)
                        })}
                        {queueItems?.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={15}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        <span>{t('permitQueueTable.nobodyInQueue')}</span>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

            </div>
            {queueItemPages != 0 &&
                <Paging
                    totalNumberOfPages={queueItemPages}
                    currentPage={currentQueueItemPage}
                    setCurrentPage={setCurrentQueueItemPage} />
            }
        </>
    );
};

export default PermitQueueTable;