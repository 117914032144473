import { acceptTermsAndConditions, getHasUserAcceptedTermsAndConditions } from "api/users";
import ButtonText from "components/common/buttonText";
import Logo from "components/common/logo";
import Modal from "components/messages/modal";
import { getToken } from "context/auth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AcceptTermsAndConditionsPage = ({ children }) => {

    const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const result = await getHasUserAcceptedTermsAndConditions(token);

                if (result && !result.error) {
                    setHasAcceptedTermsAndConditions(result.hasUserAcceptedTermsAndConditions);
                } else {
                    console.error(result);

                }
            } catch (error) {
                console.error(error);
            }
        }

        load();
    }, []);

    const handleAcceptTermsAndConditions = async () => {
        try {
            setIsLoading(true);
            const token = await getToken();
            await acceptTermsAndConditions(token);
            const result = await getHasUserAcceptedTermsAndConditions(token);

            if (result && !result.error) {
                setHasAcceptedTermsAndConditions(result.hasUserAcceptedTermsAndConditions);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    if (hasAcceptedTermsAndConditions) {
        return (
            <>
                {children}
            </>
        );
    } else {
        return (
            <div className="flex h-screen justify-center p-6">
                <Logo className="absolute top-12" />
                <div className="flex flex-col m-auto max-w-md z-20">
                    <h1 className="text-2xl font-medium">{t('acceptTermsAndConditionsPage.title')}</h1>
                    <div className="flex justify-between mt-6">
                        <span>{t('acceptTermsAndConditionsPage.description')}</span>
                    </div>
                    <div className="flex flex-col mt-6 gap-y-1">
                        <a href="https://static.airpark.app/policy/2023/terms_and_conditions_sv-se.pdf" target="_blank" rel="noreferrer" className="text-green-500 hover:underline">{t('acceptTermsAndConditionsPage.termsAndConditions')}</a>
                        <a href="https://static.airpark.app/policy/2023/privacy_policy_sv-se.pdf" target="_blank" rel="noreferrer" className="text-green-500 hover:underline">{t('acceptTermsAndConditionsPage.privacyPolicy')}</a>
                    </div>
                    <div className="flex flex-col mt-6">
                        <ButtonText onClick={() => { handleAcceptTermsAndConditions(); }} isLoading={isLoading}>
                            {t('acceptTermsAndConditionsPage.accept')}
                        </ButtonText>
                    </div>
                </div>
            </div>
        );
    }
}

export default AcceptTermsAndConditionsPage;