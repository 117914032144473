const BASE_URL_PAYMENTS = process.env.REACT_APP_API_PAYMENTS;

export const getStripeAccount = async (token, organizationExtId) => {
    const response = await fetch(BASE_URL_PAYMENTS + `/OrganizationPayouts/get-stripe-account/${organizationExtId}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`
      }
    })
  
    if (response.status === 200) {
      return response.json();
    }
  
    throw response;
  }

  export const getStripeConnectAccountUrl = async (token, stripeConnectOrganizationAccount) => {
    const response = await fetch(BASE_URL_PAYMENTS + '/OrganizationPayouts/get-stripe-connect-account-url', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`
      },
      body: JSON.stringify(stripeConnectOrganizationAccount)
    })
  
    if (response.status === 200) {
      return response.json();
    }
  
    throw response;
  }