function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    if (data instanceof File) {
      parentKey = parentKey.replace(/\[.*\]/, "");
    }

    formData.append(parentKey, value);
  }
}

function objectToFormData(object) {
  const formData = new FormData();
  buildFormData(formData, object);
  return formData;
}

export default objectToFormData;