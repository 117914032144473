import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';

import Paging from "components/common/paging";
import ParkingAreaItem from './parkingAreaItem';

const ParkingAreasTable = ({ parkingAreas, setParkingAreas, setShowEditParkingAreaModal, setParkingAreaToEdit }) => {

    const [parkingAreaPages, setParkingAreaPages] = useState();
    const [currentParkingAreaPage, setCurrentParkingAreaPage] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (parkingAreas) {
            setParkingAreaPages(Math.ceil(parkingAreas.length / 10));
            setCurrentParkingAreaPage(0);
        }
    }, [parkingAreas]);

    if (!parkingAreas) {
        return (null);
    }

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6">{t('parkingAreas.name')}</th>
                            <th className="pl-6">{t('parkingAreas.parkings')}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {parkingAreas.slice(currentParkingAreaPage * 10, ((currentParkingAreaPage * 10) + 10)).map((pa, i) => {
                            return (<ParkingAreaItem
                                key={i}
                                parkingArea={pa}
                                parkingAreas={parkingAreas}
                                setParkingAreas={setParkingAreas}
                                setShowEditParkingAreaModal={setShowEditParkingAreaModal}
                                setParkingAreaToEdit={setParkingAreaToEdit} />)
                        })}
                        {parkingAreas && parkingAreas.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={5}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        <span>{t('parkingAreas.noParkingAreas')}</span>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            {parkingAreaPages != 0 &&
                <Paging totalNumberOfPages={parkingAreaPages} currentPage={currentParkingAreaPage} setCurrentPage={setCurrentParkingAreaPage} />
            }
        </>
    );
};

export default ParkingAreasTable;