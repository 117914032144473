import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import ItemContextMenu from "../../dashboard/tableItemContextMenu/itemContextMenu";

const AirPassItem = ({ airPass }) => {
    const [showMenu, setShowMenu] = useState();
    const navigate = useNavigate();

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">
                {airPass.airPassExtId}
            </td>
            <td className="pl-6 whitespace-nowrap">
                {airPass.description}
            </td>
            <td className="pl-6 whitespace-nowrap">
                Lat {airPass.latitude}, Lng {airPass.longitude}
            </td>
            <td className="pl-6 whitespace-nowrap">
                {airPass.floor ?? ''}
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                    <ThreeDotsIcon className="block" />
                </button>
                {showMenu &&
                    <ItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onEditClick={() => { navigate(`edit/${airPass.airPassExtId.toLowerCase()}`); }} />
                }
            </td>
        </tr>
    )
}

export default AirPassItem;