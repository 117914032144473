import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import { organizationMenuItems } from "data/menuItems";

const OrganizationDesktopMenu = () => {

    const { t } = useTranslation();

    const location = useLocation();
    const { pathname } = location;
    const { organizationExtId } = useParams();

    const pages = pathname.split('/');

    let currentPageStructure = '';

    let pageDepth = 2;

    for (let i = pageDepth; i < pages.length; i++) {
        if (i == pages.length - 1) {
            currentPageStructure = currentPageStructure + pages[i];
        } else {
            currentPageStructure = currentPageStructure + pages[i] + '/';
        }
    }

    const currentPage = pages[2] ?? '';

    return (
        <div className="lg:flex hidden items-center justify-center h-16 shadow mb-6 mx-6 rounded-full gap-x-6">
            {organizationMenuItems.map((item, i) => {
                return (
                    <div key={i} className={`flex border-b-2 pb-2 mt-2 px-3 ${currentPage === item.to ? 'border-airpark-green' : 'border-white'}`}>
                        {item.icon}
                        {item.to?.substring(0, 4) === 'http' &&
                            <a key={i} href={item.to} target="_blank" rel="noreferrer" className="pl-2 font-medium">
                                {item.title ?? t(item.translationKey)}
                            </a>
                        }
                        {item.to?.substring(0, 4) !== 'http' &&
                            <NavLink
                                key={i}
                                className="pl-2 font-medium"
                                to={`/${organizationExtId}/${item.to}`}>
                                {item.title ?? t(item.translationKey)}
                            </NavLink>
                        }
                    </div>
                )
            })}
        </div>
    );
};

export default OrganizationDesktopMenu;