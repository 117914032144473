import { PDFDownloadLink } from '@react-pdf/renderer';
import { getParkingSpots } from 'api/locations';
import Alert from 'components/common/alert';
import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import ParkingAreaSignsPdf from 'components/pageSpecific/signs/parkingAreaSignsPdf';
import { getToken } from 'context/auth';
import { useMessages } from 'context/messages';
import { useParkingArea } from 'context/parkingArea';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SignsPage = () => {

    const { t } = useTranslation();
    const { parkingArea } = useParkingArea();
    const [parkingSpots, setParkingSpots] = useState();
    const [spotNumbers, setSpotNumbers] = useState();
    const { showToast } = useMessages();

    useEffect(() => {

        const load = async () => {
            const token = await getToken();
            const parkingSpotsResult = await getParkingSpots(token, parkingArea.parkingAreaExtId);

            if (parkingSpotsResult && !parkingSpotsResult.error) {
                setParkingSpots(parkingSpotsResult);
                let numbers = parkingSpotsResult?.filter(spot => spot.isNumbered).map(spot => spot.spotIdentifier);
                numbers.push('');
                setSpotNumbers(numbers);
            } else {
                console.error(parkingSpotsResult);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        }

        if (parkingArea) {
            load();
        }
    }, [parkingArea]);

    if (parkingSpots && parkingArea && spotNumbers) {
        return (
            <div className="flex flex-col gap-y-6">
                <Widget>
                    <div className="flex flex-col">
                        <h2 className="text-xl font-medium mb-3">{t('signsPage.title')}</h2>
                        <span>{t('signsPage.description')}</span>
                    </div>
                    <div className="flex items-center justify-center mt-6">
                        <PDFDownloadLink
                            className="flex max-w-xs w-full h-11 bg-airpark-green hover:bg-airpark-green-400 rounded-full shadow-log text-white font-medium items-center justify-center cursor-pointer"
                            document={<ParkingAreaSignsPdf parkingAreaCode={parkingArea?.parkingAreaCode} spotNumbers={spotNumbers} />}
                            fileName="signs.pdf">
                            {({ blob, url, loading, error }) => (
                                t('generic.download')
                            )}
                        </PDFDownloadLink>
                    </div>
                </Widget>
                <Alert
                    title={t('signsPage.alertTitle')}
                    description={t('signsPage.alertDescription')}
                    template='info'
                    clickTitle='AirPark support.'
                    /* Send e-mail to hello@airpark.app */
                    onClick={() => {
                        window.location.href = 'mailto:hello@airpark.app'
                    }} />
            </div>
        );
    } else {
        return <WidgetLoadingSkeleton />
    }
};

export default SignsPage;