import InputText from 'components/common/inputText';
import ButtonText from 'components/common/buttonText';
import { getToken } from 'context/auth';
import { createOrganizationInvite } from 'api/organizations';
import { useState} from 'react';
import { useDashboard } from 'context/dashboard';
import { useTranslation } from 'react-i18next';
import { useMessages } from 'context/messages';

export const InvitePortalAdmin = ({ invites, setInvites, isSaving, setIsSaving, setDisplay }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState();
    const { showToast } = useMessages();
    const { organization } = useDashboard();
    

    const handleInviteUser = async () => {

        setIsSaving(true);

        try {
            const token = await getToken();
            const result = await createOrganizationInvite(token, {
                organizationExtId: organization.organizationExtId,
                email: email
              });

              if (result && !result.error) {
                invites.push(result);
                setInvites([...invites]);
                setDisplay(false);
                setEmail('');
                showToast(t('inviteUserModal.userInvitedTitle'), t('inviteUserModal.userInvitedDescription'), 'success');
                
              } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
              }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSaving(false);
    }

    return <>
        <InputText 
            value={email ?? ''} 
            autoComplete="off" 
            title={t('generic.email')} 
            onChange={(value) => { setEmail(value); }} />
        <ButtonText 
            onClick={() => { handleInviteUser(); }} 
            className="flex mt-6 justify-center w-full" 
            isLoading={isSaving} 
            isSecondary={false}>
            {t('generic.invite')}
        </ButtonText>
    </>
};