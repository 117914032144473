import Modal from "../../common/modal";
import InputText from "../../common/inputText";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import InputTextArea from "../../common/inputTextArea";
import DropdownSelector from "../../common/dropdownSelector/dropdownSelector";
import { useParams } from "react-router-dom";
import { getToken } from "../../../context/auth";
import Loading from "../../common/loading";
import { t } from "i18next";
import ButtonText from "../../common/buttonText";
import debounce from "lodash/debounce";
import { searchUserByPhoneNumber } from "../../../api/users";
import { useMessages } from "context/messages";
import { addUserToPermitQueue, editPermitQueueItem } from "../../../api/permitQueue";
import { useParkingArea } from "../../../context/parkingArea";
import { PermitQueueType } from "../../../helpers/consts";
import { useTranslation } from "react-i18next";

function PermitQueueItemModal({ display, setDisplay, queueItem, onAddQueueItem, onEditQueueItem, queueType }) {
    const { showToast } = useMessages();
    const [ itemDto, setItemDto ] = useState({
        userExtId: null,
        description: "",
        permitQueueType: queueType ?? PermitQueueType.AreaParking
    });
    const [ errors, setErrors ] = useState({});
    const [ submitAsync, setSubmitAsync ] = useState(false);

    const [ initialPhoneNumber, setInitialPhoneNumber ] = useState(queueItem?.phone ?? '');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ userSearchAsync, setUserSearchAsync ] = useState(false);
    const [ dbUser, setDbUser ] = useState(undefined);

    const [ userDto, setUserDto ] = useState({
        email: '',
        firstName: '',
        lastName: '',
    });

    const { organizationExtId, parkingAreaExtId } = useParams();

    const { parkingArea } = useParkingArea();

    const { t } = useTranslation();

    const userExistsInAirPark = Boolean(dbUser);

    const permitTypeOptions = useMemo(() => {
        return [
            {
                title: t('createPermitPage.allParkingSpots'),
                value: PermitQueueType.AreaParking
            }, {
                title: t('createPermitPage.specificParkingSpots'),
                value: PermitQueueType.AnyNumberedSpot
            }
        ];
    }, []);

    const onSubmit = () => {
        setErrors({});
        setSubmitAsync(true);
        if (isEdit) {
            const editPermitQueueItemDto = {
                description: itemDto.description,
                phone: phoneNumber,
                ...userDto,
            }
            getToken().then((token) => {
                editPermitQueueItem(token, editPermitQueueItemDto, queueItem.permitQueueExtId)
                    .then((response) => {
                        onEditQueueItem(response);
                        setDisplay(false);
                    })
                    .finally(() => setSubmitAsync(false))
            })
        } else {
            if (!phoneNumber.length) {
                setErrors({ ...errors, user: true })
                setSubmitAsync(false);
                return;
            }
            const addToPermitQueueDto = {
                ...itemDto,
                parkingAreaExtId,
            };

            if (dbUser) {
                addToPermitQueueDto.userExtId = dbUser.userExtId;
                addToPermitQueueDto.email = dbUser.email;
                addToPermitQueueDto.phone = dbUser.phone;
                addToPermitQueueDto.firstName = dbUser.firstName;
                addToPermitQueueDto.lastName = dbUser.lastName;
            } else {
                Object.entries(userDto).forEach(([ key, value ]) => {
                    addToPermitQueueDto[key] = value;
                })
                addToPermitQueueDto.phone = phoneNumber;
            }

            getToken().then((token) => {
                addUserToPermitQueue(token, addToPermitQueueDto)
                    .then((queueItem) => {
                        onAddQueueItem(queueItem)
                        setDisplay(false);
                    })
                    .finally(() => setSubmitAsync(false))
            })
        }
    }

    const isEdit = queueItem !== null;

    const searchUser = useCallback((phoneNumber) => {
        return getToken().then((token) => {
            setUserSearchAsync(true);
            return searchUserByPhoneNumber(token, phoneNumber)
                .then((res) => {
                    if (res?.error) {
                        setDbUser(null);
                        setUserDto((prevDto) => ({
                            email:  prevDto?.email || queueItem?.email || '',
                            firstName: prevDto?.firstName || queueItem?.firstName || '',
                            lastName: prevDto?.lastName || queueItem?.lastName || '',
                        }));
                        setErrors({ ...errors, phone: true });
                        return null;
                    } else {
                        setDbUser(res);
                        setUserDto({
                            email: res?.email ?? '',
                            firstName: res?.firstName ?? '',
                            lastName: res?.lastName ?? '',
                        });
                        setErrors({ ...errors, phone: false });
                        return res;
                    }
                })
                .finally(() => {
                    setUserSearchAsync(false);
                })
        });
    }, [queueItem])

    const debouncedUserSearch = React.useRef(
        debounce((phoneNumber) => searchUser(phoneNumber), 1500)
    ).current;

    const userSection = () => {
        const searchedForUser = phoneNumber.length >= 3 && !userSearchAsync && dbUser !== undefined;

        const inputDisabled = userExistsInAirPark || userSearchAsync;

        return (
            <div>
                {userSearchAsync ? (
                    <div className='flex justify-between my-1'>
                        <Loading size={5} />
                    </div>
                ) : searchedForUser && (
                    dbUser ? (
                        <div className='font-medium text-airpark-green my-2'>{t('permitQueueItemModal.userInSystem')}</div>
                    ) : (
                        <div className='font-medium text-airpark-red my-2'>{t('permitQueueItemModal.userNotInSystem')}</div>
                    )
                )}
                <div>
                    <InputText title={t('permitQueueItemModal.email')}
                               className='mb-3'
                               disabled={inputDisabled}
                               value={userDto.email}
                               onChange={(email) => setUserDto({ ...userDto, email })}
                    />
                    <InputText title={t('permitQueueItemModal.firstName')}
                               className='mb-3'
                               disabled={inputDisabled}
                               value={userDto.firstName}
                               onChange={(firstName) => setUserDto({ ...userDto, firstName })}
                    />
                    <InputText title={t('permitQueueItemModal.lastName')}
                               className='mb-3'
                               disabled={inputDisabled}
                               value={userDto.lastName}
                               onChange={(lastName) => setUserDto({ ...userDto, lastName })}
                    />
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (phoneNumber.length >= 3) {
            debouncedUserSearch(phoneNumber);
        }
    }, [ phoneNumber ]);

    useEffect(() => {
        if (queueItem) {
            setItemDto({ ...queueItem });
            if (queueItem.phone) {
                setPhoneNumber(queueItem.phone);
            }
        }
    }, []);

    return (
        <Modal title={isEdit ? t('permitQueueItemModal.titleEdit') : t('permitQueueItemModal.titleAdd')}
               overlayClassName={'items-center justify-center'}
               modalClassName={'mt-0 max-h-[90vh] overflow-y-auto flex-col'}
               innerClassName={"w-[70vw] overflow-y-auto"}
               display={display}
               setDisplay={setDisplay}>
            {parkingArea && <InputText disabled={true} value={parkingArea.name} title={t('permitQueueItemModal.parkingArea')} />}
            <div className='my-6'>
                <DropdownSelector
                    disabled={isEdit}
                    title={t('permitQueueItemModal.permitType')}
                    options={permitTypeOptions}
                    value={itemDto.permitQueueType}
                    onChange={(value) => {
                        setItemDto({
                            ...itemDto,
                            permitQueueType: value,
                        })
                    }}
                />
            </div>
            <InputText
                title={
                    <div className='flex justify-between'>
                        <span className='mr-2'>{t('permitQueueItemModal.inputPhone')}</span>
                    </div>
                }
                disabled={userExistsInAirPark && isEdit && initialPhoneNumber === phoneNumber}
                error={errors.user}
                value={phoneNumber}
                onChange={setPhoneNumber} />
            <div className={"mb-6 mt-1"}>{userSection()}</div>
            <InputTextArea
                title={t('permitQueueItemModal.inputSpecialRequests')}
                placeHolder={''}
                value={itemDto.description}
                onChange={(value) => {
                    setItemDto({ ...itemDto, description: value })
                }}
            />
            <ButtonText onClick={onSubmit}
                        isLoading={submitAsync}
                        className="flex mt-6 justify-center ml-auto">
                {t('permitQueueItemModal.buttonSave')}
            </ButtonText>
        </Modal>
    )
}

export default PermitQueueItemModal;