import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getToken } from "context/auth";

import { deletePriceRuleSet } from "api/priceRuleSets";

import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";

import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';

import { useMessages } from "context/messages";
import ItemContextMenu from "../../dashboard/tableItemContextMenu/itemContextMenu";
import formatDaysToDaySpan from "helpers/formatDaysToDaySpan";

const PriceRuleSetItem = ({ priceRuleSet, priceRuleSets, setPriceRuleSets }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState();
    const navigate = useNavigate();
    const { showToast, showModal, hideModal } = useMessages();
    const [isDeleting, setIsDeleting] = useState();

    const handleRemovePriceRuleSet = () => {

        setShowMenu(false);

        showModal(
            t('parkingArea.removePriceRuleSetTitle'),
            t('parkingArea.removePriceRuleSetDescription'),
            t('generic.yes'),
            async () => {
                setIsDeleting(true);
                hideModal();

                try {
                    const token = await getToken();
                    const deletePriceRuleSetResult = await deletePriceRuleSet(token, priceRuleSet.priceRuleSetExtId);

                    if (deletePriceRuleSetResult.error) {
                        console.error(deletePriceRuleSetResult);
                    } else {
                        /* Successful, remove item from list */
                        const newPriceRuleSets = priceRuleSets.filter((item) => item.priceRuleSetExtId !== priceRuleSet.priceRuleSetExtId);
                        setPriceRuleSets(newPriceRuleSets);
                        
                        showToast(t('parkingArea.removedPriceRuleSetTitle'), t('parkingArea.removedPriceRuleSetDescription'), 'success');
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                setIsDeleting(false);
            },
            t('generic.cancel'),
            () => { hideModal() },
            'warning');
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">{priceRuleSet.description}{priceRuleSet.isExpired ? 'expired' : ''}</td>
            <td className="pl-6 whitespace-nowrap">{`${priceRuleSet.endDateTime != null ? priceRuleSet.friendlyPeriod : `${priceRuleSet.friendlyPeriod} - ${t('parkingArea.ongoing')}` }`}</td>
             {!priceRuleSet.isRecurring &&
                <td className="pl-6 whitespace-nowrap" colSpan={2}>{t('parkingArea.asDefinedByPeriod')}</td>
            }
            {priceRuleSet.isRecurring &&
                <>
                    <td className="pl-6 whitespace-nowrap">{formatDaysToDaySpan(priceRuleSet, t)}</td>
                    <td className="pl-6 whitespace-nowrap">{priceRuleSet.friendlyTime}</td>
                </>
            }
            {priceRuleSet.isSpecificParkingSpots &&
                <td className="pl-6 whitespace-nowrap">{t('parkingArea.specificSpots')}</td>
            }
            {!priceRuleSet.isSpecificParkingSpots &&
                <td className="pl-6 whitespace-nowrap">{t('parkingArea.allParkingSpots')}</td>
            }
            {priceRuleSet.isCommunity &&
                <td className="pl-6 whitespace-nowrap">{t('parkingArea.community')}</td>
            }
            {!priceRuleSet.isCommunity &&
                <td className="pl-6 whitespace-nowrap">{t('parkingArea.public')}</td>
            }
            <td className="pl-6 pr-6 whitespace-nowrap">{priceRuleSet.friendlyPrice}/{t(`priceUnit.${priceRuleSet.unit}`)}</td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <ItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onEditClick={() => { navigate(`add-price-and-availability/${priceRuleSet.priceRuleSetExtId.toLowerCase()}`); }}
                        onDeleteClick={() => { handleRemovePriceRuleSet(); }} />
                }
            </td>
        </tr>
    )
}

export default PriceRuleSetItem;