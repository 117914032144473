const BASE_URL = `${process.env.REACT_APP_API_USERS_BASE_URL}/Payouts`

export const getPayoutsForOrganization = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/payouts-for-organization/${organizationExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}

export const getPayout = async (token, payoutExtId) => {
  const response = await fetch(BASE_URL + `/get-payout/${payoutExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}