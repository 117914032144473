import Modal from 'components/common/modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconSearch } from 'assets/icons/ico_search.svg';
import usePhoneNumberVerification from 'context/phoneNumberLogin';
import { CircleFlag } from 'components/common/flagIcon';

const SelectCountryCodeModal = ({ display, setDisplay }) => {
    const { t } = useTranslation();
    const { countries, setCountry } = usePhoneNumberVerification();
    const [search, setSearch] = useState();

    const handleCountrySelect = e => {
        setDisplay(false);
    }

    const handleClose = () => {
        setSearch('');
    }

    return (
        <Modal display={display} setDisplay={setDisplay} onClose={handleClose} title={t('loginPage.selectCountryCode')}>
            <div className="flex flex-col h-min-screen-safe">
                <IconSearch className="h-5 w-5 absolute mt-2 ml-4" />
                <input onChange={(e) => { setSearch(e.target.value) }} type="text" className="w-[325px] h-[36px] mb-[16px] rounded-lg bg-gray-200 outline-0 pl-[42px] text-base font-normal align-top" placeholder={t('loginPage.search')}></input>
                {countries && countries.map((country, i) => {
                    if ((search && country.name.toLowerCase().includes(search.toLowerCase())) || !search) {
                        return (
                            <ListItem key={i} value={country} setValue={setCountry} handleSelect={handleCountrySelect} />);
                    }

                })}
            </div>
        </Modal>);
}

const ListItem = ({ value, setValue, handleSelect }) => {
    return (
        <div onClick={(e) => { e.preventDefault(); setValue(value); handleSelect(e); }} className="flex flex-row h-11 border-b-[1px] border-gray-200 items-center mr-[-24px] ml-[-24px] pl-6 cursor-pointer">
            <CircleFlag countryCode={value.country.toLowerCase()} className="h-7 mr-2" />
            <p>{value.name} ({value.code})</p>
        </div>);
}

export default SelectCountryCodeModal;