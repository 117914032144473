import React, { useEffect } from 'react';
import useAuth, { getToken } from 'context/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageLoad from 'components/common/pageLoad';
import jwtDecode from 'jwt-decode';
import { getUser } from 'api/users';

const AuthenticationCallbackPage = () => {
    const { exchangeCode, isAuthenticated, isFinishedCheckingIfUserIsAuthenticated } = useAuth();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const handleCallback = async () => {
            try {
                let token;

                /* User is not authenticated, let's get a token using the code sent */
                if (!isAuthenticated) {
                    const code = searchParams.get('code');
                    token = await exchangeCode(code);
                } else {
                    token = await getToken();
                }

                const isEmailVerified = checkIfTokenHasVerifiedEmail(token);

                if (isEmailVerified) {
                    const user = await getUser(token);

                    if (user.error === 'USER_MISSING') {
                        navigate('/verified/register');
                    } else {
                        const redirectPath = localStorage.getItem('redirect_path');

                        if (redirectPath) {
                            localStorage.removeItem('redirect_path');
                            navigate(redirectPath);
                        } else {
                            navigate('/verified/choose-organization');
                        }
                    }
                } else {
                    navigate('/verify-email');
                }
            } catch (error) {
                console.error(error);
                //navigate('/');
            }
        }

        if (isFinishedCheckingIfUserIsAuthenticated) {
            handleCallback();
        }
    }, [isFinishedCheckingIfUserIsAuthenticated]);

    const checkIfTokenHasVerifiedEmail = (token) => {
        const decodedToken = jwtDecode(token);
        return decodedToken['https://airpark.app/email_verified'];
    }

    return (
        <PageLoad />
    )
};

export default AuthenticationCallbackPage;