import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';

import Paging from "components/common/paging";
import PayoutItem from './payoutItem';

const PayoutsTable = ({ payouts }) => {

    const [payoutPages, setPayoutPages] = useState();
    const [currentPayoutPage, setCurrentPayoutPage] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (payouts) {
            setPayoutPages(Math.ceil(payouts.length / 20));
            setCurrentPayoutPage(0);
        }
    }, [payouts]);

    if (!payouts) {
        return (null);
    }

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">{t('generic.id')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('generic.amount')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('generic.status')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('payoutsTable.payoutDate')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('payoutsTable.period')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('payoutsTable.description')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {payouts && payouts.length > 0 && payouts.slice(currentPayoutPage * 20, ((currentPayoutPage * 20) + 20)).map((payout, i) => {
                            return (<PayoutItem
                                key={i}
                                payout={payout} />)
                        })}
                        {payouts && payouts.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={15}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        <span>{t('payoutsTable.noPayoutsFound')}</span>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

            </div>
            {payoutPages != 0 &&
                <Paging totalNumberOfPages={payoutPages} currentPage={currentPayoutPage} setCurrentPage={setCurrentPayoutPage} />
            }
        </>
    );
};

export default PayoutsTable;