// export function requestAuth0SmsCode(number) {
//   return fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/passwordless/start`, {
//     method: 'POST',
//     headers: {
//       'content-type': 'application/json',
//     },
//     body: JSON.stringify({
//       client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
//       connection: 'sms',
//       phone_number: number,
//       send: 'code',
//       authParams: {
//         scope: 'openid sms profile offline_access',
//       },
//     }),
//   });
// }

// export function exchangeSmsCodeAuth0(code, number) {
//   return fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
//     method: 'POST',
//     headers: {
//       'content-type': 'application/json',
//     },
//     body: JSON.stringify({
//       client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
//       grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
//       username: number,
//       otp: code,
//       realm: 'sms',
//       audience: process.env.REACT_APP_AUTH0_AUDIENCE,
//       scope: 'openid sms profile offline_access'
//     }),
//   });
// }

export function exchangeCodeAuth0(code) {
  return fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        code: code,
        redirect_uri: `${window.location.origin}/authorization-callback`,
        audience: 'https://logic.airpark.app',
        scope: 'openid profile offline_access'
      })
    });
}

export function exchangeRefreshToken(refreshToken) {
  return fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
  });
}
