import React, { useEffect, useState } from 'react';
import usePhoneNumberVerification from 'context/phoneNumberLogin';
import { ReactComponent as BackgroundIcon } from 'assets/icons/ico_bg_bell.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/ico_arrow_right.svg';
import Button from 'components/common/button';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { createUser } from 'api/users';
import useAuth from 'context/auth';
import jwtDecode from 'jwt-decode';
import ButtonText from 'components/common/buttonText';
import { useMessages } from 'context/messages';
import DropdownSelector from 'components/common/dropdownSelector/dropdownSelector';
import InputEmail from 'components/common/inputEmail';
import InputText from 'components/common/inputText';
import CheckBox from 'components/common/checkbox';

const RegisterPage = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const { getToken, logout } = useAuth();
    const { phoneNumber, country } = usePhoneNumberVerification();
    const { showToast } = useMessages();

    const countries = [
        { value: 'SE', title: 'Sverige' },
        { value: 'US', title: 'United States' },
        { value: 'NO', title: 'Norge' },
        { value: 'DK', title: 'Danmark' },
        { value: 'FI', title: 'Suomi' },
        { value: 'DE', title: 'Deutschland' },
        { value: 'FR', title: 'France' },
        { value: 'GB', title: 'Great Britain' },
        { value: 'PL', title: 'Polska' },
        { value: 'IT', title: 'Italia' },
        { value: 'ES', title: 'España' },
        { value: 'GR', title: 'Greece' },
        { value: 'AT', title: 'Österreich' },
        { value: 'RO', title: 'România' },
        { value: 'NL', title: 'Nederland' },
        { value: 'BE', title: 'Belgique' },
        { value: 'HU', title: 'Magyarország' },
        { value: 'CH', title: 'Schweiz' },
        { value: 'IS', title: 'Ísland' },
        { value: 'LT', title: 'Lietuva' },
        { value: 'LV', title: 'Latvija' },
        { value: 'EE', title: 'Eesti' },
        { value: 'PT', title: 'Portugal	' },
      ].sort((a, b) => (a.title > b.title));

    const [user, setUser] = useState(
        {
            firstName: '',
            lastName: '',
            email: '',
            phone: country.code + phoneNumber,
            countryCode: country.country,
            languageCode: country.country === 'SE' ? 'sv' : 'en',
            acceptedTermsAndConditions: false,
        });


    useEffect(() => {
        const initializeEmail = async () => {
            const email = await getEmailFromToken();

            if (email) {
                setUser({ ...user, email: email });
                setIsEmailVerified(true);
            }
        }

        initializeEmail();
    }, []);

    const getEmailFromToken = async () => {
        const token = await getToken();
        const decodedToken = jwtDecode(token);
        return decodedToken['https://airpark.app/email'];
    }

    const handleCreateUser = async () => {

        try {
            setLoading(true);
            var token = await getToken();

            if (user.firstName && user.lastName && user.email && user.acceptedTermsAndConditions) {
                const result = await createUser(token, user);

                if (result.error) {
                    console.error(result);
                    showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error');
                } else {
                    const email = await getEmailFromToken();

                    /* If the user is verified using email we redirect back to the auth callback to check if they've been verified and/or if they're logging in using an invite */
                    if (email) {
                        navigate('/authentication-callback');
                    } else {
                        /* Authenticated with phone number */
                        navigate('/verified/choose-organization');
                    }
                }
            } else {
                showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error');
            }

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="flex flex-col m-auto max-w-md z-20 p-2 pt-24 h-lg:pt-2">
                <h1 className="text-2xl font-medium mb-4">{t('registerPage.beforeWeGetStarted')}</h1>
                <p>{t('registerPage.description')}</p>
                <div className="flex flex-row mt-6">
                    <div className="flex flex-col mr-3 w-1/2">
                        <InputText
                            title={t('registerPage.firstName')}
                            value={user.firstName}
                            autoComplete={'given-name'}
                            onChange={(value) => { setUser({ ...user, firstName: value }) }} />
                    </div>
                    <div className="flex flex-col w-1/2">
                        <InputText
                            title={t('registerPage.surName')}
                            value={user.lastName}
                            autoComplete={'family-name'}
                            onChange={(value) => { setUser({ ...user, lastName: value }) }} />
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <InputEmail 
                        title={t('generic.email')} 
                        value={user.email} 
                        onChange={(value) => { setUser({ ...user, email: value }) }} 
                        disabled={isEmailVerified} />
                </div>
                <div className="mt-3">
                    <DropdownSelector
                        title={t('registerPage.country')}
                        options={countries}
                        defaultValue={country.country}
                        onChange={(value) => { setUser({...user, countryCode: value}) }}
                    />
                </div>
                <div className="flex gap-x-3 mt-3">
                    <CheckBox isChecked={user.acceptedTermsAndConditions} onClick={() => { setUser({...user, acceptedTermsAndConditions: !user.acceptedTermsAndConditions }) }} />
                    <span>{t('registerPage.accept')} <a href={t('generic.termsAndConditionsUrl')} target="_blank" className="text-airpark-green">{t('registerPage.termsAndConditions')}</a></span>
                </div>
                <div className="flex justify-between mt-6">
                    <ButtonText onClick={() => { logout(); navigate('/'); }} loading={loading} isSecondary={true}>
                        {t('generic.cancel')}
                    </ButtonText>
                    <Button disabled={!user.acceptedTermsAndConditions} onClick={() => { handleCreateUser(); }} loading={loading}>
                        <ArrowRight />
                    </Button>
                </div>
            </div>
            <BackgroundIcon className="absolute bottom-6 left-0 max-h-[100%] h-[150px] w-[275px] z-10" />
        </>
    )
}

export default RegisterPage;