import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { organizationMenuItems, leftMenuParkingAreaItems } from "data/menuItems";
import { useParkingArea } from "context/parkingArea";

const LeftMenu = ({ isOrganizationLevel }) => {

    const { t } = useTranslation();
    const location = useLocation();
    const { pathname } = location;

    const { parkingArea } = useParkingArea();

    const pages = pathname.split('/');

    let currentPageStructure = '';

    let pageDepth = 2;

    if (isOrganizationLevel) {
        pageDepth = 2;
    } else {
        pageDepth = 4;
    }

    for (let i = pageDepth; i < pages.length; i++) {
        if (i == pages.length - 1) {
            currentPageStructure = currentPageStructure + pages[i];
        } else {
            currentPageStructure = currentPageStructure + pages[i] + '/';
        }
    }

    let menuItems;
    let shouldDisplay = false;
    let organizationLevelSubItems;

    if (isOrganizationLevel) {
        menuItems = organizationMenuItems;

        const currentOrganizationLevelPage = pages[2] ?? '';

        menuItems.map((item, i) => {
            if (item.to == currentOrganizationLevelPage && item.subItems) {
                shouldDisplay = true;
                organizationLevelSubItems = item.subItems;
            }
        });
    } else {
        menuItems = leftMenuParkingAreaItems;
    }

    if (isOrganizationLevel && !shouldDisplay) {
        return null;
    } else if (isOrganizationLevel && shouldDisplay && organizationLevelSubItems) {
        menuItems = organizationLevelSubItems;
    }

    const getPageTitle = (item) => {

        if (!isOrganizationLevel && parkingArea && '' === item.to) {
            return parkingArea.name;
        }

        if (item.title) {
            return item.title;
        } else if (item.translationKey) {
            return t(item.translationKey);
        } else {
            return '';
        }
    }


    return (
        <div className="flex-col min-w-[272px] rounded-3xl ml-6 p-6 shadow lg:inline-flex hidden">
            <ul>
                {menuItems.map((item, i) => {
                    let shouldExpand = false;
                    if (item.to === '' && currentPageStructure !== '') {
                        /* We're on a sub item page for the top level item, expand if one of the sub items matches */
                        item.subItems?.forEach((subItem) => {
                            if (subItem.to == currentPageStructure) {
                                shouldExpand = true;
                            }
                        });
                    } else {
                        shouldExpand = item.to == currentPageStructure?.split('/').shift();
                    }

                    return (
                        <li key={i} className="flex flex-col list-none mb-2 w-full">
                            <div className={`flex items-center border-b-2 pb-2 ${currentPageStructure == item.to ? 'border-airpark-green' : 'border-white'}`}>
                                {item.icon}

                                {item.to?.substring(0, 4) === 'http' &&
                                    <a key={i} href={item.to} target="_blank" rel="noreferrer" className="pl-2 font-medium">
                                        {item.title ?? t(item.translationKey)}
                                    </a>
                                }
                                {item.to?.substring(0, 4) !== 'http' &&
                                    <NavLink
                                        key={i}
                                        className="flex pl-2 font-medium"
                                        to={item.to}>
                                        <span className="truncate w-48">{getPageTitle(item)}</span>
                                    </NavLink>
                                }

                            </div>
                            {shouldExpand && item.subItems &&
                                <div className="flex flex-col mt-1 mb-1">
                                    {item.subItems.map((subItem, i) => {
                                        return (
                                            <NavLink
                                                key={i}
                                                to={subItem.to}
                                                className={`pl-8 border-b-2 pb-1 ${currentPageStructure == subItem.to ? 'border-airpark-green' : 'border-white'}`}>
                                                {item.title ?? t(subItem.translationKey)}
                                            </NavLink>)
                                    })}
                                </div>
                            }
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}

export default LeftMenu;