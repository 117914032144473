import { getPayout } from 'api/payouts';
import Alert from 'components/common/alert';
import ButtonText from 'components/common/buttonText';
import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import PayoutDetailsTable from 'components/pageSpecific/payoutDetails/payoutDetailsTable';
import PayoutDetailsTableNewTaxCalculation from 'components/pageSpecific/payoutDetails/payoutDetailsTableNewTaxCalculation';
import { getToken } from 'context/auth';
import { useDashboard } from 'context/dashboard';
import { useMessages } from 'context/messages';
import { formatCurrency } from 'helpers/currency';
import { formatDateToIsoStandard } from 'helpers/date';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const PayoutDetailsPage = () => {

    const { payoutExtId } = useParams();
    const { showToast } = useMessages();
    const { t } = useTranslation();
    const [payout, setPayout] = useState();
    const [loading, setLoading] = useState(true);
    const { organization } = useDashboard();
    const navigate = useNavigate();

    useEffect(() => {

        const load = async () => {
            try {
                const token = await getToken();
                const result = await getPayout(token, payoutExtId);

                if (result && !result.error) {
                    setPayout(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setLoading(false);
        };

        load();
    }, [payoutExtId]);

    const handleExportToCsv = () => {
        let csv = '';

        if (payout.isNewTaxCalculation) {
            // Create header row
            csv += 'Transaction Type,Payment Amount,Transaction Fee,Air Park Fee,TotalVAT,Owner Amount,Subletter Amount,Payout Amount,VAT on Payout Amount,Payment Ext ID\n';

            // Create data rows
            payout?.payoutLines.forEach(line => {
                csv += `${line.transactionType},${line.paymentAmount},${line.transactionFee},${line.airParkFee},${line.vatOnAirParkFee},${line.ownerAmount},${line.subletterAmount},${line.payoutAmount},${line.vatOnPayoutAmount},${line.paymentExtId}\n`;
            });
        } else {
            // Create header row
            csv += 'Transaction Type,Payment Amount,Transaction Fee,Air Park Fee,VAT on Air Park Fee,Air Park Percentage,Owner Percentage,Owner Amount,Owner VAT Percentage,VAT on Owner Amount,Subletter Percentage,Subletter Amount,Payout Amount,VAT on Payout Amount,Organization VAT Percentage,Payment Ext ID\n';

            // Create data rows
            payout?.payoutLines.forEach(line => {
                csv += `${line.transactionType},${line.paymentAmount},${line.transactionFee},${line.airParkFee},${line.vatOnAirParkFee},${line.airParkPercentage},${line.ownerPercentage},${line.ownerAmount},${line.ownerVatPercentage},${line.vatOnOwnerAmount},${line.subletterPercentage},${line.subletterAmount},${line.payoutAmount},${line.vatOnPayoutAmount},${line.organizationVATPercentage},${line.paymentExtId}\n`;
            });
        }
        // Download CSV
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvUrl;
        tempLink.setAttribute('download', `payout_${payoutExtId}.csv`);
        tempLink.click();
    };

    const getPayoutStatusTranslation = (payoutStatus) => {

        switch (payoutStatus.toLowerCase()) {
            case 'pending':
                return t('payoutStatus.pending');
            case 'processed':
                return t('payoutStatus.processed');
            case 'processing':
                return t('payoutStatus.processing');
            case 'ready to process':
                return t('payoutStatus.readytoprocess');
            case 'error':
                return t('payoutStatus.failed');
            default:
                return payoutStatus;
        }
    }

    return (
        <div className="flex flex-col gap-y-6">
            <Widget>
                <h2 className="text-xl font-medium">{t('payoutDetailsPage.title')}</h2>
                <span>{t('payoutDetailsPage.description', { payoutExtId: payoutExtId })}</span>
            </Widget>
            <Alert
                title={t('payoutDetailsPage.openCsvTitle')}
                description={t('payoutDetailsPage.openCsvDescription')}
                template="info" />
            {loading &&
                <WidgetLoadingSkeleton />
            }
            {!loading && payout &&
                <>
                    <div className="flex gap-x-6">
                        <Widget className="flex md:w-1/4 items-center justify-center">
                            <h2 className="text-center">{t('payoutDetailsPage.sum')}</h2>
                            <span className="text-xl font-medium mt-3">{payout.friendlyTotalAmount}</span>
                        </Widget>
                        <Widget className="flex md:w-1/4 items-center justify-center">
                            <h2 className="text-center">{t('payoutDetailsPage.vat')}</h2>
                            <span className="text-xl font-medium mt-3">{`${formatCurrency(payout.totalVat, 2)} ${organization?.currencySymbol}`}</span>
                        </Widget>
                        <Widget className="flex md:w-1/4 items-center justify-center">
                            <h2 className="text-center">{t('payoutDetailsPage.status')}</h2>
                            <span className="text-xl font-medium mt-3">{getPayoutStatusTranslation(payout.payoutStatus)}</span>
                        </Widget>
                        <Widget className="flex md:w-1/4 items-center justify-center">
                            <h2 className="text-center">{t('payoutDetailsPage.payoutDate')}</h2>
                            <span className="text-xl font-medium mt-3">{payout.processedAtUtc ? formatDateToIsoStandard(payout.processedAtUtc) : '-'}</span>
                        </Widget>
                    </div>
                    <Widget>
                        <div className="flex items-center justify-between mb-6">
                            <h2 className="text-xl font-medium">{t('payoutDetailsPage.payoutLines')}</h2>
                            <div className="flex gap-x-3">
                                <ButtonText isSecondary={true} onClick={() => { handleExportToCsv(); }}>
                                    {t('payoutDetailsPage.exportToCsv')}
                                </ButtonText>
                                <ButtonText onClick={() => { navigate('print'); }}>{t('payoutDetailsPage.printSummary')}</ButtonText>
                            </div>
                        </div>
                        {payout.isNewTaxCalculation &&
                            <PayoutDetailsTableNewTaxCalculation
                                payout={payout}
                                payoutLines={payout.payoutLines}
                            />
                        }
                        {!payout.isNewTaxCalculation &&
                            <PayoutDetailsTable
                                payout={payout}
                                payoutLines={payout.payoutLines}
                            />
                        }
                    </Widget>
                </>
            }
        </div>
    );
};

export default PayoutDetailsPage;