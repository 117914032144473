import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackgroundIcon } from 'assets/icons/ico_bg_bell.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ico_arrow_right.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ico_arrow_left.svg';
import Button from 'components/common/button';
import { createOrganization } from "api/organizations";
import { useMessages } from "context/messages";
import usePhoneNumberVerification from 'context/phoneNumberLogin';
import useAuth from "context/auth";
import DropdownSelector from "components/common/dropdownSelector/dropdownSelector";
import InputText from "components/common/inputText";
import CheckBox from "components/common/checkbox";
import useHandleServerError from "hooks/serverErrors";

const CreateOrganizationPage = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const handleServerError = useHandleServerError();
    const [loading, setLoading] = useState();
    const [errors, setErrors] = useState({});
    const [organization, setOrganization] = useState({ 
        name: '', 
        organizationType: 'company', 
        organizationNumber: '', 
        address: '',
        zipCode: '',
        city: '',
        phone: '',
        email: '',
        acceptedTermsAndConditions: false});
    const { showToast } = useMessages();
    const { getToken } = useAuth();
    const { country } = usePhoneNumberVerification();

    const countries = [
        { value: 'US', title: 'United States' },
        { value: 'SE', title: 'Sverige' },
        { value: 'NO', title: 'Norge' },
        { value: 'DK', title: 'Danmark' },
        { value: 'FI', title: 'Suomi' },
        { value: 'DE', title: 'Deutschland' },
        { value: 'FR', title: 'France' },
        { value: 'GB', title: 'Great Britain' },
        { value: 'PL', title: 'Polska' },
        { value: 'IT', title: 'Italia' },
        { value: 'ES', title: 'España' },
        { value: 'GR', title: 'Greece' },
        { value: 'AT', title: 'Österreich' },
        { value: 'RO', title: 'România' },
        { value: 'NL', title: 'Nederland' },
        { value: 'BE', title: 'Belgique' },
        { value: 'HU', title: 'Magyarország' },
        { value: 'CH', title: 'Schweiz' },
        { value: 'IS', title: 'Ísland' },
        { value: 'LT', title: 'Lietuva' },
        { value: 'LV', title: 'Latvija' },
        { value: 'EE', title: 'Eesti' },
        { value: 'PT', title: 'Portugal	' },
    ].sort((a, b) => (a.title > b.title));

    const getOrganizationFormErrors = () => {
        const formErrors = {};

        if (!organization.name) {
            formErrors.name = true; // Replace with some message if needs to be displayed as well
        }

        if (!organization.organizationNumber) {
            formErrors.organizationNumber = true;
        }

        if (!organization.address) {
            formErrors.address = true;
        }

        if (!organization.zipCode) {
            formErrors.zipCode = true;
        }

        if (!organization.city) {
            formErrors.city = true;
        }

        if (!organization.phone) {
            formErrors.phone = true;
        }

        if (!organization.email) {
            formErrors.email = true;
        }

        return formErrors;
    }

    const handleCreateOrganization = () => {
        if (loading) {
            return;
        }

        const formErrors = getOrganizationFormErrors();
        setErrors(formErrors);

        if (JSON.stringify(formErrors) !== '{}') {
            showToast(t('createOrganizationPage.missingRequiredInfoTitle'), t('createOrganizationPage.missingRequiredInfoDescription'), 'error');
            return;
        }

        if (!organization.acceptedTermsAndConditions) {
            showToast(t('createOrganizationPage.youMustAcceptTermsAndConditionsTitle'), t('createOrganizationPage.youMustAcceptTermsAndConditionsDescription'), 'error');
            return;
        }

        const create = async () => {
            setLoading(true);

            try {
                const token = await getToken();

                const response = await createOrganization(token, {...organization, countryCode: organization.countryCode ?? country.country});

                if (!response.error) {
                    navigate('/verified/choose-organization');
                } else {
                    handleServerError(response.error);
                    console.error(response);
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setLoading(false);
        }

        create();
    }

    const isIndividualOrganization = organization.organizationType === 'individual';
    const organizationNumberPlaceholder = isIndividualOrganization ? t('createOrganizationPage.socialSecurityNumber') : t('createOrganizationPage.orgNbr')

    return (
        <>
            <button className="flex p-6 absolute top-12 left-12 md:visible invisible" onClick={(e) => { e.preventDefault(); navigate(-1); }}><ArrowLeftIcon /></button>
            <div className="flex flex-col m-auto max-w-md z-20 pt-24 pb-4">
                <h1 className="text-2xl font-medium mb-4">{t('createOrganizationPage.createOrganization')}</h1>
                <span className="font-base text-base mb-6">{t('createOrganizationPage.description')}</span>
                <DropdownSelector
                    title={t('createOrganizationPage.typeOfOrganization')}
                    tooltip={t('createOrganizationPage.typeOfOrganizationTooltip')}
                    options={[{ title: t('createOrganizationPage.company'), value: 'company' }, { title: t('createOrganizationPage.individual'), value: 'individual' }]}
                    defaultValue={'company'}
                    onChange={(value) => { setOrganization({ ...organization, organizationType: value }) }}
                />
                <div className="mt-3">
                    <DropdownSelector
                        title={t('createOrganizationPage.country')}
                        options={countries}
                        defaultValue={country.country}
                        onChange={(value) => { setOrganization({...organization, countryCode: value}) }}
                    />
                </div>
                <InputText
                    placeHolder={t('createOrganizationPage.name')}
                    className={"mt-6"}
                    error={errors.name}
                    value={organization.name}
                    onChange={(value) => { setOrganization({ ...organization, name: value }) }} />
                <InputText
                    placeHolder={organizationNumberPlaceholder}
                    className={"mt-3"}
                    error={errors.organizationNumber}
                    value={organization.organizationNumber}
                    onChange={(value) => { setOrganization({ ...organization, organizationNumber: value }) }} />
                <InputText
                    placeHolder={t('createOrganizationPage.address')}
                    className={"mt-3"}
                    error={errors.address}
                    value={organization.address}
                    onChange={(value) => { setOrganization({ ...organization, address: value }) }} />
                <InputText
                    placeHolder={t('createOrganizationPage.zipCode')}
                    className={"mt-3"}
                    error={errors.zipCode}
                    value={organization.zipCode}
                    onChange={(value) => { setOrganization({ ...organization, zipCode: value }) }} />
                <InputText
                    placeHolder={t('createOrganizationPage.city')}
                    className={"mt-3"}
                    error={errors.city}
                    value={organization.city}
                    onChange={(value) => { setOrganization({ ...organization, city: value }) }} />
                <InputText
                    placeHolder={t('createOrganizationPage.phone')}
                    className={"mt-3"}
                    error={errors.phone}
                    value={organization.phone}
                    onChange={(value) => { setOrganization({ ...organization, phone: value }) }} />
                <InputText
                    placeHolder={t('createOrganizationPage.email')}
                    className={"mt-3"}
                    error={errors.email}
                    value={organization.email}
                    onChange={(value) => { setOrganization({ ...organization, email: value }) }} />
                <div className="mt-3 flex flex-row">
                    <CheckBox isChecked={organization.acceptedTermsAndConditions} onClick={() => { setOrganization({...organization, acceptedTermsAndConditions: !organization.acceptedTermsAndConditions }) }} />
                    <span className="ml-3">{t('registerPage.accept')} <a href={t('generic.termsAndConditionsUrl')} target="_blank" className="text-airpark-green">{t('registerPage.termsAndConditions')}</a></span>
                </div>
                <div className="flex justify-end">
                    <Button onClick={() => { handleCreateOrganization(); }} loading={loading}>
                        <ArrowRightIcon />
                    </Button>
                </div>
            </div>
            <BackgroundIcon className="absolute bottom-6 left-0 max-h-[100%] h-[150px] w-[275px] z-10" />
        </>
    );
}

export default CreateOrganizationPage;