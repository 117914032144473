import React, { useState, useRef, useEffect } from 'react';
import useLogin from 'context/phoneNumberLogin';
import { ReactComponent as BackgroundIcon } from 'assets/icons/ico_bg_lock.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/ico_arrow_right.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/ico_arrow_left.svg';
import Button from 'components/common/button';
import useAuth from 'context/auth';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/common/logo';

import { getUser } from 'api/users';

import { t } from 'i18next';
import { useMessages } from 'context/messages';

const VerifySmsCodePage = () => {
    const { requestSmsCodeToVerify, verifySmsCode, fullPhoneNumber } = useLogin();
    const { isAuthenticated, getToken } = useAuth();
    const [pin, setPin] = useState(['', '', '', '']);
    const [loadingUser, setLoadingUser] = useState();
    const [isLoading, setIsLoading] = useState();
    const navigate = useNavigate();
    const { showToast } = useMessages();

    useEffect(() => {
        const fetchUser = async () => {
            setLoadingUser(true);

            try {
                const token = await getToken();
                const user = await getUser(token);

                if (user.error === 'USER_MISSING') {
                    navigate('/verified/register');
                } else {
                    navigate('/verified/choose-organization');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setLoadingUser(false);
        }

        if (isAuthenticated) {
            fetchUser();
        }
    }, [isAuthenticated]);

    const handleSinglePinEntered = (e, pinPosition) => {

        if (e.target.value.length < 2) {
            let newArray = [...pin];
            var regex = /[^0-9]|\./;

            if (e.target.value != '' && e.target.value.replace(regex, '') == '') {
                return;
            }

            newArray[pinPosition] = e.target.value.replace(regex, '');
            setPin(newArray);
            if (e.target.value.length == 1) {
                switch (pinPosition) {
                    case 0:
                        pinTwo.current.focus();
                        break;
                    case 1:
                        pinThree.current.focus();
                        break;
                    case 2:
                        pinFour.current.focus();
                        break;
                }
            }
        }

        e.preventDefault();
    };

    const handleSinglePinKeyDown = (e, pinPosition) => {
        if (e.key === 'Backspace' && !pin[pinPosition]) {
            switch (pinPosition) {
                case 1:
                    pinOne.current.focus();
                    break;
                case 2:
                    pinTwo.current.focus();
                    break;
                case 3:
                    pinThree.current.focus();
                    break;
            }
        }

        if (e.key === 'Enter') {
            handleVerifyCode();
        }
    }

    const handleRequestNewCode = async () => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);

        try {
            const result = await requestSmsCodeToVerify();

            if (result) {
                showToast(t('verifySmsCodePage.newCodeSentTitle'), `${t('verifySmsCodePage.newCodeSentDescription')} ${fullPhoneNumber}`, 'info');
            } else {
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }
        } catch (error) {
            console.error('Could not get new code', error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsLoading(false);
    }

    const handleVerifyCode = async () => {
        if (isLoading || pin.length != 4) {
            return;
        }

        try {
            setIsLoading(true);
            const token = await verifySmsCode(pin.join(""));
            if (!token) {
                showToast(t('verifySmsCodePage.codeCouldNotBeVerifiedTitle'), t('verifySmsCodePage.codeCouldNotBeVerifiedDescription'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsLoading(false);
    }

    const pinOne = useRef(null);
    const pinTwo = useRef(null);
    const pinThree = useRef(null);
    const pinFour = useRef(null);

    return (
        <div className="flex h-screen justify-center p-6">
            <Logo className="absolute top-12" />
            <button className="flex p-6 absolute top-12 left-12 md:visible invisible" onClick={(e) => { e.preventDefault(); navigate('/'); }}><ArrowLeft /></button>
            <div className="flex flex-col m-auto max-w-md z-20">
                <h1 className="text-2xl font-medium">{t('verifySmsCodePage.verifyCode')}</h1>
                <span>{t('verifySmsCodePage.enterTheCodeThatWasSentTo')} {fullPhoneNumber}</span>
                <div className="flex flex-row h-24 gap-6 mt-6">
                    <PinItem reference={pinOne} autoFocus={true} value={pin[0] || ''} onChange={(e) => handleSinglePinEntered(e, 0)} onKeyDown={(e) => { handleSinglePinKeyDown(e, 0) }} />
                    <PinItem reference={pinTwo} value={pin[1] || ''} onChange={(e) => handleSinglePinEntered(e, 1)} onKeyDown={(e) => { handleSinglePinKeyDown(e, 1) }} />
                    <PinItem reference={pinThree} value={pin[2] || ''} onChange={(e) => handleSinglePinEntered(e, 2)} onKeyDown={(e) => { handleSinglePinKeyDown(e, 2) }} />
                    <PinItem reference={pinFour} value={pin[3] || ''} onChange={(e) => handleSinglePinEntered(e, 3)} onKeyDown={(e) => { handleSinglePinKeyDown(e, 3) }} />
                </div>
                <a href="#" className="mt-9 text-airpark-green" onClick={(e) => { e.preventDefault(); handleRequestNewCode(); }}>{t('verifySmsCodePage.didNotReceiveCode')}</a>
                <div className="flex justify-end pt-2">
                    <Button onClick={() => { handleVerifyCode(); }} loading={isLoading || loadingUser}>
                        <ArrowRight />
                    </Button>
                </div>
            </div>
            <BackgroundIcon className="absolute bottom-6 left-0 max-h-[100%] h-[150px] w-[275px] z-10" />
        </div>
    );
}

const PinItem = ({ reference, value, onChange, onKeyDown, autoFocus }) => {
    return (
        <div className="basis-1/4">
            <input ref={reference} autoFocus={autoFocus} type="text" inputMode="numeric" pattern="[0-9]*" autoComplete="one-time-code" value={value} onChange={onChange} onKeyDown={onKeyDown} className="w-[100%] h-[100%] bg-gray-200 rounded-xl text-2xl font-medium text-center appearance-none pin outline-airpark-green"></input>
        </div>
    );
}

export default VerifySmsCodePage;