import React from "react";
import Tooltip from "../tooltip/tooltip";
const InputNumber = ({ title, value, onChange, unit, className, disabled, tooltip }) => {

    const baseClassName = 'w-full rounded-xl p-2 pl-4 h-11 bg-airpark-gray-300 focus:outline-none';

    if (!className) {
        className = baseClassName;
    } else {
        className = `${baseClassName} ${className}`;
    }

    if (disabled) {
        className = `${baseClassName} opacity-50`;
    }

    return (
        <div>
             <div className="flex relative items-center">
                <span className="text-base font-medium pb-1">{title}</span>
                {tooltip &&
                    <Tooltip tooltip={tooltip} />
                }
            </div>
            <div className="relative">
                <input type="number" value={value ?? ''} onChange={(e) => { onChange(e.target.value); }} autoComplete='off' autoCorrect='off' className={className} disabled={disabled ?? false} />
                {unit &&
                    <div className="flex absolute justify-center items-center right-0 top-0 h-11 min-w-[44px] pl-3 pr-3 rounded-lg bg-airpark-gray-400">
                        {unit}
                    </div>
                }
            </div>
        </div>
    );
}

export default InputNumber;