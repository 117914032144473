import React from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';
import ChargingStationItem from './chargingStationItem';

const ChargingStationsTable = ({ chargingStations, setChargingStations }) => {
    const { t } = useTranslation();

    if (!chargingStations) {
        return (null);
    }

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6 whitespace-nowrap">{t('chargingStationsTable.id')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('chargingStationsTable.parkingSpotFloor')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('chargingStationsTable.parkingSpotNumber')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('chargingStationsTable.chargingProvider')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('chargingStationsTable.chargingProviderExternalId')}</th>
                            <th className="pl-6 whitespace-nowrap">{t('chargingStationsTable.status')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {chargingStations?.length > 0 && chargingStations.map((chargingStation, i) => {
                            return (<ChargingStationItem 
                                        key={i} 
                                        chargingStation={chargingStation} 
                                        chargingStations={chargingStations} 
                                        setChargingStations={setChargingStations} />)
                        })}
                        {chargingStations?.length < 1 &&
                            <tr className="h-16">
                                <td className="text-center" colSpan={4}>
                                    <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                        <InfoIcon className="h-6 w-6" />
                                        <span>{t('chargingStationsTable.noChargingStations')}</span>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ChargingStationsTable;