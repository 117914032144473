import React from "react";
import Tooltip from "../tooltip/tooltip";

const ToggleText = ({ title, defaultText, toggledText, isToggled, onClick, tooltip }) => {
    return (
        <div>
            <div className="flex relative items-center">
                <span className="text-base font-medium pb-1">{title}</span>
                {tooltip &&
                    <Tooltip tooltip={tooltip} />
                }
            </div>
            <div onClick={() => { onClick(); }} className="flex items-center w-full h-11 rounded-lg bg-airpark-gray-300">
                <div className={`flex cursor-pointer h-9 items-center justify-center w-1/2 rounded-md ml-1 mr-1 transition-all duration-500 ease-in-out ${isToggled ? 'bg-airpark-gray-300' : 'bg-white'}`}>
                    <span  className="truncate pl-1 pr-1">{defaultText}</span>
                </div>
                <div className={`flex cursor-pointer h-9 items-center justify-center w-1/2 rounded-md ml-1 mr-1 transition-all duration-500 ease-in-out ${!isToggled ? 'bg-airpark-gray-300' : 'bg-white'}`}>
                    <span className="truncate pl-1 pr-1">{toggledText}</span>
                </div>
            </div>
        </div>
    )
}

export default ToggleText;