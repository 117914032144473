import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/ico_close.svg';
import ButtonText from 'components/common/buttonText';
import { useTranslation } from 'react-i18next';
import { InviteSublessor } from './inviteSublessor';
import { InvitePortalAdmin } from './invitePortalAdmin';

const InviteUserModal = ({
    display,
    setDisplay,
    portalAdminInvites,
    setPortalAdminInvites,
    sublessorInvites,
    setSublessorInvites
}) => {
    const { t } = useTranslation();
    const [isSaving, setIsSaving] = useState();
    const [userType, setUserType] = useState("portalAdmin");

    if (!display) {
        return (null);
    }

    return (
        <>
            {/* Overlay */}
            <div className="fixed inset-0 z-50 bg-black bg-opacity-60">
            </div>
            {/* Modal */}
            <div onClick={(e) => { e.preventDefault(); setDisplay(false); }} className="flex fixed inset-0 z-50 justify-center items-center overflow-y-auto overflow-x-hidden">
                <div onClick={(e) => { e.stopPropagation(); }} className="flex max-w-md w-full p-3 justify-center items-center">
                    <div className="flex flex-col w-full bg-white max-w-md rounded-lg p-6">
                        <div className="flex justify-end">
                            <button className="h-4" onClick={() => { setDisplay(false); }}>
                                <CloseIcon className="h-5 w-5" />
                            </button>
                        </div>
                        <div className="flex items-center justify-center">
                            <span className="font-medium">{t('inviteUserModal.title')}</span>
                        </div>
                        {userType === "portalAdmin"
                            ?
                            <span className="mt-3 mb-3">{t('inviteUserModal.descriptionPortalAdmin')}</span>
                            :
                            null
                        }
                        {userType === "sublessor"
                            ?
                            <span className="mt-3 mb-3">{t('inviteUserModal.descriptionSublessor')}</span>
                            :
                            null
                        }
                        <div className='flex bg-black mb-3 bg-opacity-5 overflow-hidden rounded-3xl'>
                            <ButtonText 
                                onClick={() => { setUserType("portalAdmin") }} 
                                className={`flex justify-center w-full border-0${userType !== "portalAdmin" ? " bg-opacity-0" : ""}`}
                                isLoading={isSaving} 
                                isSecondary={userType !== "portalAdmin"}>
                                {t('inviteUserModal.portalAdmin')}
                            </ButtonText>
                            <ButtonText 
                                onClick={() => { setUserType("sublessor") }} 
                                className={`flex justify-center w-full border-0${userType !== "sublessor" ? " bg-opacity-0" : ""}`}
                                isLoading={isSaving} 
                                isSecondary={userType !== "sublessor"}>
                                {t('inviteUserModal.sublessor')}
                            </ButtonText>
                        </div>
                        {userType === "portalAdmin"
                            ?
                            <InvitePortalAdmin
                                isSaving={isSaving}
                                setIsSaving={setIsSaving} 
                                invites={portalAdminInvites}
                                setInvites={setPortalAdminInvites} 
                                setDisplay={setDisplay} /> 
                            :
                            null
                        }           
                        {userType === "sublessor"
                            ?
                            <InviteSublessor
                                isSaving={isSaving}
                                setIsSaving={setIsSaving} 
                                invites={sublessorInvites}
                                setInvites={setSublessorInvites} 
                                setDisplay={setDisplay} /> 
                            :
                            null
                        }     
                    </div>
                </div>
            </div>
        </>)
};

export default InviteUserModal;