import React, { useRef } from 'react';

import { ReactComponent as ImageIcon } from 'assets/icons/ico_image.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/ico_delete.svg';

import { useTranslation } from "react-i18next";
import Tooltip from '../tooltip';

const InputFileUpload = ({ title, files, setFiles, handleFileError, isDocuments, tooltip, allowOnlySingleFile }) => {

    const { t } = useTranslation();

    const fileInputRef = useRef();
    const fileAreaRef = useRef();

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        setFileAreaState(true);
    }

    const handleDragLeave = (e) => {
        e.preventDefault();
        setFileAreaState(false);
    }

    const handleFileDrop = (e) => {
        e.preventDefault();
        setFileAreaState(false);

        if (e.dataTransfer.items) {
            [...e.dataTransfer.items].forEach((item, i) => {
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    handleFileSave(file);
                }
            });
        } else {
            [...e.dataTransfer.files].forEach((file, i) => {
                handleFileSave(file);
            });
        }
    }

    const setFileAreaState = (isActive) => {
        if (isActive) {
            fileAreaRef.current.classList.remove('border-airpark-gray-500');
            fileAreaRef.current.classList.remove('border-[1px]');
            fileAreaRef.current.classList.add('border-airpark-green');
            fileAreaRef.current.classList.add('border-[2px]');
        } else {
            fileAreaRef.current.classList.remove('border-airpark-green');
            fileAreaRef.current.classList.remove('border-[2px]');
            fileAreaRef.current.classList.add('border-airpark-gray-500');
            fileAreaRef.current.classList.add('border-[1px]');
        }
    }

    const handleFileSave = (file) => {

        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

        if (isDocuments) {
            allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
        }

        if (!allowedExtensions.exec(file.name) && handleFileError) {
            handleFileError();
        } else {
            if (!allowOnlySingleFile) {
                const existingFiles = files ? files : [];
                existingFiles.push(file);
                setFiles(files);
            } else {
                setFiles([file]);
            }
        }
    }

    return (
        <div className="flex flex-col">
            {(title || tooltip) &&
                <div className="flex items-center relative">
                    {title &&
                        <span className="text-base font-medium pb-1">{title}</span>
                    }
                    {tooltip &&
                        <Tooltip tooltip={tooltip} />
                    }
                </div>
            }
            <div ref={fileAreaRef}
                onClick={(e) => { e.preventDefault(); fileInputRef.current.click(); }}
                onDrop={(e) => { handleFileDrop(e); }}
                onDragOver={(e) => { handleDragOver(e); }}
                onDragEnter={(e) => { handleDragEnter(e); }}
                onDragLeave={(e) => { handleDragLeave(e); }}
                className="drop-area gap-2 flex flex-col p-3 w-full min-h-[180px] border-[1px] border-airpark-gray-500 border-dashed rounded-xl cursor-pointer items-center justify-center">
                {files && files.length < 1 &&
                    <>
                        <ImageIcon className="pointer-events-none" />
                        <p className="mt-3 pointer-events-none">{isDocuments ? t('generic.uploadDocumentFiles') : t('generic.uploadImageFiles')}</p>
                    </>
                }
                {files && files.map((file, i) => {
                    return (
                        <div key={i} className="flex flex-row">
                            <span className="mr-2">{file.name}</span>
                            <button onClick={(e) => {
                                files.splice(i, 1);
                                setFiles(files);
                                e.stopPropagation();
                            }}>
                                <DeleteIcon className="h-5 w-5 hover:scale-105 transition-all duration-200 ease-in-out" />
                            </button>
                        </div>
                    )
                })}
            </div>
            <input ref={fileInputRef} onChange={(e) => {
                for (let i = 0; i < e.target.files.length; i++) {
                    handleFileSave(e.target.files[i]);
                }
            }}
                id="file-upload" type="file" className="hidden" multiple={true} />
        </div>
    );
};

export default InputFileUpload;