import camelCase from 'camelcase';
import { useTranslation } from "react-i18next";
import { useMessages } from "context/messages";

const useHandleServerError = () => {
  const { t, i18n } = useTranslation();
  const { showToast } = useMessages();

  const handleServerError = (error) => {
    const camelCaseError = camelCase(error);
    const titleKey = 'error.' + camelCaseError + 'Title';
    const descriptionKey =  'error.' + camelCaseError + 'Description';
    const translationsExist = i18n.exists(titleKey) && i18n.exists(descriptionKey);
    
    if (translationsExist) {
      showToast(t(titleKey), t(descriptionKey), 'error');
    } else {
      showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');    
    }
  };

  return handleServerError;
};

export default useHandleServerError;