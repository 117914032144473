import { formatDateToIsoStandard } from 'helpers/date';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ControlFeesItem = ({ controlFee }) => {

    const { t } = useTranslation();

    const getControlFeeReasonById = (id) => {
        switch (id) {
            case 1:
                return t('controlFeesTable.overstayed');
            case 2:
                return t('controlFeesTable.forbidden');
            case 3:
                return t('controlFeesTable.outsideMarks');
            case 4:
                return t('controlFeesTable.wrongSpot');
            default:
                return t('controlFeesTable.unspecified');
        }
    };

    const getControlFeeStatusById = (id) => {
        switch (id) {
            case 1:
                return t('controlFeesTable.pending');
            case 2:
                return t('controlFeesTable.rejected');
            case 3:
                return t('controlFeesTable.approved');
            default:
                return t('controlFeesTable.unspecified');
        }
    };

    const getPaymentStatus = (isPaid) => {
        if (isPaid) {
            return t('controlFeesTable.paid');
        } else {
            return t('controlFeesTable.unpaid');
        }
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">{formatDateToIsoStandard(controlFee.createdDate)}</td>
            <td className="pl-6 whitespace-nowrap">{getControlFeeReasonById(controlFee.controlFeeReasonId)}</td>
            <td className="pl-6 whitespace-nowrap">{controlFee.notes}</td>
            <td className="pl-6 whitespace-nowrap">{getControlFeeStatusById(controlFee.controlFeeStatusId)}</td>
            <td className="pl-6 whitespace-nowrap">{controlFee.friendlyPrice}</td>
            <td className="pl-6 whitespace-nowrap">{formatDateToIsoStandard(controlFee.paymentDueDate)}</td>
            <td className="pl-6 pr-6 whitespace-nowrap">{getPaymentStatus(controlFee.isPaid)}</td>
        </tr>
    );
};

export default ControlFeesItem;