import moment from 'moment';

const BASE_URL = `${process.env.REACT_APP_API_USERS_BASE_URL}/Statistics`

export const getDashboardStatistics = async (token, organizationExtId) => {
  const response = await fetch(`${BASE_URL}/get-dashboard-statistics-for-organization/${organizationExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getDailyRevenue = async (token, dailyRevenueRequest) => {
  // We need to handle UTC time parsing
  dailyRevenueRequest.fromDate.toJSON = function () { return `${moment(this).format('YYYY-MM-DD')}T${moment(this).format('HH:mm:ss')}`; }
  dailyRevenueRequest.fromDate.toJSON = function () { return `${moment(this).format('YYYY-MM-DD')}T${moment(this).format('HH:mm:ss')}`; }

  const response = await fetch(`${BASE_URL}/get-daily-revenue`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(dailyRevenueRequest)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getMonthlyRevenue = async (token, monthlyRevenueRequest) => {
  // We need to handle UTC time parsing
  monthlyRevenueRequest.fromDate.toJSON = function () { return `${moment(this).format('YYYY-MM-DD')}T${moment(this).format('HH:mm:ss')}`; }
  monthlyRevenueRequest.fromDate.toJSON = function () { return `${moment(this).format('YYYY-MM-DD')}T${moment(this).format('HH:mm:ss')}`; }

  const response = await fetch(`${BASE_URL}/get-monthly-revenue`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(monthlyRevenueRequest)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}